import React, { FC, memo, useEffect, useLayoutEffect, useRef, useState, useCallback } from 'react';
import classes from './style.module.scss';
import LeftCommentRow from './LeftCommentRow';
import RightCommentRow from './RightCommentRow';
import DateRow from './DateRow';
import ButtonsRow from './ButtonsRow';
import SimpleBar from 'simplebar-react';
import { IDialog } from 'models/Dialog';
import { IDialogController } from 'types/IDialogController';
import { CaretDownFill } from 'react-bootstrap-icons';

interface IRowsProps {
  dialog: IDialog[];
  loginName: string;
  currentContactName: string;
  dlgCtrl: IDialogController;
  flagScroll: boolean;
  setFlagScroll: (flag: boolean) => void;
}

const Rows: FC<IRowsProps> = (props) => {
  const { dialog, loginName, currentContactName, dlgCtrl, flagScroll, setFlagScroll } = props;

  const ref = useRef<any>(null);
  const [oldMessages, setOldMessages] = useState<IDialog[]>([]);
  const [countUnread, setCountUnread] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true);

  useLayoutEffect(() => {
    const el = ref.current?.getScrollElement();
    if (el && !shouldScrollToBottom) {
      if (dialog.length === oldMessages.length + 1) {
        if (el.scrollHeight - (el.scrollTop + el.clientHeight) < 250) {
          scrollToBottom(true);
        } else setCountUnread(countUnread + 1);
      } else el.scrollTop = el.scrollTop + (el.scrollHeight - offset);
    }
    if (dialog.length > 0 && shouldScrollToBottom) {
      scrollToBottom(flagScroll === true ? true : false);
      setShouldScrollToBottom(false);
      setFlagScroll(false);
    }
    setOldMessages(dialog);
  }, [dialog]);

  useEffect(() => {
    setShouldScrollToBottom(true);
  }, [currentContactName, flagScroll]);

  const scrollToBottom = (type: boolean) => {
    if (ref.current) {
      const el = ref.current.getScrollElement();
      if (el) {
        if (type) {
          el.scrollTo({ top: el.scrollHeight, behavior: 'smooth' });
          setCountUnread(0);
        } else {
          el.scrollTo({ top: el.scrollHeight, behavior: 'auto' });
        }
      }
    }
  };

  const loadMessages = useCallback(
    async (direction: string) => {
      try {
        const el = ref.current?.getScrollElement();

        if (el && direction === 'up') {
          setOffset(el.scrollHeight);
        }

        if (direction === 'down') {
          setCountUnread(0);
          dlgCtrl.loadPagesDown();
        } else {
          dlgCtrl.loadPagesUp();
        }
      } catch (error) {
        console.error('Ошибка загрузки сообщений:', error);
      }
    },
    [dialog, dlgCtrl]
  );

  const handleScroll = () => {
    const el = ref.current?.getScrollElement();
    if (el) {
      // const threshold = el.clientHeight * 0.1;
      const threshold = 50; //  Более точный порог (пикселей)
      if (el.scrollTop < threshold) {
        // Близость к верху
        loadMessages('up');
      } else if (el.scrollHeight - (el.scrollTop + el.clientHeight) < threshold) {
        // Близость к низу
        loadMessages('down');
      }
    }
  };

  const showBtnBottom = () => {
    const el = ref.current?.getScrollElement();
    if (el) {
      return countUnread > 0 || el.scrollHeight - (el.scrollTop + el.clientHeight) >= 360
        ? true
        : false;
    }
    return false;
  };

  const useDebounce = <T extends (...args: any[]) => void>(
    func: T,
    delay: number
  ): ((...args: Parameters<T>) => void) => {
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

    const debounced = useCallback(
      (...args: Parameters<T>) => {
        timer && clearTimeout(timer);
        setTimer(
          setTimeout(() => {
            func(...args);
          }, delay)
        );
      },
      [func, delay]
    );

    return debounced;
  };

  const debouncedHandleScroll = useDebounce(handleScroll, 100);

  const areDatesDifferentDays = (dateStr1: any, dateStr2: any): boolean => {
    const date1 = new Date(dateStr1);
    const date2 = new Date(dateStr2);

    const dateOnly1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const dateOnly2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
    return dateOnly1.getTime() !== dateOnly2.getTime();
  };

  const defineSender = (dialogItem: any, keyNum: any, prevDialogItem: any, nextDialogItem: any) => {
    return dialogItem.message.senderName !== loginName ? (
      <LeftCommentRow
        keyNum={keyNum}
        dialogItem={dialogItem}
        prevDialogItem={prevDialogItem}
        nextDialogItem={nextDialogItem}
      />
    ) : (
      <RightCommentRow
        keyNum={keyNum}
        dialogItem={dialogItem}
        prevDialogItem={prevDialogItem}
        nextDialogItem={nextDialogItem}
        loginName={loginName}
      />
    );
  };

  return (
    <>
      <div className={classes.wrapperChat}>
        <SimpleBar
          ref={ref}
          forceVisible="y"
          autoHide={true}
          className={classes.chatConversation}
          onScrollCapture={debouncedHandleScroll}
        >
          <ul>
            {dialog.map((dialogItem, key) => {
              const { message } = dialogItem;
              const prevDialogItem = dialog[key - 1];
              const nextDialogItem = dialog[key + 1];
              const keyNum = dialogItem.message.id;

              const flagDate = !!prevDialogItem
                ? areDatesDifferentDays(message.date, prevDialogItem.message.date)
                : false;

              return (
                <div key={`dialog-item=${keyNum}`}>
                  {(flagDate || key === 0) && <DateRow keyNum={keyNum} message={message} />}
                  <span key={`dlgRow-${keyNum}`}>
                    {dialogItem.isButtons() ? (
                      <ButtonsRow
                        keyNum={keyNum}
                        dialogItem={dialogItem}
                        prevDialogItem={prevDialogItem}
                        nextDialogItem={nextDialogItem}
                      />
                    ) : (
                      <>{defineSender(dialogItem, keyNum, prevDialogItem, nextDialogItem)}</>
                    )}
                  </span>
                </div>
              );
            })}
          </ul>
        </SimpleBar>
        {showBtnBottom() && (
          <button className={classes.btnBottom} onClick={() => scrollToBottom(true)}>
            <CaretDownFill />
            {countUnread > 0 && <div className={classes.counterBtn}>{countUnread}</div>}
          </button>
        )}
      </div>
    </>
  );
};

export default memo(Rows);
