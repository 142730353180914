import React, { FC, memo } from 'react';
import './style.scss';
import { trailingEmpties } from 'types/Text';

interface IMultyMsgProps {
  msg: string;
}

const MultyMsg: FC<IMultyMsgProps> = (props) => {
  const { msg } = props;

  let parts: string[] = [];
  if (msg !== null && msg !== undefined && msg.length > 0) {
    const lines: string[] = msg.split('\n');
    lines.forEach((line) => parts.push(line));
  }

  parts = trailingEmpties(parts);

  return (
    <>
      {parts.map((text, index) => {
        return (
          <span key={'key-' + index}>
            <span>{text}</span>
            <br />
          </span>
        );
      })}
    </>
  );
};

export default memo(MultyMsg);
