import dayjs from 'dayjs';
import { IPlayerInfo } from 'models/Player';

export const playerInfoWithoutHours = !true;

export function getPlayerInfoCreatedAt(playerInfo: IPlayerInfo): string {
  const timest = playerInfo.created_at;
  let result = '';
  if (timest !== null && timest !== undefined) {
    if (playerInfoWithoutHours) result = getJustShortDate(timest);
    else result = getJustShortDatetime(timest);
  }
  return result;
}

export function getPlayerInfoDatetime(timest: any): string {
  let result = '';
  if (timest !== null && timest !== undefined) {
    if (playerInfoWithoutHours) result = getJustShortDate(timest);
    else result = getJustShortDatetime(timest);
  }
  return result;
}

export const unixTime = dayjs().subtract(1, 'hour').unix();

export function getJustShortDate(dt: Date): string {
  const result = dayjs(dt).format('YYYY-MM-DD');
  return result;
}

export function getShortDatetime(dt: Date): string {
  const result = dayjs(dt).format('YYYY-MM-DD HH:mm');
  return result;
}

export function getJustShortDatetime(dt: Date): string {
  const result = dayjs(dt).format('DD/MM/YY, HH:mm');
  return result;
}

export function getShortWeekday(dt: Date): string {
  return dayjs(dt).format('ddd');
}

export function getShortMonthDay(dt: Date): string {
  return dayjs(dt).format('MM.DD');
}

export function getShortLastYear(dt: Date): string {
  return dayjs(dt).format('MM.DD.YY');
}

export function getShortTime(dt: Date): string {
  return dayjs(dt).format('HH:mm');
}

export function getFullMonthDay(dt: Date, withTodayStr: boolean = true): string {
  const dateFormated = dayjs(dt).format('MMMM DD');

  if (!withTodayStr) return dateFormated;

  const dateNowFormated = dayjs().format('MMMM DD');

  return dateFormated === dateNowFormated ? 'Today' : dateFormated;
}

export function getShortDate(dt: Date, withTodayStr: boolean = true): string {
  const dateFormated = dayjs(dt).format('YYYY-MM-DD');

  if (!withTodayStr) return dateFormated;

  const dateNowFormated = dayjs().format('YYYY-MM-DD');

  return dateFormated === dateNowFormated ? 'Today' : dateFormated;
}
