import React, { FC, memo, useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import classes from './style.module.scss';
import { CONTACT_NOTES_LIMIT } from 'types/Chat';
import { EDITABLE_CATEGORY, IGalleryMessage } from 'types/IHallImagesGet';

interface ICreateMsgTemplateDlgProps {
  messageSaveDlgOpen: boolean;
  msgAddTemplateCategoryChange: (event: any) => void;
  messagesFromGallery?: IGalleryMessage[];
  getCategoriesOfMessages: () => string[];
  msgTemplateName: string;
  msgTemplateNameChange: (event: any) => void;
  msgTemplateShortcut: string;
  msgTemplateShortcutChange: (event: any) => void;
  msgTemplateMessage: string;
  msgTemplateMessageChange: (event: any) => void;
  cancelAddMessageTemplete: () => void;
  confirmAddMessageTemplete: () => void;
}

const CreateMsgTemplateDlg: FC<ICreateMsgTemplateDlgProps> = (props) => {
  const {
    messageSaveDlgOpen,
    msgAddTemplateCategoryChange,
    messagesFromGallery,
    getCategoriesOfMessages,
    msgTemplateName,
    msgTemplateNameChange,
    msgTemplateShortcut,
    msgTemplateShortcutChange,
    msgTemplateMessage,
    msgTemplateMessageChange,
    cancelAddMessageTemplete,
    confirmAddMessageTemplete,
  } = props;

  const [errors, setErrors] = useState<boolean[]>([]);

  const validateForm = () => {
    let checkErrors: boolean[] = [];
    setErrors(checkErrors);
    checkErrors.push(msgTemplateName.trim() === '');
    checkErrors.push(msgTemplateShortcut.trim() === '');
    checkErrors.push(msgTemplateMessage.trim() === '');
    setErrors(checkErrors);
    if (!checkErrors.includes(true)) {
      confirmAddMessageTemplete();
    }
  };

  const cancel = () => {
    setErrors([]);
    cancelAddMessageTemplete();
  };

  return (
    <Modal
      returnFocusAfterClose={true}
      isOpen={messageSaveDlgOpen}
      centered
      className={classes.modalDialog}
    >
      <ModalHeader className={classes.modalHeader}>
        <span>
          <span>Create new message template</span>
        </span>
      </ModalHeader>
      <ModalBody className={classes.modalBody}>
        <div>For quick access to the message you can save it to the library as a template</div>

        <div className={classes.inputBox}>
          <span>Category</span>
          <Input
            id="imgAddTemplateCategory"
            className="upl-dlg-img-templ-ctrl"
            name="imgAddTemplateCategory"
            type="select"
            disabled
            value={EDITABLE_CATEGORY}
            onChange={msgAddTemplateCategoryChange}
          >
            {messagesFromGallery &&
              getCategoriesOfMessages().map((category, i) => {
                return <option key={'catgrm' + i}>{category}</option>;
              })}
          </Input>
        </div>

        <div className={classes.inputBox}>
          <div className={classes.headerInput}>
            <span>Name</span>
            <span className={classes.counter}>{msgTemplateName.length}/100</span>
          </div>
          <Input
            type="text"
            value={msgTemplateName}
            className="msg-template-edit input-valid"
            placeholder={'Name of message'}
            style={{ resize: 'none' }}
            onChange={(event) => {
              errors[0] = false;
              msgTemplateNameChange(event);
            }}
            maxLength={100}
            invalid={errors[0]}
          />
          {errors[0] && <div className={classes.error}>Fill in the field</div>}
        </div>

        <div className={classes.inputBox}>
          <div className={classes.headerInput}>
            <span>Shortcut</span>
            <span className={classes.counter}>{msgTemplateShortcut.length}/10</span>
          </div>
          <Input
            type="text"
            value={msgTemplateShortcut}
            className="msg-template-edit input-valid"
            placeholder={'/shortcut'}
            style={{ resize: 'none' }}
            onChange={(event) => {
              errors[1] = false;
              msgTemplateShortcutChange(event);
            }}
            maxLength={10}
            invalid={errors[1]}
          />
          {errors[1] && <div className={classes.error}>Fill in the field</div>}
        </div>

        <div className={classes.inputBox}>
          <div className={classes.headerInput}>
            <span>Message</span>
            <span className={classes.counter}>{msgTemplateMessage.length}/1000</span>
          </div>
          <Input
            type="textarea"
            value={msgTemplateMessage}
            className="notes-edit input-valid"
            placeholder={'Type a message'}
            style={{ resize: 'none' }}
            onChange={(event) => {
              errors[2] = false;
              msgTemplateMessageChange(event);
            }}
            // maxLength={CONTACT_NOTES_LIMIT}
            maxLength={1000}
            invalid={errors[2]}
          />
          {errors[2] && <div className={classes.error}>Fill in the field</div>}
        </div>
      </ModalBody>
      <ModalFooter className={classes.modalFooter}>
        <span className={classes.dlgBtns}>
          <button onClick={cancel} className="button default">
            Cancel
          </button>
          <button onClick={validateForm} className="button primary">
            Save
          </button>
        </span>
      </ModalFooter>
    </Modal>
  );
};

export default memo(CreateMsgTemplateDlg);
