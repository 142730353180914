import { FC, memo } from 'react';
import { Outlet } from 'react-router-dom';
import UpsidePanel from 'components/UpsidePanel';
import classes from './style.module.scss';

const Layout: FC = () => {
  return (
    <div className={classes.chat}>
      <UpsidePanel />
      <div className={classes.chatContainers}>
        <Outlet />
      </div>
    </div>
  );
};

export default memo(Layout);
