export const CONTACTS_MSG_LIMIT = 50 / 2;

export function getShortMessage(text: string, singleLine: boolean = true): string {
  let result = '';
  if (text !== null && text !== undefined)
    result = text.length > CONTACTS_MSG_LIMIT ? `${text.slice(0, CONTACTS_MSG_LIMIT)}...` : text;

  if (singleLine) {
    const idx = result.indexOf('\n');
    if (idx >= 0) result = result.substring(0, idx);
  }

  return result;
}

export const MESSAGE_SYMBOLS_LIMIT = 4096;
export const IMAGE_MESSAGE_SYMBOLS_LIMIT = MESSAGE_SYMBOLS_LIMIT;

export function firstChunkSize(entireMessage: string, withImage: boolean): number {
  let result = MESSAGE_SYMBOLS_LIMIT;
  if (withImage !== null && withImage !== undefined && withImage)
    result = IMAGE_MESSAGE_SYMBOLS_LIMIT;
  if (
    entireMessage !== null &&
    entireMessage !== undefined &&
    entireMessage.length > 0 &&
    result > entireMessage.length
  )
    result = entireMessage.length;
  else if (entireMessage === null || entireMessage === undefined || entireMessage.length < 1)
    result = 0;
  return result;
}

export function prepareMultiChunkMessage(entireMessage: string, withImage: boolean): string[] {
  let result: string[] = [];
  if (entireMessage !== null && entireMessage !== undefined && entireMessage.length >= 0) {
    const size1 = firstChunkSize(entireMessage, withImage);
    let part = entireMessage.substring(0, size1);
    result.push(part);
    let rest = entireMessage.substring(size1);
    while (rest.length > 0) {
      part = rest.substring(0, MESSAGE_SYMBOLS_LIMIT);
      result.push(part);
      rest = rest.substring(MESSAGE_SYMBOLS_LIMIT);
    }
  }
  return result;
}

export function trailingEmpties(copy: string[]): string[] {
  let parts: string[] = [...copy];
  let stop = false;
  while (!stop) {
    const idx = parts.length - 1;
    const lastVal = parts[idx];
    if (lastVal !== null && lastVal !== undefined) {
      const trimmedLast = lastVal.trim();
      if (trimmedLast.length > 0) stop = true;
      else parts.splice(idx, 1);
    } else stop = true;
  }
  return parts;
}
