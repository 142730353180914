// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_modalDialog__VBuuK {
  width: 328px;
}
@media (max-width: 576px) {
  .style_modalDialog__VBuuK {
    width: auto;
  }
}
.style_modalDialog__VBuuK .style_modalHeader__1m9vm {
  justify-content: space-between;
  border: none;
}
.style_modalDialog__VBuuK .style_modalFooter__Qzfg2 {
  border: none;
}
.style_modalDialog__VBuuK .style_modalFooter__Qzfg2 .style_dlgBtns__d8DGG {
  width: 100%;
  display: flex;
  gap: 16px;
}
.style_modalDialog__VBuuK .style_modalFooter__Qzfg2 .style_dlgBtns__d8DGG button {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Dialog/AddImages/DeleteMsgTemplateDlg/style.module.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;AADF;AAGE;EAHF;IAII,WAAA;EAAF;AACF;AAEE;EACE,8BAAA;EACA,YAAA;AAAJ;AAGE;EACE,YAAA;AADJ;AAGI;EACE,WAAA;EACA,aAAA;EACA,SAAA;AADN;AAGM;EACE,WAAA;AADR","sourcesContent":["@import '../../../../variables.scss';\n\n.modalDialog {\n  width: 328px;\n\n  @media (max-width: 576px) {\n    width: auto;\n  }\n\n  .modalHeader {\n    justify-content: space-between;\n    border: none;\n  }\n\n  .modalFooter {\n    border: none;\n\n    .dlgBtns {\n      width: 100%;\n      display: flex;\n      gap: 16px;\n\n      button {\n        width: 100%;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalDialog": `style_modalDialog__VBuuK`,
	"modalHeader": `style_modalHeader__1m9vm`,
	"modalFooter": `style_modalFooter__Qzfg2`,
	"dlgBtns": `style_dlgBtns__d8DGG`
};
export default ___CSS_LOADER_EXPORT___;
