import { EditableEntityId } from './soket';

export interface IHallImagesGet {
  hall_id: number;
  type: string;
  command: string;
  argument: string | null;
}

export interface IHallMessagesGet extends IHallImagesGet {}

export interface ITemplateMessageCreate extends IHallImagesGet {}

export interface ITemplateMessageDelete extends IHallImagesGet {}

export interface ICangeStatus extends IHallImagesGet {}

export interface ISwitchVip extends IHallImagesGet {}

export interface IChangePlayerId extends IHallImagesGet {}

export interface IGalleryTransport {
  imagesFromGallery?: IGalleryImage[];
  messagesFromGallery?: IGalleryMessage[];
  getHallImages: (message: IHallImagesGet) => string[];
  getHallMessages: (message: IHallMessagesGet) => string[];
  createMsgTemplate: (msgTempl: IGalleryMessage) => void;
  deleteMsgTemplate: (id: number) => void;
}

export interface IGalleryMessage {
  title: string;
  body: string;
  shortcat: string;
  type: string | undefined;
  id: EditableEntityId;
}

export interface IGalleryImage {
  filename: string;
  category: string;
}

export const EDITABLE_CATEGORY = 'User template';
