import React, { FC, memo } from 'react';
import { IMessage } from 'models/Message';
import classes from './style.module.scss';

interface IDateRowProps {
  message: IMessage;
  keyNum: string;
}

const DateRow: FC<IDateRowProps> = (props) => {
  const { message, keyNum } = props;

  return (
    <li key={`dateRow-${keyNum}`}>
      <div className={classes.chatDayTitle}>
        <span className={classes.title}>{message.getFullMonthDay()}</span>
      </div>
    </li>
  );
};

export default memo(DateRow);
