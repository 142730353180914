export enum ChatCommand {
  CHANGE_BALANCE = 'changeBalance',
  SET_BALANCE_MODE = 'setBalanceMode',
  SET_WEEK_RESET = 'setWeeklyReset',
  GET_HALL_IMAGES = 'getHallImages',
  GET_HALL_MESSAGES = 'getHallMessages',
  STORE_MESSAGE = 'storeMessage',
  DELETE_MESSAGE = 'deleteMessage',
  CHANGE_STATUS = 'changeStatus',
  SWITCH_VIP = 'switchVip',
  CHANGE_PLAYER_ID = 'changePlayerId',
  MARK_AS_READ = 'markAsRead',
}
