import React, { FC, memo } from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import classes from './style.module.scss';
import { MONEY_LIMIT, yesNoAnser } from 'types/Money';
import Switch from 'react-switch';
import { BalanceMode, BalanceModeLabel } from 'models/Balance';

interface IChangeBalanceDlgProps {
  changeMoneyDlgOpen: boolean;
  balanceMovements: number;
  balanceChange: (event: any) => void;
  balanceMode: BalanceMode;
  modeChange: (mode: BalanceMode) => void;
  confirmChangeMoney: () => void;
  cancelChangeMoney: () => void;
  weeklyReset: boolean | null;
  resetChange: () => void;
}

const ChangeBalanceDlg: FC<IChangeBalanceDlgProps> = (props) => {
  const {
    changeMoneyDlgOpen,
    balanceMovements,
    balanceChange,
    balanceMode,
    modeChange,
    confirmChangeMoney,
    cancelChangeMoney,
    weeklyReset,
    resetChange,
  } = props;

  function credit2balanceChange(e: any) {
    modeChange(BalanceMode.MODE_BALANCE);
    console.log(' b ' + balanceMode + ' ' + e.target.value);
  }

  function balance2creditChange(e: any) {
    modeChange(BalanceMode.MODE_CREDIT);
    console.log(' b ' + balanceMode + ' ' + e.target.value);
  }

  const getBalanceValue = () => {
    return BalanceModeLabel.BALANCE;
  };

  const getCreditValue = () => {
    return BalanceModeLabel.CREDIT;
  };

  return (
    <Modal
      className={classes.modalDialog}
      returnFocusAfterClose={true}
      isOpen={changeMoneyDlgOpen}
      centered
    >
      <ModalHeader className={classes.modalHeader}>
        <span>
          <span>Change balance</span>
        </span>
      </ModalHeader>
      <ModalBody className={classes.modalBody}>
        <div className={classes.inputBox}>
          <div className={classes.label}>Money movements</div>
          <Input
            type="text"
            value={balanceMovements}
            className="money-edit"
            placeholder={'$0'}
            style={{ resize: 'none' }}
            onChange={balanceChange}
            maxLength={MONEY_LIMIT}
          />
        </div>
        <div className={classes.balanceCreditChoice}>
          <span className={classes.balanceCreditCtrl}>
            <Input
              type="radio"
              checked={balanceMode === BalanceMode.MODE_BALANCE}
              value={getBalanceValue()}
              style={{ cursor: 'pointer' }}
              onChange={balance2creditChange}
              className="money-edit"
            />
            <span className={classes.balanceCreditLbl} onClick={balance2creditChange}>
              {getBalanceValue()}
            </span>
          </span>
          <span className={classes.balanceCreditCtrl}>
            <Input
              type="radio"
              checked={balanceMode === BalanceMode.MODE_CREDIT}
              value={getCreditValue()}
              style={{ cursor: 'pointer' }}
              onChange={credit2balanceChange}
              className="money-edit"
            />
            <span className={classes.balanceCreditLbl} onClick={credit2balanceChange}>
              {getCreditValue()}
            </span>
          </span>
        </div>
        <div className={classes.weekBalanceReset}>
          <span>Weekly balance reset</span>
          <span>
            <Switch
              onChange={resetChange}
              checked={yesNoAnser(weeklyReset) === 'Yes'}
              onColor={'#5f8fdf'}
              offColor={'#b8babe'}
              checkedIcon={false}
              uncheckedIcon={false}
              checkedHandleIcon={undefined}
              uncheckedHandleIcon={undefined}
              className="msg-switch"
              height={20}
              width={40}
            />
            <span>{yesNoAnser(weeklyReset)}</span>
          </span>
        </div>
      </ModalBody>
      <ModalFooter className={classes.modalFooter}>
        <span className={classes.dlgBtns}>
          <button onClick={cancelChangeMoney} className="button default">
            Cancel
          </button>
          <button onClick={confirmChangeMoney} className="button primary">
            Change
          </button>
        </span>
      </ModalFooter>
    </Modal>
  );
};

export default memo(ChangeBalanceDlg);
