import React, { FC, memo } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import classes from './style.module.scss';

interface IDeleteMsgTemplateDlgProps {
  askMessageDeleteConfirmDlgOpen: boolean;
  title: string | undefined;
  cancelDelMessageTemplete: () => void;
  confirmDelMessageTemplete: () => void;
}

const DeleteMsgTemplateDlg: FC<IDeleteMsgTemplateDlgProps> = (props) => {
  const {
    askMessageDeleteConfirmDlgOpen,
    title,
    cancelDelMessageTemplete,
    confirmDelMessageTemplete,
  } = props;

  return (
    <Modal
      className={classes.modalDialog}
      returnFocusAfterClose={true}
      isOpen={askMessageDeleteConfirmDlgOpen}
      centered
    >
      <ModalHeader className={classes.modalHeader}>
        <span>
          <span>Delete message template</span>
        </span>
      </ModalHeader>
      <ModalBody>
        <div>
          <span>Are you sure to delete message template?</span>
          &nbsp;
          <b>{title}</b>
        </div>
      </ModalBody>
      <ModalFooter className={classes.modalFooter}>
        <span className={classes.dlgBtns}>
          <button onClick={cancelDelMessageTemplete} className="button default">
            Cancel
          </button>
          <button onClick={confirmDelMessageTemplete} className="button primary">
            Delete
          </button>
        </span>
      </ModalFooter>
    </Modal>
  );
};

export default memo(DeleteMsgTemplateDlg);
