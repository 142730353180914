export function extractNumber(value: any): number {
    const result: number = value !== null && value !== undefined ? value : 0;
    return result;
}

export function extractString(value: any): string {
    const result: string = value !== null && value !== undefined ? value : '';
    return result;
}

export function extractTrue(value: any): boolean {
    const result: boolean =
        value !== null && value !== undefined ? value === true || value === 1 : false;
    return result;
}
