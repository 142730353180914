// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_modalDialog__C01uu .style_modalHeader__7fw-f {
  justify-content: space-between;
  border: none;
}
.style_modalDialog__C01uu .style_modalBody__gBJco {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.style_modalDialog__C01uu .style_modalBody__gBJco .style_inputBox__qlT0Z {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.style_modalDialog__C01uu .style_modalBody__gBJco .style_inputBox__qlT0Z .style_headerInput__WVg70 {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.style_modalDialog__C01uu .style_modalBody__gBJco .style_inputBox__qlT0Z .style_headerInput__WVg70 span {
  font-size: 14px;
  line-height: 18px;
  color: #4a596a;
}
.style_modalDialog__C01uu .style_modalBody__gBJco .style_inputBox__qlT0Z .style_headerInput__WVg70 .style_counter__tKVrJ {
  font-size: 12px;
  color: #495057;
}
.style_modalDialog__C01uu .style_modalBody__gBJco .style_inputBox__qlT0Z .style_error__SVWo9 {
  color: #ec205f;
  font-size: 12px;
}
.style_modalDialog__C01uu .style_modalFooter__BtaO7 {
  border: none;
}
.style_modalDialog__C01uu .style_modalFooter__BtaO7 .style_dlgBtns__YnqLB {
  width: 100%;
  display: flex;
  gap: 16px;
}
.style_modalDialog__C01uu .style_modalFooter__BtaO7 .style_dlgBtns__YnqLB button {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Dialog/AddImages/CreateMsgTemplateDlg/style.module.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAGE;EACE,8BAAA;EACA,YAAA;AAFJ;AAKE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAHJ;AAKI;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AAHN;AAKM;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,8BAAA;AAHR;AAKQ;EACE,eAAA;EACA,iBAAA;EACA,cCfO;ADYjB;AAMQ;EACE,eAAA;EACA,cAAA;AAJV;AAQM;EACE,cAAA;EACA,eAAA;AANR;AAWE;EACE,YAAA;AATJ;AAWI;EACE,WAAA;EACA,aAAA;EACA,SAAA;AATN;AAWM;EACE,WAAA;AATR","sourcesContent":["@import '../../../../variables.scss';\n\n.modalDialog {\n  .modalHeader {\n    justify-content: space-between;\n    border: none;\n  }\n\n  .modalBody {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n\n    .inputBox {\n      display: flex;\n      flex-direction: column;\n      gap: 8px;\n\n      .headerInput {\n        display: flex;\n        align-items: center;\n        width: 100%;\n        justify-content: space-between;\n\n        span {\n          font-size: 14px;\n          line-height: 18px;\n          color: $text-color-800;\n        }\n\n        .counter {\n          font-size: 12px;\n          color: #495057;\n        }\n      }\n\n      .error {\n        color: #ec205f;\n        font-size: 12px;\n      }\n    }\n  }\n\n  .modalFooter {\n    border: none;\n\n    .dlgBtns {\n      width: 100%;\n      display: flex;\n      gap: 16px;\n\n      button {\n        width: 100%;\n      }\n    }\n  }\n}\n","$white: #ffffff;\n$black: #000000;\n\n$gray-background: #f5f5f7;\n$gray-border: #dce1e6;\n$gray-box-border: #e9e9eb;\n$gray-active: #eaedef;\n$active-color: #5f8fdf;\n$active-color-op: rgba(95, 143, 223, 0.4);\n// $active-color: #00ff95;\n$sender-message-color: #cce4ff;\n\n$text-color-800: #4a596a;\n$text-color-700: #626d7a;\n$text-color-600: #909399;\n\n$active-color-2: #ec205f;\n\n// $header-color: #add8e6;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalDialog": `style_modalDialog__C01uu`,
	"modalHeader": `style_modalHeader__7fw-f`,
	"modalBody": `style_modalBody__gBJco`,
	"inputBox": `style_inputBox__qlT0Z`,
	"headerInput": `style_headerInput__WVg70`,
	"counter": `style_counter__tKVrJ`,
	"error": `style_error__SVWo9`,
	"modalFooter": `style_modalFooter__BtaO7`,
	"dlgBtns": `style_dlgBtns__YnqLB`
};
export default ___CSS_LOADER_EXPORT___;
