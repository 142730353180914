// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_mainContent__1zkaT {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  caret-color: transparent;
  position: relative;
}
@media (max-width: 991.98px) {
  .style_mainContent__1zkaT {
    width: 100%;
    height: 100%;
  }
}
.style_mainContent__1zkaT.style_openPlayerInfo__AuYuz {
  width: calc(100% - 350px);
}
@media (max-width: 1480px) {
  .style_mainContent__1zkaT.style_openPlayerInfo__AuYuz {
    width: 100%;
  }
}
@media (max-width: 991.98px) {
  .style_mainContent__1zkaT.style_openPlayerInfo__AuYuz {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Dialog/DialogList/style.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EAEA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,wBAAA;EACA,kBAAA;AAAF;AAEE;EAVF;IAWI,WAAA;IACA,YAAA;EACF;AACF;AACE;EACE,yBAAA;AACJ;AACI;EAHF;IAII,WAAA;EAEJ;AACF;AAAI;EAPF;IAQI,WAAA;EAGJ;AACF","sourcesContent":[".mainContent {\n  width: 100%;\n  // width: calc(100% - 350px);\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  caret-color: transparent;\n  position: relative;\n\n  @media (max-width: 991.98px) {\n    width: 100%;\n    height: 100%;\n  }\n\n  &.openPlayerInfo {\n    width: calc(100% - 350px);\n\n    @media (max-width: 1480px) {\n      width: 100%;\n    }\n\n    @media (max-width: 991.98px) {\n      width: 100%;\n    }\n  }\n}\n\n// .chat-conversation {\n//   flex: 1;\n//   display: flex;\n//   overflow-y: auto;\n\n//   @media (max-width: 991.98px) {\n//     height: calc(100vh - 219px);\n//     width: 100%;\n//   }\n// }\n\n// .dtc-hide {\n//   @media (max-width: 991.98px) {\n//     visibility: hidden;\n//   }\n// }\n\n// .dialog-content-half {\n//   width: 50%;\n\n//   @media (max-width: 991.98px) {\n//     visibility: hidden;\n//     width: 0px;\n//   }\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContent": `style_mainContent__1zkaT`,
	"openPlayerInfo": `style_openPlayerInfo__AuYuz`
};
export default ___CSS_LOADER_EXPORT___;
