import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { PATH_HOME, PATH_HOME_LOGIN } from 'constants/paths';
import Layout from 'components/Layout';
import HomeLogin from 'components/Login';
import Guruchat from 'pages';
import 'assets/scss/themes_.scss';
import './globals.scss';

const App = () => {
  const router = createBrowserRouter([
    {
      path: PATH_HOME,
      element: <Layout />,
      children: [
        {
          path: PATH_HOME,
          element: <Guruchat />,
        },
      ],
    },
    {
      path: PATH_HOME_LOGIN,
      element: <HomeLogin />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default App;
