import { StateCreator } from 'zustand';

interface Dialog {
  id: string;
  participants: string[];
}

interface DialogsState {
  dialogs: Dialog[];
}

interface DialogsActions {
  openDialog: (dialog: Dialog) => void;
  closeDialog: (dialogId: string) => void;
  addParticipant: (dialogId: string, participant: string) => void;
}

const createDialogsSlice: StateCreator<DialogsState & DialogsActions> = (set) => ({
  dialogs: [],
  openDialog: (dialog: Dialog) => set((state) => ({ dialogs: [...state.dialogs, dialog] })),
  closeDialog: (dialogId: string) =>
    set((state) => ({
      dialogs: state.dialogs.filter((dialog) => dialog.id !== dialogId),
    })),
  addParticipant: (dialogId: string, participant: string) =>
    set((state) => ({
      dialogs: state.dialogs.map((dialog) =>
        dialog.id === dialogId
          ? { ...dialog, participants: [...dialog.participants, participant] }
          : dialog
      ),
    })),
});

export default createDialogsSlice;
export type { DialogsState, DialogsActions };
