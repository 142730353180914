import { FC, FormEvent, memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Form } from 'reactstrap';
import { useCookies } from 'react-cookie';
import { AUTH_TAG } from 'types/Chat';
import { ENV } from '../../.env';
import { fetchLogin, fetchAutologin } from 'types/Login';
import { useInput } from 'types/React';
import classes from './style.module.scss';

const env = ENV || 'dev';
const config = require('../../config/config.json')[env];

const HomeLogin: FC = () => {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies([AUTH_TAG]);
  const login = useInput('' + config.LOGIN);
  const password = useInput('' + config.PASSWORD);
  const [checkAutologin, setCheckAutologin] = useState<boolean>(true);

  const autologin = (token: string) => {
    if (token) {
      fetchAutologin(token)
        .then((response) => {
          console.log('RESPONSE', response);
          setCookie(AUTH_TAG, JSON.stringify(response));
          navigate('/');
        })
        .catch((error) => {
          console.log('ERROR', error);
          setCheckAutologin(false);
          removeCookie(AUTH_TAG);
          navigate('/login');
        });
    } else {
      setCheckAutologin(false);
      removeCookie(AUTH_TAG);
      navigate('/login');
    }
  };

  const onLoginProc = (e: FormEvent<HTMLFormElement>, loginData: string, passwordData: string) => {
    e.preventDefault();
    fetchLogin(loginData, passwordData)
      .then((response) => {
        setCookie(AUTH_TAG, JSON.stringify(response));
        navigate('/');
      })
      .catch((error) => {
        console.log('ERROR', error);
        navigate('/login');
      });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const uToken = urlParams.get('token');
    if (uToken) {
      autologin(uToken);
    } else {
      setCheckAutologin(false);
    }
  }, []);

  return (
    <>
      {checkAutologin ? (
        <div className={classes.boxLoading}>
          <span className={classes.loader}></span>
        </div>
      ) : (
        <Form onSubmit={(e) => onLoginProc(e, login.value, password.value)}>
          <div className={classes.chatLoginScreen}>
            <div className={classes.chatLogin}>
              <h3>Welcome</h3>
              <div className={classes.inputs}>
                <Input
                  type="text"
                  {...login}
                  className="login"
                  placeholder={"User's name"}
                  style={{ resize: 'none' }}
                  maxLength={128}
                />
                <Input
                  type="password"
                  {...password}
                  className="password"
                  placeholder={"User's password"}
                  style={{ resize: 'none' }}
                  maxLength={128}
                />
              </div>
              <button type="submit" className="button primary">
                Login
              </button>
            </div>
          </div>
        </Form>
      )}
    </>
  );
};

export default memo(HomeLogin);
