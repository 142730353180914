import React, { ChangeEvent, FC, memo, useState } from 'react';
import { Input } from 'reactstrap';
import classes from './style.module.scss';

interface ISearchBlockProps {
  onSearch: (value: string) => void;
  onClear: () => void;
  placeholder: string;
}

const SearchBlock: FC<ISearchBlockProps> = (props) => {
  const { onSearch, onClear, placeholder } = props;

  const [searchValue, setSearchValue] = useState<string>('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    console.log('handle change <' + e.target.value + '>');
    setSearchValue(e.target.value);
    setTimeout(() => {
      const currentSearch = e.target.value;
      if (currentSearch !== null && currentSearch !== undefined)
        onSearch(currentSearch.toLowerCase());
    }, 300);
  };

  const handleKeyPress = (target: any) => {
    if (target.charCode === 13)
      setTimeout(() => {
        if (searchValue !== null && searchValue !== undefined) onSearch(searchValue.toLowerCase());
      }, 100);
  };

  const handleClear = () => {
    setSearchValue('');
    onClear();
  };

  return (
    <div className={classes.searchBox}>
      <div className={classes.searchBlockFrame}>
        <span className={classes.searchBlockIco}>
          <i className="ri-search-line"></i>
        </span>

        {searchValue && (
          <span className={classes.searchBlockClear} onClick={handleClear}>
            <i className="ri-close-line"></i>
          </span>
        )}

        <Input
          type="text"
          value={searchValue}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          className={classes.searchBlockCtrl}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default memo(SearchBlock);
