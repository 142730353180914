import React, { FC, memo, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import classes from './style.module.scss';
import * as Icon from 'react-bootstrap-icons';
import EmojiPicker from 'emoji-picker-react';

interface SmilesProps {
  timeout: number;
  setChosenSmile: (smile: string) => void;
}

interface EmojiClickData {
  activeSkinTone: any;
  unified: string;
  unifiedWithoutSkinTone: string;
  emoji: string;
  names: string[];
  getImageUrl: (emojiStyle: any) => string;
}

const Smiles: FC<SmilesProps> = (props) => {
  const { timeout, setChosenSmile } = props;

  const [smiles, setSmiles] = useState(false);
  const toggleSmiles = () => setSmiles(!smiles);

  const smileSelected = (data: EmojiClickData) => {
    setTimeout(() => {
      setChosenSmile('' + data.emoji);
    }, timeout);
    toggleSmiles();
  };

  const closeBtn = (
    <button className={classes.btnClose} onClick={toggleSmiles}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
      </svg>
    </button>
  );

  return (
    <span className={classes.smilesCtrl}>
      <Icon.EmojiSmile size={22} onClick={toggleSmiles} />
      <Modal className={classes.modalDialog} isOpen={smiles} toggle={toggleSmiles} centered>
        <ModalHeader className={classes.modalHeader} toggle={toggleSmiles} close={closeBtn}>
          <span>Smiles</span>
        </ModalHeader>
        <ModalBody>
          <EmojiPicker height={500} width="100%" onEmojiClick={smileSelected} />
        </ModalBody>
        {/* 
        <ModalFooter>
          <Button color="primary" onClick={toggleSmiles}>
            Select
          </Button>
        </ModalFooter>
         */}
      </Modal>
    </span>
  );
};

export default memo(Smiles);
