import { IMessage } from 'models/Message';

export interface IDialog {
  type: DialogTypeEnum;
  message: IMessage;
  isRenderDate(prevDialog?: IDialog): boolean;
  isNextBlock(nextDialog?: IDialog): boolean;
  isRenderAvatar(prevDialog?: IDialog): boolean;
  isButtons(): boolean;
  isIncoming(currentContactName: string): boolean;
  isOutgoing(): boolean;
}

export enum DialogTypeEnum {
  in = 'IN',
  out = 'OUT',
}
