import React, { MouseEvent, FC, memo, useState, useEffect } from 'react';
import SimpleBar from 'simplebar-react';
import './style.scss';
import { IContact } from 'models/Contact';
import { IShortcut } from 'models/Shortcut/type';
import { getShortMessage } from 'types/Text';

interface IShortcutBlockProps {
  shortcuts: IShortcut[];
  currentContact?: IContact;
  inputTextMessage: string;
  setInputTextMessage: (message: string) => void;
  commandsHeight: number;
  setCommandsHeight: (height: number) => void;
  showCommands: boolean;
  setShowCommands: (showOn: boolean) => void;
}

const ShortcutBlock: FC<IShortcutBlockProps> = (props) => {
  const {
    commandsHeight,
    setShowCommands,
    shortcuts,
    inputTextMessage,
    setInputTextMessage,
  } = props;

  const selectShortcut = (e: MouseEvent<HTMLLIElement>, id: string, descr: string) => {
    setInputTextMessage(descr);
    setShowCommands(false);
  };

  const [filteredShortcuts, setFilteredShortcuts] = useState<IShortcut[] | undefined>([]);

  useEffect(() => {
    let search = '';
    if (inputTextMessage && inputTextMessage.length > 1) search = inputTextMessage.substring(1);

    const filtered = shortcuts.filter((item) => {
      const current = '' + item.command;
      return (
        inputTextMessage.length <= 1 || current.toLowerCase().startsWith(search.toLocaleLowerCase())
      );
    });
    setFilteredShortcuts(filtered);
  }, [shortcuts, inputTextMessage]);

  return (
    <div className="shortcuts-block" style={{ height: commandsHeight }}>
      <SimpleBar style={{ maxHeight: '100%' }} className="shortcut-message-list">
        <ul className="list-unstyled shortcut-list" id="shortcut-list">
          {filteredShortcuts &&
            filteredShortcuts.map((shortcut, i) => {
              const id = shortcut.name;
              const descr = shortcut.description !== undefined ? shortcut.description : '';
              const command = shortcut.command;

              return (
                <li
                  key={id}
                  className={`sidebar-contact ${0 && 'unread'} ${true && 'active'}`}
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => selectShortcut(e, id, descr)}
                >
                  <span className="shortcut-text">
                    /{command} - {getShortMessage(descr)}
                  </span>
                </li>
              );
            })}
        </ul>
      </SimpleBar>
    </div>
  );
};

export default memo(ShortcutBlock);
