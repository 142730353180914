import React, { FC, memo, useEffect, useState } from 'react';
import classes from './style.module.scss';
import { getPlayerInfoCreatedAt, getPlayerInfoDatetime } from 'types/Time';
import avatar from 'assets/images/chats/avatar.svg';
import {
  presents,
  moneySpacing,
  textSpacing,
  yesNoAnser,
  getBalanceMode,
  MONEY_LIMIT,
  getCurrentBalance,
  getCurrentBalanceMode,
} from 'types/Money';
import edit_ from 'assets/images/chats/edit.svg';
import premi_ from 'assets/images/chats/premi.png';
import { Button, Input } from 'reactstrap';
import Switch from 'react-switch';
import { CONTACT_NOTES_LIMIT, Chat } from 'types/Chat';
import MultyMsg from '../MultyMsg';
import { activateTab } from 'types/React';
import closeBtn from 'assets/images/chats/close_dlg.svg';
import {
  BalanceMode,
  IBalanceChanges,
  IBalanceModeChanges,
  IPlayerBalance,
  IWeeklyResetChanges,
} from 'models/Balance';
import { IPlayerInfo, IPlayerInfoChanges, isBlacklisted, notBlacklisted } from 'models/Player';
import { IContact } from 'models/Contact';
import { ICangeStatus, IChangePlayerId, ISwitchVip } from 'types/IHallImagesGet';
import ChangeBalanceDlg from './ChangeBalanceDlg';
import CancelChangesDlg from './CancelChangesDlg';
import { extractString, extractTrue } from 'types/extract';
import { ChatCommand } from 'types/ChatCommand';

interface IPlayerInfoProps {
  playerInfo: IPlayerInfo;
  updatedBalance: number | null;
  updatedBalanceMode: BalanceMode | null;
  playerBalance: IPlayerBalance;
  currentContact?: IContact;
  showPlayerInfo: boolean;
  tooglePlayerInfo: () => void;
  savePlayerInfo: (cc: IPlayerInfoChanges) => void;
  changeBalance: (cc: IBalanceChanges) => void;
  changeBalanceMode: (cc: IBalanceModeChanges) => void;
  sendReset: (cc: IWeeklyResetChanges) => void;
  changeStatus: (cc: ICangeStatus) => void;
  switchVip: (cc: ISwitchVip) => void;
  savePlayerId: (cc: IChangePlayerId) => void;
  type: string;
  userInfo: any;
  invitedPlayerInfo: any;
}

const PlayerInfo: FC<IPlayerInfoProps> = (props) => {
  const {
    playerInfo,
    updatedBalance,
    updatedBalanceMode,
    savePlayerInfo,
    changeBalance,
    changeBalanceMode,
    sendReset,
    changeStatus,
    switchVip,
    savePlayerId,
    showPlayerInfo,
    tooglePlayerInfo,
    playerBalance,
    currentContact,
    type,
    userInfo,
    invitedPlayerInfo,
  } = props;

  const [infoTab, setInfoTab] = useState<boolean>(!true);
  const [editDetails, setEditDetails] = useState<boolean | null>(null);

  function onEditClick() {
    initPlayerInfo();
    setEditDetails(true);
  }

  function onEditBalanceClick() {
    setBalanceMovements(0);
    setBalanceModeHasChanged(false);
    setChangeMoneyDlgOpen(true);
  }

  function modeChange(mode: BalanceMode) {
    if (mode === BalanceMode.MODE_CREDIT) {
      setBalanceMode(BalanceMode.MODE_BALANCE);
      setBalanceModeHasChanged(!balanceModeHasChanged);
    } else {
      setBalanceMode(BalanceMode.MODE_CREDIT);
      setBalanceModeHasChanged(!balanceModeHasChanged);
    }
  }

  function resetChange() {
    if (weeklyReset && weeklyReset === true) {
      setWeeklyReset(false);
      onResetChanges(false);
    } else {
      setWeeklyReset(true);
      onResetChanges(true);
    }
  }

  function onResetChanges(rc: boolean) {
    if (currentContact !== null && currentContact !== undefined) {
      const arg = rc ? 1 : 0;
      const changes: IWeeklyResetChanges = {
        contact_id: currentContact.telegram_id,
        hall_id: currentContact.hall_id,
        payId: currentContact.pay_id,
        message: 'Command: setWeeklyReset ' + arg,
        type: Chat.SEND_MESSAGE,
        command: ChatCommand.SET_WEEK_RESET,
        argument: arg,
      };
      sendReset(changes);
    }
  }

  function resetChangeBlacklisted() {
    if (statusBlacklisted && statusBlacklisted === true) {
      setStatusBlacklisted(false);
      onResetChangeBlacklisted(false);
    } else {
      setStatusBlacklisted(true);
      onResetChangeBlacklisted(true);
    }
  }

  function onResetChangeBlacklisted(rc: boolean) {
    if (currentContact !== null && currentContact !== undefined) {
      const arg = rc ? 1 : 0;
      const changes: ICangeStatus = {
        hall_id: currentContact.hall_id,
        type: Chat.SEND_COMMAND,
        command: ChatCommand.CHANGE_STATUS,
        argument: '' + arg,
      };
      changeStatus(changes);
    }
  }

  function resetChangeVip() {
    if (statusVip && statusVip === true) {
      setStatusVip(false);
      onResetChangeVip(false);
    } else {
      setStatusVip(true);
      onResetChangeVip(true);
    }
  }

  function onResetChangeVip(rc: boolean) {
    if (currentContact !== null && currentContact !== undefined) {
      const arg = rc ? 1 : 0;
      const changes: ISwitchVip = {
        hall_id: currentContact.hall_id,
        type: Chat.SEND_COMMAND,
        command: ChatCommand.SWITCH_VIP,
        argument: '' + arg,
      };
      switchVip(changes);
    }
  }

  const [payAmount, setPayAmount] = useState<number>(0);
  const [creditLimit, setCreditLimit] = useState<number>(0);
  const [weekLimit, setWeekLimit] = useState<number>(0);
  const [playerId, setPlayerId] = useState<number>(0);
  const [playerIdCopy, setPlayerIdCopy] = useState<number | null>(null);
  const [piDescr, setPiDescr] = useState<string>('');
  const [adsMessages, setAdsMessages] = useState<boolean>(false);
  const [creditMessages, setCreditMessages] = useState<boolean>(false);

  const [confirmDlgOpen, setConfirmDlgOpen] = useState<boolean>(false);
  const [hasUnsaved, setHasUnsaved] = useState<boolean>(false);
  const [changeMoneyDlgOpen, setChangeMoneyDlgOpen] = useState<boolean>(false);
  const [balanceMovements, setBalanceMovements] = useState<number>(0);
  const [balanceMode, setBalanceMode] = useState<BalanceMode>(BalanceMode.MODE_BALANCE);
  const [balanceModeHasChanged, setBalanceModeHasChanged] = useState<boolean>(false);
  const [weeklyReset, setWeeklyReset] = useState<boolean | null>(null);

  const [statusBlacklisted, setStatusBlacklisted] = useState<boolean | null>(
    isBlacklisted(playerInfo.status)
  );
  const [statusVip, setStatusVip] = useState<boolean | null>(presents(playerInfo.vip));

  useEffect(() => {
    setWeeklyReset(playerInfo.weeklyReset);
    setStatusVip(presents(playerInfo.vip));
    setStatusBlacklisted(isBlacklisted(playerInfo.status));
  }, [playerInfo]);

  const toggleConfirmDlgIfUnsaved = () => {
    if (hasUnsaved) setConfirmDlgOpen(true);
    else setEditDetails(false);
  };

  const confirmDlgProceed = () => {
    setConfirmDlgOpen(false);
    setHasUnsaved(false);
    setEditDetails(false);
    initPlayerInfo();
  };

  const confirmDlgCancel = () => {
    setConfirmDlgOpen(false);
  };

  const confirmChangeBalanceMode = () => {
    if (currentContact !== null && currentContact !== undefined) {
      setTimeout(() => {
        const changes: IBalanceModeChanges = {
          contact_id: currentContact.telegram_id,
          hall_id: currentContact.hall_id,
          payId: currentContact.pay_id,
          message: 'Command: setBalanceMode ' + balanceMode,
          type: Chat.SEND_MESSAGE,
          command: ChatCommand.SET_BALANCE_MODE,
          argument: balanceMode,
        };
        changeBalanceMode(changes);
      }, 300);
    }
  };

  function confirmChangeMoney() {
    setChangeMoneyDlgOpen(false);

    if (balanceModeHasChanged) confirmChangeBalanceMode();

    if (
      currentContact !== null &&
      currentContact !== undefined &&
      balanceMovements &&
      balanceMovements !== 0
    ) {
      const changes: IBalanceChanges = {
        contact_id: currentContact.telegram_id,
        hall_id: currentContact.hall_id,
        payId: currentContact.pay_id,
        message: 'Command: changeBalance ' + balanceMovements,
        type: Chat.SEND_MESSAGE,
        command: ChatCommand.CHANGE_BALANCE,
        argument: balanceMovements,
      };
      changeBalance(changes);
    }
  }

  function cancelChangeMoney() {
    setChangeMoneyDlgOpen(false);
  }

  function onSaveClick() {
    setEditDetails(!true);

    const changes: IPlayerInfoChanges = {
      description: piDescr,
      weekLimit: weekLimit,
      creditLimit: creditLimit,
      payAmount: payAmount,
      adsMessages: adsMessages,
      creditMessages: creditMessages,
    };
    savePlayerInfo(changes);

    if (playerIdCopy !== null && currentContact !== null && currentContact !== undefined) {
      const changes: IChangePlayerId = {
        hall_id: currentContact.hall_id,
        type: Chat.SEND_COMMAND,
        command: ChatCommand.CHANGE_PLAYER_ID,
        argument: '' + playerId,
      };
      savePlayerId(changes);
    }
  }

  function onSaveCancelClick() {
    toggleConfirmDlgIfUnsaved();
  }

  function onSaveNotesClick() {
    setEditDetails(!true);

    onSaveClick();
  }

  function onSaveNotesCancelClick() {
    toggleConfirmDlgIfUnsaved();
  }

  function initPlayerInfo() {
    setTimeout(() => {
      setPlayerId(
        extractNumber(playerInfo !== null && playerInfo !== undefined ? playerInfo.playerid : 0)
      );
      setPayAmount(
        extractNumber(playerInfo !== null && playerInfo !== undefined ? playerInfo.payAmount : 0)
      );
      setCreditLimit(
        extractNumber(playerInfo !== null && playerInfo !== undefined ? playerInfo.creditLimit : 0)
      );
      setWeekLimit(
        extractNumber(playerInfo !== null && playerInfo !== undefined ? playerInfo.weekLimit : 0)
      );
      setPiDescr(
        extractString(playerInfo !== null && playerInfo !== undefined ? playerInfo.description : '')
      );
      setAdsMessages(
        extractTrue(
          playerInfo !== null && playerInfo !== undefined ? playerInfo.adsMessages : false
        )
      );
      setCreditMessages(
        extractTrue(
          playerInfo !== null && playerInfo !== undefined ? playerInfo.creditMessages : false
        )
      );
    }, 150);
  }

  function ifEmptyDescr(): string {
    const result = piDescr.length < 1 ? '-' : piDescr;
    return result;
  }

  useEffect(() => {
    initPlayerInfo();
    setEditDetails(false);
  }, [playerInfo]);

  function extractNumber(param: any) {
    let value = '' + param;
    if (value.indexOf('-') >= 0) value = '-' + value.replaceAll('-', '');
    const input = parseFloat('' + value);
    const result = Number.isNaN(input) ? 0 : input;
    return result;
  }

  function balanceChange(event: any) {
    setBalanceMovements(extractNumber(event.target.value));
  }

  function payAmountChange(event: any) {
    setPayAmount(extractNumber(event.target.value));
    setHasUnsaved(true);
  }

  function creditLimitChange(event: any) {
    setCreditLimit(extractNumber(event.target.value));
    setHasUnsaved(true);
  }

  function weekLimitChange(event: any) {
    setWeekLimit(extractNumber(event.target.value));
    setHasUnsaved(true);
  }

  function playerIdChange(event: any) {
    if (playerIdCopy === null) setPlayerIdCopy(playerId);
    setPlayerId(extractNumber(event.target.value));
    setHasUnsaved(true);
  }

  function piDescrChange(event: any) {
    setPiDescr('' + event.target.value);
    setHasUnsaved(true);
  }

  function adsMessagesChange(checked: any) {
    setAdsMessages(checked);
    setHasUnsaved(true);
  }

  function creditMessagesChange(checked: any) {
    setCreditMessages(checked);
    setHasUnsaved(true);
  }

  function activateInfo() {
    // activateTab('pi-detail-tab', 'pi-info-tab', 'pi-active');
    setInfoTab(false);
  }

  function activateDetails() {
    // activateTab('pi-info-tab', 'pi-detail-tab', 'pi-active');
    setInfoTab(true);
  }

  const firstCharUC = (str: string) => {
    if (typeof str !== 'string' || str.length === 0) {
      return ''; // Возвращаем пустую строку для нестроковых или пустых входных данных.
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const changeFormat = (str: string) => {
    if (typeof str !== 'string') {
      return '';
    }
    return str.replace(/_/g, ' ');
  };

  const closePlayerInfo = (e: any) => {
    e.stopPropagation();
    tooglePlayerInfo();
  };

  return (
    <div className={`${classes.playerInfo} ${showPlayerInfo ? classes.playerInfoOpen : ''}`}>
      <div className={classes.fixedInfo}>
        <div className={classes.playerAvatarCell}>
          {isBlacklisted(playerInfo.status) && (
            <img src={avatar} alt="not present" className={classes.playerAvatar} />
          )}
          {notBlacklisted(playerInfo.status) && (
            <img src={avatar} alt="not present" className={classes.playerAvatarOk} />
          )}
          <span
            className={classes.playerInfoCancel}
            style={{ cursor: 'pointer' }}
            onClick={(e) => closePlayerInfo(e)}
          >
            <img src={closeBtn} alt="not present" />
          </span>
        </div>
        <div className={classes.playerRealName}>
          {currentContact?.name}
          {presents(playerInfo.vip) && (
            <img className={classes.vip} src={premi_} alt="not present" />
          )}
        </div>
        {/* <span className="player-payid">{playerInfo.payId}</span> */}

        {type === 'support' && (
          <span className={classes.playerInfoTabs}>
            <span
              className={`${classes.piTab} ${!infoTab ? classes.active : ''}`}
              onMouseDown={activateInfo}
            >
              Info
            </span>
            <span
              className={`${classes.piTab} ${infoTab ? classes.active : ''}`}
              onMouseDown={activateDetails}
            >
              Details
            </span>
          </span>
        )}
      </div>

      {type === 'support' && (
        <div className={classes.stretchInfo}>
          {infoTab && !editDetails && (
            <div className={`${classes.detailsLine} ${classes.cb}`}>
              <span className={classes.detailsLineLabel}>Week limit</span>
              <span className={classes.detailsLineValue}>$&nbsp;{moneySpacing(weekLimit)}</span>
            </div>
          )}

          {infoTab && editDetails && (
            <div className={`${classes.detailsLine} ${classes.cb}`}>
              <span className={classes.detailsLineLabel}>Week limit</span>
              <span className={classes.detailsLineValue}>
                <Input
                  type="text"
                  value={weekLimit}
                  className="money-edit"
                  placeholder={'$0'}
                  style={{ resize: 'none' }}
                  onChange={weekLimitChange}
                  maxLength={MONEY_LIMIT}
                />
              </span>
            </div>
          )}

          {infoTab && !editDetails && (
            <div className={`${classes.detailsLine} ${classes.cb}`}>
              <span className={classes.detailsLineLabel}>Credit limit</span>
              <span className={classes.detailsLineValue}>$&nbsp;{moneySpacing(creditLimit)}</span>
            </div>
          )}

          {infoTab && editDetails && (
            <div className={`${classes.detailsLine} ${classes.cb}`}>
              <span className={classes.detailsLineLabel}>Credit limit</span>
              <span className={classes.detailsLineValue}>
                {/* <span className='currency-sign'>$</span> */}
                <Input
                  type="text"
                  value={creditLimit}
                  className="money-edit"
                  placeholder={'$0'}
                  style={{ resize: 'none' }}
                  onChange={creditLimitChange}
                  maxLength={MONEY_LIMIT}
                />
              </span>
            </div>
          )}

          {infoTab && !editDetails && (
            <div className={`${classes.detailsLine} ${classes.cb}`}>
              <span className={classes.detailsLineLabel}>Amount to pay</span>
              <span className={classes.detailsLineValue}>$&nbsp;{moneySpacing(payAmount)}</span>
            </div>
          )}

          {infoTab && editDetails && (
            <div className={`${classes.detailsLine} ${classes.cb}`}>
              <span className={classes.detailsLineLabel}>Amount to pay</span>
              <span className={classes.detailsLineValue}>
                <Input
                  type="text"
                  value={payAmount}
                  className="money-edit"
                  placeholder={'$0'}
                  style={{ resize: 'none' }}
                  onChange={payAmountChange}
                  maxLength={MONEY_LIMIT}
                />
              </span>
            </div>
          )}

          {infoTab && !editDetails && (
            <div className={classes.playerInfoLine}>
              <span className={`${classes.detailsLine}`}>
                <span className={classes.detailsLineLabel}>Ads messages</span>
                <span className={classes.detailsLineValue}>{yesNoAnser(adsMessages)}</span>
              </span>
              <span className={`${classes.detailsLine}`}>
                <span className={classes.detailsLineLabel}>Credit messages</span>
                <span className={classes.detailsLineValue}>{yesNoAnser(creditMessages)}</span>
              </span>
            </div>
          )}

          {infoTab && editDetails && (
            <div className={classes.playerInfoLine}>
              <span className={`${classes.detailsLine}`}>
                <span className={classes.detailsLineLabel}>Ads messages</span>
                <span className={classes.detailsLineValue}>
                  <Switch
                    onChange={adsMessagesChange}
                    checked={adsMessages === true}
                    onColor={'#5f8fdf'}
                    offColor={'#b8babe'}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    checkedHandleIcon={undefined}
                    uncheckedHandleIcon={undefined}
                    className="msg-switch"
                    height={20}
                    width={40}
                  />
                </span>
              </span>

              <span className={`${classes.detailsLine}`}>
                <span className={classes.detailsLineLabel}>Credit messages</span>
                <span className={classes.detailsLineValue}>
                  <Switch
                    onChange={creditMessagesChange}
                    checked={creditMessages === true}
                    onColor={'#5f8fdf'}
                    offColor={'#b8babe'}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    checkedHandleIcon={undefined}
                    uncheckedHandleIcon={undefined}
                    className="msg-switch"
                    height={20}
                    width={40}
                  />
                </span>
              </span>
            </div>
          )}

          {infoTab && !editDetails && (
            <div className={`${classes.detailsLine}`}>
              <button onClick={onEditClick} className="button default">
                <img src={edit_} alt="not present" />
                <span>Edit</span>
              </button>
            </div>
          )}

          {infoTab && editDetails && (
            <div className={classes.playerInfoLine}>
              <span className={`${classes.detailsLine}`}>
                <button onClick={onSaveCancelClick} className="button default">
                  Cancel
                </button>
              </span>
              <span className={`${classes.detailsLine}`}>
                <button onClick={onSaveClick} className="button primary">
                  Save
                </button>
              </span>
            </div>
          )}

          {!infoTab && editDetails && (
            <div className={classes.playerInfoLine}>
              <span className={`${classes.detailsLine} ${classes.cb} ${classes.dlEdit}`}>
                <span className={classes.detailsLineLabel}>Player ID</span>
                <span className={classes.detailsLineValue}>
                  <Input
                    type="text"
                    value={playerId}
                    className="money-edit"
                    style={{ resize: 'none' }}
                    onChange={playerIdChange}
                    maxLength={MONEY_LIMIT}
                  />
                </span>
              </span>
            </div>
          )}

          {!infoTab && !editDetails && (
            <div className={classes.playerInfoLine}>
              <span className={`${classes.detailsLine} ${classes.cb}`}>
                <span className={classes.playerIdLine}>
                  <span className={classes.editorPlayerId}>
                    <span className={classes.detailsLineLabel}>Player ID</span>
                    <span className={classes.detailsLineValue}>{playerInfo.playerid}</span>
                  </span>
                </span>
              </span>
            </div>
          )}

          {!infoTab && !editDetails && (
            <div className={classes.playerInfoLine}>
              <span className={`${classes.detailsLine} ${classes.cb}`}>
                <span className={classes.detailsLineLabel}>Registered</span>
                <span className={classes.detailsLineValue}>
                  {getPlayerInfoCreatedAt(playerInfo)}
                </span>
              </span>
              <span className={`${classes.detailsLine} ${classes.cb}`}>
                <span className={classes.detailsLineLabel}>Telegram name</span>
                <span className={classes.detailsLineValue}>{textSpacing(playerInfo.tg_name)}</span>
              </span>
            </div>
          )}

          {!infoTab && (
            <div className={classes.playerInfoLine}>
              <span className={`${classes.detailsLine} ${classes.cb}`}>
                <span className={classes.detailsLineLabel}>First deposit</span>
                <span className={classes.detailsLineValue}>
                  $&nbsp;{moneySpacing(playerBalance.firstDeposit)}
                </span>
              </span>

              <span className={`${classes.detailsLine} ${classes.cb}`}>
                <span className={classes.detailsLineLabel}>Last deposit</span>
                <span className={classes.detailsLineValue}>
                  $&nbsp;{moneySpacing(playerBalance.lastDeposit)}
                </span>
              </span>
            </div>
          )}

          {!infoTab && (
            <div className={classes.playerInfoLine}>
              <span className={`${classes.detailsLine} ${classes.cb}`}>
                <span className={classes.detailsLineLabel}>Total deposit</span>
                <span className={classes.detailsLineValue}>
                  $&nbsp;{moneySpacing(playerBalance.totalDeposits)}
                </span>
              </span>

              <span className={`${classes.detailsLine} ${classes.cb}`}>
                <span className={classes.detailsLineLabel}>Total withdraw</span>
                <span className={classes.detailsLineValue}>
                  $&nbsp;{moneySpacing(playerBalance.totalWithdrawals)}
                </span>
              </span>
            </div>
          )}

          {!infoTab && (
            <div className={classes.playerInfoLine}>
              <span className={`${classes.detailsLine} ${classes.cb}`}>
                <span className={classes.detailsLineLabel}>Label</span>
                <span className={classes.detailsLineValue}>{textSpacing(playerInfo.label)}</span>
              </span>

              <span className={`${classes.detailsLine} ${classes.cb}`}>
                <span className={classes.detailsLineLabel}>Daily TRX remain</span>
                <span className={classes.detailsLineValue}>{playerInfo.remainTRX}</span>
              </span>
            </div>
          )}

          {!infoTab && !editDetails && (
            <div className={classes.playerInfoLine}>
              <span className={`${classes.detailsLine} ${classes.cb}`}>
                <span className={classes.detailsLineLabel}>Blacklisted</span>
                <span className={classes.detailsLineValue}>{yesNoAnser(statusBlacklisted)}</span>
              </span>

              <span className={`${classes.detailsLine} ${classes.cb}`}>
                <span className={classes.detailsLineLabel}>Vip</span>
                <span className={classes.detailsLineValue}>{yesNoAnser(statusVip)}</span>
              </span>
            </div>
          )}

          {!infoTab && editDetails && (
            <div className={classes.playerInfoLine}>
              <span className={`${classes.detailsLine} ${classes.cb}`}>
                <span className={classes.detailsLineLabel}>Blacklisted</span>
                <span className={classes.detailsLineValueChk}>
                  <Switch
                    onChange={resetChangeBlacklisted}
                    checked={yesNoAnser(statusBlacklisted) === 'Yes'}
                    onColor={'#5f8fdf'}
                    offColor={'#B8BABE'}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    checkedHandleIcon={undefined}
                    uncheckedHandleIcon={undefined}
                    className={classes.msgSwitch}
                    height={20}
                    width={40}
                  />
                  <span className={classes.yesNoWrap}>{yesNoAnser(statusBlacklisted)}</span>
                </span>
              </span>

              <span className={`${classes.detailsLine} ${classes.cb}`}>
                <span className={classes.detailsLineLabel}>Vip</span>
                <span className={classes.detailsLineValueChk}>
                  <Switch
                    onChange={resetChangeVip}
                    checked={yesNoAnser(statusVip) === 'Yes'}
                    onColor={'#5f8fdf'}
                    offColor={'#B8BABE'}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    checkedHandleIcon={undefined}
                    uncheckedHandleIcon={undefined}
                    className={classes.msgSwitch}
                    height={20}
                    width={40}
                  />
                  <span className={classes.yesNoWrap}>{yesNoAnser(statusVip)}</span>
                </span>
              </span>
            </div>
          )}

          {!infoTab && !editDetails && (
            <div className={classes.detailsLineDescr}>
              <span className={classes.editNotes}>
                <span className={classes.detailsLineLabel}>Notes</span>
              </span>
              <span className={classes.detailsLineValue}>
                <MultyMsg msg={ifEmptyDescr()} />
              </span>
            </div>
          )}

          {!infoTab && editDetails && (
            <div className={`${classes.detailsLine} ${classes.neHi}`}>
              <span className={classes.detailsLineLabel}>Notes</span>
              <span className={classes.detailsLineValue}>
                <Input
                  type="textarea"
                  value={piDescr}
                  className="notes-edit"
                  placeholder={'Type a comment'}
                  style={{ resize: 'none' }}
                  onChange={piDescrChange}
                  maxLength={CONTACT_NOTES_LIMIT}
                />
              </span>
            </div>
          )}

          {!infoTab && !editDetails && (
            <div className={`${classes.detailsLineDescr} ${classes.cb}`}>
              <button onClick={onEditClick} className="button default">
                <img src={edit_} alt="not present" />
                <span>Edit</span>
              </button>
            </div>
          )}

          {!infoTab && editDetails && (
            <div className={`${classes.playerInfoLine} ${classes.cb}`}>
              <span className={`${classes.detailsLine}`}>
                <button onClick={onSaveNotesCancelClick} className="button default">
                  Cancel
                </button>
              </span>
              <span className={`${classes.detailsLine}`}>
                <button onClick={onSaveNotesClick} className="button primary">
                  Save
                </button>
              </span>
            </div>
          )}

          {!infoTab && (
            <div className={classes.playerInfoLine}>
              <span className={`${classes.detailsLine} ${classes.cb}`}>
                <span className={classes.detailsLineLabel}>Balance</span>
                <span className={classes.detailsLineValue}>
                  $&nbsp;{moneySpacing(getCurrentBalance(playerInfo.balance, updatedBalance))}
                </span>
              </span>

              <span className={`${classes.detailsLine} ${classes.cb}`}>
                <span className={classes.detailsLineLabel}>Balance mode</span>
                <span className={classes.detailsLineValue}>
                  {getBalanceMode(
                    getCurrentBalanceMode(playerInfo.balanceMode, updatedBalanceMode)
                  )}
                </span>
              </span>
            </div>
          )}

          {!infoTab && (
            <div className={classes.playerInfoLine}>
              <span className={`${classes.detailsLine} ${classes.cb}`}>
                <span className={classes.detailsLineLabel}>Last week balance</span>
                <span className={classes.detailsLineValue}>
                  $&nbsp;{moneySpacing(playerInfo.balanceLastWeek)}
                </span>
              </span>

              <span className={`${classes.detailsLine} ${classes.cb}`}>
                <span className={classes.detailsLineLabel}>Before last week balance</span>
                <span className={classes.detailsLineValue}>
                  $&nbsp;{moneySpacing(playerInfo.balanceLastWeekBefore)}
                </span>
              </span>
            </div>
          )}

          {!infoTab && (
            <div className={classes.playerInfoLine}>
              <span className={`${classes.detailsLine} ${classes.cb}`}>
                <span className={classes.detailsLineLabel}>Weekly balance reset</span>
                <span className={classes.detailsLineValue}>{yesNoAnser(weeklyReset)}</span>
              </span>

              <span className={`${classes.detailsLine} ${classes.cb}`}>
                <span className={classes.detailsLineLabel}>Debt</span>
                <span className={classes.detailsLineValue}>
                  $&nbsp;{moneySpacing(playerInfo.debt)}
                </span>
              </span>
            </div>
          )}

          {!infoTab && (
            <div className={classes.playerInfoLine}>
              <span className={`${classes.detailsLine}`}>
                <span className={classes.detailsLineLabel}>Next withdraw</span>
                <span className={classes.detailsLineValue}>
                  {getPlayerInfoDatetime(playerInfo.nextWithdrawal)}
                </span>
              </span>

              <span className={`${classes.detailsLine}`}>
                <span className={classes.detailsLineLabel}>Last withdraw</span>
                <span className={classes.detailsLineValue}>
                  {getPlayerInfoDatetime(playerInfo.lastWithdrawal)}
                </span>
              </span>
            </div>
          )}

          {!infoTab && (
            <div className={classes.detailsLine}>
              <button onClick={onEditBalanceClick} className="button default">
                Change balance
              </button>
            </div>
          )}
        </div>
      )}
      {type === 'operators' && (
        <div className={classes.infoUserWrapper}>
          {Object.keys(userInfo).map((item) => {
            return (
              <div className={classes.playerInfoLine} key={item}>
                <span className={`${classes.detailsLine}`}>
                  <span className={classes.detailsLineLabel}>{firstCharUC(item)}</span>
                  <span className={classes.detailsLineValue}>
                    {' '}
                    {userInfo[item] === null ? '-' : userInfo[item]}
                  </span>
                </span>
              </div>
            );
          })}
        </div>
      )}
      {type === 'invite' && (
        <div className={classes.infoUserWrapper}>
          {Object.keys(invitedPlayerInfo).map((item) => {
            if (item !== 'id')
              return (
                <div className={classes.playerInfoLine} key={item}>
                  <span className={`${classes.detailsLine}`}>
                    <span className={classes.detailsLineLabel}>
                      {firstCharUC(changeFormat(item))}
                    </span>
                    <span className={classes.detailsLineValue}>
                      {invitedPlayerInfo[item] === null ? '-' : invitedPlayerInfo[item]}
                    </span>
                  </span>
                </div>
              );
          })}
        </div>
      )}

      <CancelChangesDlg
        confirmDlgOpen={confirmDlgOpen}
        confirmDlgProceed={confirmDlgProceed}
        confirmDlgCancel={confirmDlgCancel}
      />

      <ChangeBalanceDlg
        changeMoneyDlgOpen={changeMoneyDlgOpen}
        balanceMovements={balanceMovements}
        balanceChange={balanceChange}
        balanceMode={balanceMode}
        modeChange={modeChange}
        confirmChangeMoney={confirmChangeMoney}
        cancelChangeMoney={cancelChangeMoney}
        weeklyReset={weeklyReset}
        resetChange={resetChange}
      />
    </div>
  );
};

export default memo(PlayerInfo);
