import React, { ChangeEvent, MouseEvent, FC, memo, useState, useEffect, useRef } from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import classes from './style.module.scss';
import * as Icon from 'react-bootstrap-icons';
import { Chat } from 'types/Chat';
import MultyMsg from '../MultyMsg';
import {
  EDITABLE_CATEGORY,
  IGalleryImage,
  IGalleryMessage,
  IGalleryTransport,
  IHallImagesGet,
  IHallMessagesGet,
} from 'types/IHallImagesGet';
import add_ from 'assets/images/chats/add_.svg';
import delete_ from 'assets/images/chats/delete.svg';
import edit_ from 'assets/images/chats/edit.svg';
import search_ from 'assets/images/chats/search_upl.png';
import search_close_ from 'assets/images/chats/search_close_upl.png';
import close_ from 'assets/images/chats/close_upl.png';
import SearchBlock from 'components/Contacts/SearchBlock';
import { activateTab } from 'types/React';
import SimpleBar from 'simplebar-react';
import TabsCtrl from 'components/TabsCtrl';
import { EditableEntityId } from 'types/soket';
import CreateMsgTemplateDlg from './CreateMsgTemplateDlg';
import SaveImageTemplateDlg from './SaveImageTemplateDlg';
import DeleteMsgTemplateDlg from './DeleteMsgTemplateDlg';
import { ChatCommand } from 'types/ChatCommand';

interface AddImagesProps extends IGalleryTransport {
  side: number;
  idHall: number;
  textMessage: string;
  setTextMessage: (message: string) => void;
  imageFiles: File[];
  setImageFiles: (images: File[]) => void;
  imagePaths: string[];
  setImagePaths: (pathes: string[]) => void;
}

interface ITab {
  id: number;
  name: string;
}

const AddImages: FC<AddImagesProps> = (props) => {
  const {
    side,
    getHallImages,
    getHallMessages,
    imagesFromGallery,
    messagesFromGallery,
    createMsgTemplate,
    idHall,
    setTextMessage,
    imageFiles,
    setImageFiles,
    imagePaths,
    deleteMsgTemplate,
    setImagePaths,
  } = props;

  const EVERY = 'Select';

  const [imagesDlgOpen, setImagesDlgOpen] = useState<boolean>(false);
  const [imageSaveDlgOpen, setImageSaveDlgOpen] = useState<boolean>(false);
  const [messageSaveDlgOpen, setMessageSaveDlgOpen] = useState<boolean>(false);
  const [askMessageDeleteConfirmDlgOpen, setAskMessageDeleteConfirmDlgOpen] =
    useState<boolean>(false);
  const [imageChanges, setImageChanges] = useState<boolean>(false);
  const [imagePathsCopy, setImagePathsCopy] = useState<string[]>([]);
  const [uploadingFile, setUploadingFile] = useState<string | null>(null);
  const [messageChanges, setMessageChanges] = useState<boolean>(false);
  const [selectedMessage, setSelectedMessage] = useState<IGalleryMessage | null>(null);
  const [filteredGalleryMessages, setFilteredGalleryMessages] = useState<
    IGalleryMessage[] | undefined
  >([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const [filteredGalleryImages, setFilteredGalleryImages] = useState<IGalleryImage[] | undefined>(
    []
  );

  const [imgCategory, setImgCategory] = useState<string>(EVERY);
  const [imgAddTemplateCategory, setImgAddTemplateCategory] = useState<string>(EVERY);
  const [msgAddTemplateCategory, setMsgAddTemplateCategory] = useState<string>(EVERY);
  const [msgCategory, setMsgCategory] = useState<string>(EVERY);

  const [msgTemplateRef, setMsgTemplateRef] = useState<IGalleryMessage>();
  const [msgTemplateId, setMsgTemplateId] = useState<EditableEntityId>(undefined);
  const [msgTemplateName, setMsgTemplateName] = useState<string>('');
  const [msgTemplateShortcut, setMsgTemplateShortcut] = useState<string>('');
  const [msgTemplateMessage, setMsgTemplateMessage] = useState<string>('');

  const [activeTab, setActiveTab] = useState<ITab>({ id: 1, name: 'Image' });
  const Tabs: ITab[] = [
    { id: 1, name: 'Image' },
    { id: 2, name: 'Message' },
  ];
  const [errorUpload, setErrorUpload] = useState<boolean>(false);

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const ref = useRef<any>(null);

  function reloadMessages() {
    if (idHall !== null && idHall !== undefined) {
      onGetHallMessages();
    }
  }

  function msgCategoryChange(event: any) {
    setMsgCategory(event.target.value);

    setTimeout(() => {
      updateSearchMessages(searchValue !== null ? searchValue : '', event.target.value);
    }, 130);
  }

  function imgCategoryChange(event: any) {
    setImgCategory(event.target.value);

    setTimeout(() => {
      updateSearchImages(event.target.value);
    }, 130);
  }

  function msgTemplateNameChange(event: any) {
    setMsgTemplateName(event.target.value);
  }

  function msgTemplateShortcutChange(event: any) {
    setMsgTemplateShortcut(event.target.value);
  }

  function msgTemplateMessageChange(event: any) {
    setMsgTemplateMessage(event.target.value);
  }

  function msgAddTemplateCategoryChange(event: any) {
    setMsgAddTemplateCategory(event.target.value);
  }

  const getInitEditableCategory = (): string => {
    const result = EDITABLE_CATEGORY;
    setMsgAddTemplateCategory(result);
    return result;
  };

  function imgAddTemplateCategoryChange(event: any) {
    setImgAddTemplateCategory(event.target.value);
  }

  function getCategoriesOfImages(): string[] {
    let result = new Set();
    let keys: string[] = [EVERY];
    console.log('keys-first1', keys);
    if (imagesFromGallery)
      imagesFromGallery.forEach((file, i) => {
        if (file.category) result.add('' + file.category);
      });
    for (let k of result) keys.push('' + k);
    console.log('keys-first2', keys);
    return keys;
  }

  function getCategoriesOfMessages(): string[] {
    let result = new Set();
    let keys: string[] = [EVERY];
    if (messagesFromGallery)
      messagesFromGallery.forEach((msg, i) => {
        if (msg.type) result.add('' + msg.type);
      });
    for (let k of result) keys.push('' + k);
    return keys;
  }

  function onGetHallImages() {
    const arg = null;
    const imagesHallReq: IHallImagesGet = {
      hall_id: idHall,
      type: Chat.SEND_COMMAND,
      command: ChatCommand.GET_HALL_IMAGES,
      argument: arg,
    };
    getHallImages(imagesHallReq);
  }

  function onGetHallMessages() {
    const arg = null;
    const messagesHallReq: IHallMessagesGet = {
      hall_id: idHall,
      type: Chat.SEND_COMMAND,
      command: ChatCommand.GET_HALL_MESSAGES,
      argument: arg,
    };
    getHallMessages(messagesHallReq);
  }

  const closeGalleryDlg = (e: any) => {
    cancelSelectedImage();
  };

  const cancelSelectedImage = () => {
    setErrorUpload(false);
    setImagesDlgOpen(false);
    if (imageChanges) {
      setImageChanges(false);
      setImagePaths(imagePathsCopy);
    }
    if (messageChanges) {
      setMessageChanges(false);
      setSelectedMessage(null);
    }
  };

  function createNewTemplate() {
    setMsgTemplateName('');
    setMsgTemplateShortcut('');
    setMsgTemplateMessage('');
    setMessageSaveDlgOpen(true);
  }

  const handleImageChangeListed = (e: MouseEvent<any>, file: string) => {
    if (file) {
      // let ff: File[] = [];
      let fp: string[] = [];
      if (0 && imageFiles) {
        // ff = ff.concat([...imageFiles]);
        fp = fp.concat([...imagePaths]);
      }
      const f = file;
      // ff.push(f);
      fp.push(f);
      setImageFiles([]);
      setImagePaths(fp);
      setImageChanges(true);
      setUploadingFile(f);
    }
  };

  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setErrorUpload(false);
    if (e.target.files) {
      if (['image/jpeg', 'image/png'].includes(e.target.files[0].type)) {
        let ff: File[] = [];
        let fp: string[] = [];
        if (0 && imageFiles) {
          ff = ff.concat([...imageFiles]);
          fp = fp.concat([...imagePaths]);
        }
        for (let i = 0; i < e.target.files.length; i++) {
          const f = e.target.files[i];
          ff.push(f);
          fp.push(URL.createObjectURL(f));
        }
        setImageFiles(ff);
        setImagePaths(fp);
        setImageChanges(true);
        setUploadingFile(fp[fp.length - 1]);
        confirmSelectedImage();
        // proposeAddImageTemplate();
      } else {
        setErrorUpload(true);
      }
    }
  };

  function proposeAddImageTemplate() {
    setImageSaveDlgOpen(true);
  }

  function cancelAddMessageTemplete() {
    console.log('cancel');
    setMessageSaveDlgOpen(false);
  }

  function performMsgTemplateDelete() {
    console.log('delete msg template ' + msgTemplateId);
    if (msgTemplateId && msgTemplateId > 0) {
      deleteMsgTemplate(msgTemplateId);

      // messagesFromGallery?.slice()
    }
  }

  function confirmDelMessageTemplete() {
    performMsgTemplateDelete();
    setAskMessageDeleteConfirmDlgOpen(false);
  }

  function cancelDelMessageTemplete() {
    setAskMessageDeleteConfirmDlgOpen(false);
  }

  function confirmAddMessageTemplete() {
    setMessageSaveDlgOpen(false);
    addMessageToTemplates();
    confirmSelectedMessage();
    reloadMessages();
  }

  const confirmSelectedMessage = () => {
    if (selectedMessage) setTextMessage(selectedMessage.body);
  };

  function cancelAddImageTemplete() {
    console.log('cancel');
    setImageSaveDlgOpen(false);
    confirmSelectedImage();
  }

  function extractShortcut(shortcut: string) {
    if (shortcut) {
      if (shortcut.startsWith('/')) return shortcut.substring(1);
    }
    return shortcut;
  }

  function updateMsgTemplateReference() {
    if (msgTemplateRef) {
      msgTemplateRef.title = msgTemplateName;
      msgTemplateRef.body = msgTemplateMessage;
      msgTemplateRef.shortcat = extractShortcut(msgTemplateShortcut);
    }
  }

  function addMessageToTemplates() {
    console.log('propose new message template');

    updateMsgTemplateReference();

    const param: IGalleryMessage = {
      title: msgTemplateName,
      body: msgTemplateMessage,
      shortcat: extractShortcut(msgTemplateShortcut),
      type: undefined,
      id: msgTemplateId && msgTemplateId > 0 ? msgTemplateId : undefined,
    };
    createMsgTemplate(param);
  }

  function addImageToTemplates() {
    console.log('propose new image template');
  }

  function confirmAddImageTemplete() {
    console.log('confirm');
    setImageSaveDlgOpen(false);
    addImageToTemplates();
    confirmSelectedImage();
  }

  const confirmSelectedImage = () => {
    // refInput.onProps.onFocus();
    // refInput.focus = 'true';
    setMessageChanges(false);
    setSelectedMessage(null);
    setImagesDlgOpen(false);
    if (selectedMessage) setTextMessage(selectedMessage.body);
    (document.getElementsByClassName('send-message-ctrl')[0] as HTMLElement)?.focus();
  };

  const handleMessageChange = (e: MouseEvent<any>, message: IGalleryMessage) => {
    if (message) {
      setMessageChanges(true);
      setSelectedMessage(message);
    }
  };

  const editMsgTemplate = (sel: IGalleryMessage) => {
    setMsgTemplateRef(sel);
    setMsgTemplateId(sel.id);
    setMsgTemplateName(sel.title);
    setMsgTemplateShortcut(sel.shortcat);
    setMsgTemplateMessage(sel.body);
    setMessageSaveDlgOpen(true);
  };

  const checkEditRight = (sel: IGalleryMessage) => {
    const result = sel.type === EDITABLE_CATEGORY;
    return result;
  };

  const delMsgTemplate = (sel: IGalleryMessage) => {
    setMsgTemplateRef(sel);
    setMsgTemplateId(sel.id);
    setAskMessageDeleteConfirmDlgOpen(true);
  };

  const toogleSelectImages = () => {
    console.log('');
    setImagesDlgOpen(true);
    setImagePathsCopy(imagePaths);
    setUploadingFile(null);
    setTimeout(() => {
      reloadImages();
    }, 300);
    setTimeout(() => {
      reloadMessages();
    }, 300);
    setTimeout(() => {
      setFilteredGalleryMessages(messagesFromGallery);
    }, 300);
    setTimeout(() => {
      setFilteredGalleryImages(imagesFromGallery);
    }, 300);
  };

  function reloadImages() {
    if (idHall !== null && idHall !== undefined) {
      // FAIL HALL IMAGE
      onGetHallImages();
    }
  }

  useEffect(() => {
    updateSearchMessages(searchValue, msgCategory);
  }, [messagesFromGallery]);

  function updateSearchMessages(value: string, category: string) {
    if (messagesFromGallery && value !== null && value !== undefined) {
      const filteredMessages = messagesFromGallery.filter((msg) => {
        const { title, body, type } = msg;
        const msgInfo = '' + title + '' + body + '' + type;
        return (
          (msgInfo.toLowerCase().indexOf(value) > -1 || value.length === 0) &&
          (category === EVERY || category === type)
        );
      });
      setFilteredGalleryMessages(filteredMessages);
    } else setFilteredGalleryMessages(messagesFromGallery);
  }

  useEffect(() => {
    updateSearchImages(imgCategory);
  }, [imagesFromGallery]);

  function updateSearchImages(ct: string) {
    if (imagesFromGallery) {
      const filteredImages = imagesFromGallery.filter((img) => {
        const { category } = img;
        return ct === EVERY || category === ct;
      });
      setFilteredGalleryImages(filteredImages);
    } else setFilteredGalleryImages(imagesFromGallery);
  }

  const onSearcMessageshHandler = (value: string) => {
    setSearchValue(value);

    updateSearchMessages(value, msgCategory);
  };

  const onClearSearchMessagesHandler = () => {
    setFilteredGalleryMessages(messagesFromGallery);
  };

  function activateImages() {
    // activateTab('upl-message-tab', 'upl-image-tab', 'upl-tab-active');
    setImageTab(true);
  }

  function activateMessages() {
    // activateTab('upl-image-tab', 'upl-message-tab', 'upl-tab-active');
    setImageTab(false);
  }

  const [imageTab, setImageTab] = useState<boolean>(true);
  const [msgSearch, setMsgSearch] = useState<boolean>(true);

  const ico_side = side ?? 32;

  function recoverMessagesTab() {
    if (!imagesDlgOpen) return;
    if (!imageTab)
      setTimeout(() => {
        // activateTab('upl-image-tab', 'upl-message-tab', 'upl-tab-active');
      }, 130);
  }
  recoverMessagesTab();

  function msgSearchMode() {
    setMsgSearch(false);
  }

  function msgSearchClose() {
    setMsgSearch(true);
  }

  const onWheelMessages = () => {
    if (ref.current) {
      const el = ref.current.getScrollElement();
      if (el) {
        console.log('wheel down');
      }
    }
  };

  const onScrollMessages = () => {
    if (ref.current) {
      const el = ref.current.getScrollElement();
      if (el) {
        console.log('scrolled to top');
      }
    }
  };

  const closeBtn = (
    <button className={classes.btnClose} onClick={closeGalleryDlg}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
      </svg>
    </button>
  );

  function onMessagesFocusOver() {
    console.log('over messages ' + JSON.stringify(selectedMessage));
  }

  return (
    <div className={classes.addImgContainer}>
      <label id="images" className={classes.btnAdd} onClick={toogleSelectImages}>
        <Icon.Plus size={ico_side} />
      </label>

      <DeleteMsgTemplateDlg
        askMessageDeleteConfirmDlgOpen={askMessageDeleteConfirmDlgOpen}
        title={msgTemplateRef?.title}
        cancelDelMessageTemplete={cancelDelMessageTemplete}
        confirmDelMessageTemplete={confirmDelMessageTemplete}
      />

      <CreateMsgTemplateDlg
        messageSaveDlgOpen={messageSaveDlgOpen}
        msgAddTemplateCategoryChange={msgAddTemplateCategoryChange}
        messagesFromGallery={messagesFromGallery}
        getCategoriesOfMessages={getCategoriesOfMessages}
        msgTemplateName={msgTemplateName}
        msgTemplateNameChange={msgTemplateNameChange}
        msgTemplateShortcut={msgTemplateShortcut}
        msgTemplateShortcutChange={msgTemplateShortcutChange}
        msgTemplateMessage={msgTemplateMessage}
        msgTemplateMessageChange={msgTemplateMessageChange}
        cancelAddMessageTemplete={cancelAddMessageTemplete}
        confirmAddMessageTemplete={confirmAddMessageTemplete}
      />

      <SaveImageTemplateDlg
        imageSaveDlgOpen={imageSaveDlgOpen}
        imgAddTemplateCategoryChange={imgAddTemplateCategoryChange}
        imagesFromGallery={imagesFromGallery}
        getCategoriesOfImages={getCategoriesOfImages}
        cancelAddImageTemplete={cancelAddImageTemplete}
        confirmAddImageTemplete={confirmAddImageTemplete}
      />

      <Modal
        returnFocusAfterClose={true}
        isOpen={imagesDlgOpen}
        centered
        className={classes.modalDialog}
      >
        <ModalHeader toggle={closeGalleryDlg} close={closeBtn} className={classes.modalHeader}>
          Upload file
        </ModalHeader>
        <ModalBody className={classes.modalBody}>
          <div className={classes.uplDlgBody}>
            <div className={classes.wrapperTabs}>
              {Tabs.map((tab, index) => (
                <button
                  key={tab.id}
                  className={`${classes.tab} ${index + 1 === activeTab?.id ? classes.active : ''}`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab.name}
                </button>
              ))}
            </div>

            {activeTab.id === 1 && (
              <div className={classes.contentTab}>
                {/* <div
                  className={`${classes.imageAddNew} ${errorUpload && classes.error}`}
                  onClick={() => {
                    hiddenFileInput.current?.click();
                  }}
                >
                  <img src={add_} alt="not present" />
                  <span>Add new image</span>
                  <input
                    onChange={(e) => handleImageChange(e)}
                    accept="image/*"
                    type="file"
                    hidden
                    ref={hiddenFileInput}
                  />
                </div> */}
                {errorUpload && (
                  <div className={classes.errorUpload}>
                    Unsupported file format. Only PNG and JPG files are supported
                  </div>
                )}
                {imagesFromGallery ? (
                  <>
                    <div className={classes.selectBox}>
                      <span>Category</span>
                      <Input
                        id="imgCategory"
                        className="images-list-ctrl"
                        name="imgCategory"
                        type="select"
                        onChange={imgCategoryChange}
                      >
                        {imagesFromGallery &&
                          getCategoriesOfImages().map((category, i) => {
                            return <option key={'catgr' + i}>{category}</option>;
                          })}
                      </Input>
                    </div>

                    <SimpleBar
                      ref={ref}
                      forceVisible="y"
                      autoHide={true}
                      className={classes.imagesListScroll}
                      onScrollCapture={onScrollMessages}
                      onWheelCapture={onWheelMessages}
                    >
                      <ul className={classes.imagesListItems}>
                        {filteredGalleryImages &&
                          filteredGalleryImages.map((file, i) => {
                            return (
                              <div key={i} className={classes.imgInsertBox}>
                                <img
                                  className={`${classes.imgInsert} ${
                                    uploadingFile === file.filename
                                      ? classes.focus
                                      : classes.dontFocus
                                  }`}
                                  src={file.filename}
                                  onClick={(e) => handleImageChangeListed(e, file.filename)}
                                  alt="not present"
                                />
                              </div>
                            );
                          })}
                      </ul>
                    </SimpleBar>
                  </>
                ) : (
                  <div className={classes.notData}>No images</div>
                )}
              </div>
            )}

            {activeTab.id === 2 && (
              <div className={classes.contentTab}>
                <div className={classes.imageAddNew} onClick={createNewTemplate}>
                  <img src={add_} alt="not present" />
                  <span>Create new template</span>
                </div>

                <div className={classes.actionBox}>
                  <div className={classes.containerInput}>
                    {msgSearch && (
                      <div className={classes.selectBox}>
                        <span className="images-list-lbl">Category</span>
                        <Input
                          id="msgCategory"
                          name="msgCategory"
                          className="messages-list-ctrl"
                          type="select"
                          onChange={msgCategoryChange}
                        >
                          {messagesFromGallery &&
                            getCategoriesOfMessages().map((category, i) => {
                              return <option key={'catgr_m' + i}>{category}</option>;
                            })}
                        </Input>
                      </div>
                    )}
                    {!msgSearch && (
                      <div className={classes.myPadding}>
                        <SearchBlock
                          onSearch={onSearcMessageshHandler}
                          onClear={onClearSearchMessagesHandler}
                          placeholder={'Search messages'}
                        />
                      </div>
                    )}
                  </div>
                  <div className={classes.toggleBtn}>
                    {msgSearch && (
                      <label className={classes.icoAction} id="images" onClick={msgSearchMode}>
                        <img src={search_} alt="not present" />
                      </label>
                    )}
                    {!msgSearch && (
                      <label className={classes.icoAction} id="images" onClick={msgSearchClose}>
                        <img src={search_close_} alt="not present" />
                      </label>
                    )}
                  </div>
                </div>

                <SimpleBar
                  ref={ref}
                  forceVisible="y"
                  autoHide={true}
                  className={classes.messageListScroll}
                  onMouseOver={onMessagesFocusOver}
                  onScrollCapture={onScrollMessages}
                  onWheelCapture={onWheelMessages}
                >
                  <ul className={classes.tmplMsgList}>
                    {filteredGalleryMessages &&
                      filteredGalleryMessages.map((message, i) => {
                        return (
                          <li
                            key={i}
                            className={`${classes.templateMsg} ${selectedMessage === message ? classes.focus : ''}`}
                            onClick={(e) => handleMessageChange(e, message)}
                          >
                            <div className={classes.headerTemplate}>
                              <div className={classes.info}>
                                <span>{message.title}</span>
                                <span>Shortcut: {message.shortcat}</span>
                              </div>
                              <div className={classes.action}>
                                {checkEditRight(message) && (
                                  <span
                                    className={classes.ico}
                                    onClick={() => editMsgTemplate(message)}
                                  >
                                    <img src={edit_} alt="not present" />
                                  </span>
                                )}
                                {checkEditRight(message) && <div className={classes.divider}></div>}
                                {checkEditRight(message) && (
                                  <span
                                    className={classes.ico}
                                    onClick={() => delMsgTemplate(message)}
                                  >
                                    <img src={delete_} alt="not present" />
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className={classes.messageTemplate}>{message.body}</div>
                          </li>
                        );
                      })}
                  </ul>
                </SimpleBar>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter className={classes.modalFooter}>
          <span className={classes.dlgBtns}>
            <button onClick={cancelSelectedImage} className="button default">
              Cancel
            </button>
            <button onClick={confirmSelectedImage} className="button primary">
              Add
            </button>
          </span>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default memo(AddImages);
