import { StateCreator } from 'zustand';

export interface Group {
  value: string;
  title: string;
  unreadCount: number;
}

interface GroupsState {
  groups: Group[];
  activeGroup: Group;
  setGroups: (groups: Group[]) => void;
  setActiveGroup: (group: Group) => void;
}

const DEFAULT_GROUPS: Group[] = [
  { value: 'operators', title: 'Team', unreadCount: 0 },
  { value: 'invite', title: 'Invites', unreadCount: 0 },
  { value: 'support', title: 'Players', unreadCount: 0 },
];

const createGroupsSlice: StateCreator<GroupsState> = (set) => ({
  groups: DEFAULT_GROUPS,
  activeGroup: DEFAULT_GROUPS[0],
  setGroups: (groups: Group[]) => set({ groups }),
  setActiveGroup: (group: Group) => set({ activeGroup: group }),
});

export default createGroupsSlice;
export type { GroupsState };
