import {
  IMessage,
  IMessageDTO,
  INewMessageDTO,
  InMessageStatusEnum,
  MessageStatusEnum,
  MessageTypeEnum,
  OutMessageStatusEnum,
} from './type';
import { getFullMonthDay, getShortDate, getShortTime } from 'types/Time';

export class Message<T = MessageStatusEnum> implements IMessage<T> {
  private _id: string;
  private _type: MessageTypeEnum;
  private _status: T;
  private _date: Date;
  private _senderName: string;
  private _photo: string;
  private _text: string;
  private _textLines: string[];
  private _media?: any;

  constructor(
    id: string,
    type: MessageTypeEnum,
    status: T,
    date: Date,
    senderName: string,
    photo: string,
    text: string,
    media?: any
  ) {
    this._id = id;
    this._type = type;
    this._status = status;
    this._date = date;
    this._senderName = senderName;
    this._photo = photo;
    this._text = text;
    this._textLines = this._text.split('\n');
    this._media = media;
  }

  public static createInstance<M = MessageStatusEnum>(data: IMessageDTO<M>) {
    const { id, text, status, datetime, senderName, media } = data;

    return new Message<M>(
      id,
      MessageTypeEnum.text,
      status,
      new Date(datetime),
      senderName,
      '',
      text,
      media
    );
  }

  public static createEmpty(): Message {
    return new Message<InMessageStatusEnum>(
      '',
      MessageTypeEnum.text,
      InMessageStatusEnum.new,
      new Date(),
      '',
      '',
      ''
    );
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get type(): MessageTypeEnum {
    return this._type;
  }

  set type(value: MessageTypeEnum) {
    this._type = value;
  }

  get status(): T {
    return this._status;
  }

  set status(value: T) {
    this._status = value;
  }

  get date(): Date {
    return this._date;
  }

  set date(value: Date) {
    this._date = value;
  }

  get senderName(): string {
    return this._senderName;
  }

  set senderName(value: string) {
    this._senderName = value;
  }

  get photo(): string {
    return this._photo;
  }

  set photo(value: string) {
    this._photo = value;
  }

  get media(): any {
    return this._media;
  }

  set media(value: any) {
    this._media = value;
  }

  get text(): string {
    return this._text;
  }

  get textLines(): string[] {
    return this._textLines;
  }

  set textLines(value: string[]) {
    this._textLines = value;
  }

  set text(value: string) {
    this._text = value;
  }

  public getShortMessage(): string {
    return this._text.length > 50 ? `${this._text.slice(0, 50)}...` : this._text;
  }

  public getShortTime(): string {
    const result = getShortTime(this._date);
    return result;
  }

  public getShortDate(withTodayStr: boolean = true): string {
    const result = getShortDate(this._date, withTodayStr);
    return result;
  }

  public getFullMonthDay(withTodayStr: boolean = true): string {
    const result = getFullMonthDay(this._date, withTodayStr);
    return result;
  }
}

export { InMessageStatusEnum, OutMessageStatusEnum, MessageTypeEnum };

export type { IMessageDTO, INewMessageDTO, IMessage, MessageStatusEnum };
