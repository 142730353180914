// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_userChat__I0pxC {
  width: calc(100% - 350px);
  height: 100%;
  transition: all 0.4s;
  display: inline-flexbox;
  position: relative;
}
@media (max-width: 991.98px) {
  .style_userChat__I0pxC {
    position: fixed;
    top: 64px;
    width: 100%;
    height: calc(100% - 65px);
    transform: translateX(100%);
    z-index: 99;
  }
  .style_userChat__I0pxC.style_userChatHide__Y9wRb {
    visibility: visible;
    transform: translateX(0);
    left: 0;
    width: 100%;
  }
}
.style_userChat__I0pxC .style_userDialog__Dd58t {
  height: 100%;
  display: flex;
  width: 100%;
}
.style_userChat__I0pxC .style_noContent__\\+Afl2 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
@media (max-width: 991.98px) {
  .style_userChat__I0pxC .style_noContent__\\+Afl2 {
    display: none;
  }
}
.style_userChat__I0pxC .style_noContent__\\+Afl2 .style_title__yck-6 {
  font-size: 24px;
  color: #909399;
}`, "",{"version":3,"sources":["webpack://./src/components/Dialog/style.module.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,YAAA;EACA,oBAAA;EACA,uBAAA;EACA,kBAAA;AADF;AAOE;EAXF;IAYI,eAAA;IACA,SAAA;IACA,WAAA;IACA,yBAAA;IACA,2BAAA;IACA,WAAA;EAJF;EAME;IACE,mBAAA;IACA,wBAAA;IACA,OAAA;IACA,WAAA;EAJJ;AACF;AAOE;EACE,YAAA;EACA,aAAA;EACA,WAAA;AALJ;AAQE;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AANJ;AAQI;EARF;IAUI,aAAA;EANJ;AACF;AAQI;EACE,eAAA;EACA,cCpCW;AD8BjB","sourcesContent":["@import '../../variables.scss';\n\n.userChat {\n  width: calc(100% - 350px);\n  height: 100%;\n  transition: all 0.4s;\n  display: inline-flexbox;\n  position: relative;\n\n  // @media (max-width: 1386px) {\n  //   width: 100%;\n  // }\n\n  @media (max-width: 991.98px) {\n    position: fixed;\n    top: 64px;\n    width: 100%;\n    height: calc(100% - 65px);\n    transform: translateX(100%);\n    z-index: 99;\n\n    &.userChatHide {\n      visibility: visible;\n      transform: translateX(0);\n      left: 0;\n      width: 100%;\n    }\n  }\n\n  .userDialog {\n    height: 100%;\n    display: flex;\n    width: 100%;\n  }\n\n  .noContent {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: relative;\n\n    @media (max-width: 991.98px) {\n      // width: 67%;\n      display: none;\n    }\n\n    .title {\n      font-size: 24px;\n      color: $text-color-600;\n    }\n  }\n}\n","$white: #ffffff;\n$black: #000000;\n\n$gray-background: #f5f5f7;\n$gray-border: #dce1e6;\n$gray-box-border: #e9e9eb;\n$gray-active: #eaedef;\n$active-color: #5f8fdf;\n$active-color-op: rgba(95, 143, 223, 0.4);\n// $active-color: #00ff95;\n$sender-message-color: #cce4ff;\n\n$text-color-800: #4a596a;\n$text-color-700: #626d7a;\n$text-color-600: #909399;\n\n$active-color-2: #ec205f;\n\n// $header-color: #add8e6;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userChat": `style_userChat__I0pxC`,
	"userChatHide": `style_userChatHide__Y9wRb`,
	"userDialog": `style_userDialog__Dd58t`,
	"noContent": `style_noContent__+Afl2`,
	"title": `style_title__yck-6`
};
export default ___CSS_LOADER_EXPORT___;
