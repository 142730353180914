import React, { FC, memo } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import classes from './style.module.scss';
import warn_unsave_ from 'assets/images/chats/warn-unsaved-data.png';

interface ICancelChangesDlgProps {
  confirmDlgOpen: boolean;
  confirmDlgProceed: () => void;
  confirmDlgCancel: () => void;
}

const CancelChangesDlg: FC<ICancelChangesDlgProps> = (props) => {
  const { confirmDlgOpen, confirmDlgProceed, confirmDlgCancel } = props;

  return (
    <Modal
      className={classes.modalDialog}
      returnFocusAfterClose={true}
      isOpen={confirmDlgOpen}
      centered
    >
      <ModalHeader className={classes.modalHeader}>
        <span className={classes.dlgHeader}>
          <span className={classes.dlgHeaderIco}>
            <img src={warn_unsave_} alt="not present" />
          </span>
          <span>Are you sure?</span>
        </span>
      </ModalHeader>
      <ModalBody>
        <span>If you quit, all unsaved progress will be lost</span>
      </ModalBody>
      <ModalFooter className={classes.modalFooter}>
        <span className={classes.dlgBtns}>
          <button onClick={confirmDlgProceed} className="button primary">
            Proceed
          </button>
          <button onClick={confirmDlgCancel} className="button default">
            Quit
          </button>
        </span>
      </ModalFooter>
    </Modal>
  );
};

export default memo(CancelChangesDlg);
