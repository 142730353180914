import { createRoot } from 'react-dom/client';
// import { Provider } from 'react-redux';
// import { store } from './redux/store';
import App from './App';
import { CookiesProvider } from 'react-cookie';

const root =createRoot(document.getElementById('root') as HTMLElement)


root.render(
  <CookiesProvider>
      <App />
  </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
