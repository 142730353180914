import React, { MouseEvent, FC, memo, useEffect } from 'react';
import SimpleBar from 'simplebar-react';
import avatar from 'assets/images/chats/avatar.svg';
import classes from './style.module.scss';
import { Ids } from 'types/Ids';
import { getShortLastYear, getShortMonthDay, getShortTime, getShortWeekday } from 'types/Time';
import { getShortMessage } from 'types/Text';
import { IContact } from 'models/Contact';
import NumberFlow from '@number-flow/react';

interface IContactListProps {
  contacts: IContact[];
  currentContact?: IContact;
  type: string;
  setCurrentContact: (cc: IContact) => void;
}

const ContactList: FC<IContactListProps> = (props) => {
  const { contacts, currentContact, type, setCurrentContact } = props;

  // console.log('ContactList ' + JSON.stringify(contacts));

  // useEffect(() => {
  //   console.log('ContactList2 ' + JSON.stringify(contacts));
  // }, [contacts]);

  function sortFuncUnread(first: IContact, second: IContact): boolean {
    return first.last_message_ts <= second.last_message_ts;
  }

  function sortFuncKnown(first: IContact, second: IContact): boolean {
    return first.last_message_ts > second.last_message_ts;
  }

  let sortedContactsUnread: IContact[] = contacts.filter((c) => c.unreadMessagesNum > 0);
  let sortedContactsKnown: IContact[] = contacts.filter((c) => c.unreadMessagesNum < 1);

  function sortContacts(contacts2sort: IContact[], cmpFunc: any) {
    for (let i = 0; i < contacts2sort.length - 1; i++)
      for (let j = i + 1; j < contacts2sort.length; j++)
        if (cmpFunc(contacts2sort[j], contacts2sort[i])) {
          let tmp = contacts2sort[j];
          contacts2sort[j] = contacts2sort[i];
          contacts2sort[i] = tmp;
        }
  }

  sortContacts(sortedContactsUnread, sortFuncUnread);
  sortContacts(sortedContactsKnown, sortFuncKnown);

  let sortedContacts: IContact[] = [...sortedContactsUnread];
  sortedContacts = sortedContacts.concat(sortedContactsKnown);

  const openUserChat = (e: MouseEvent<HTMLLIElement>, ids: Ids) => {
    // console.log('open user chat ' + JSON.stringify(ids));
    let curCon = null;
    if (type === 'support') curCon = getContactById(contacts, ids);
    if (type === 'operators') {
      curCon = contacts.find((item) => item?.operator_id === ids.operator_id);
    }
    if (type === 'invite') {
      curCon = contacts.find((item) => item?.tg_name === ids.tg_name);
      console.log('ETETETETET', curCon);
    }
    if (curCon) setCurrentContact(curCon);
  };

  function getContactById(contacts: IContact[], ids: Ids): IContact {
    const id = ids.telegram_id;
    const id2 = ids.pay_id;

    if (id2 !== null && id2 !== undefined && id2.length > 0) {
      const result2 = contacts.filter((item) => item.pay_id === id2)[0];
      // console.log('contact-by-id-3=' + JSON.stringify(result2));
      // return result2;
    }

    if (id === 0) {
      const result2 = contacts.filter(
        (item) => item.pay_id === ids.pay_id && item.player_id === '' + ids.player_id
      )[0];
      // console.log('contact-by-id-2=' + JSON.stringify(result2));
      return result2;
    }
    const result = contacts.filter((item) => item.telegram_id === id)[0];
    // console.log('contact-by-id=' + JSON.stringify(result));
    return result;
  }

  const hasUnreadMessages = (contact: IContact): boolean => {
    const result =
      contact.unreadMessagesNum !== null &&
      contact.unreadMessagesNum !== undefined &&
      contact.unreadMessagesNum > 0 &&
      contact !== currentContact;
    return result;
  };

  const getUnreadMessagesMark = (contact: IContact): string => {
    // const result = unreadMessagesNum >= 10 ? '9+' : unreadMessagesNum;
    const result = '' + (contact !== currentContact ? contact.unreadMessagesNum : 0);
    return result;
  };

  function getContactShortMessage(contact: IContact): string {
    let result = '';
    if (contact && contact.last_message) result = getShortMessage('' + contact.last_message);
    return result;
  }

  const noLastMsg = (contact: IContact): boolean => {
    return (
      // contact.last_message === null ||
      // contact.last_message === undefined ||
      contact.last_message_ts === null || contact.last_message_ts === undefined
      // contact.last_message.length < 0 + 1
    );
  };

  function getContactShortTime(contact: IContact): string {
    let date;
    if (noLastMsg(contact)) return '';
    date = new Date(1000 * contact.last_message_ts);
    /*
    return getShortDatetime(date);
*/
    const td = new Date();

    const lastYear: boolean = td.getFullYear() - date.getFullYear() >= 1;
    if (lastYear) return getShortLastYear(date);

    const moreWeek: boolean = (td.getTime() - date.getTime()) / 1000 > 3600 * 24 * 7;
    if (moreWeek) return getShortMonthDay(date);

    const time: boolean =
      date.getFullYear() === td.getFullYear() &&
      date.getMonth() === td.getMonth() &&
      date.getDay() === td.getDay();
    if (!time) return getShortWeekday(date);
    return getShortTime(date);
  }

  // const getAttachment = () => {
  //   if (type === 'support') {
  //     return 'Photo';
  //   }
  //   if (type === 'operators') {
  //     return 'Attachment (🖼️ Image/🎬 Video/📁 File)';
  //   }
  // };

  return (
    <SimpleBar style={{ maxHeight: '100%' }} className={classes.chatMessageList}>
      <ul className={classes.chatList} id="chat-list">
        {sortedContacts.map((contact) => {
          const ids: Ids = JSON.parse(JSON.stringify(contact));
          let id, id2, id3, key, dynClass;
          if (type === 'operators') {
            id = ids.operator_id;
            key = id;
            dynClass = contact.operator_id === currentContact?.operator_id ? classes.active : '';
          }
          if (type === 'support') {
            id = ids.telegram_id;
            id2 = ids.player_id;
            id3 = ids.pay_id;
            key = '' + id + '-' + id2 + '-' + id3;
            dynClass = contact.pay_id === currentContact?.pay_id ? classes.active : '';
          }
          if (type === 'invite') {
            key = ids.tg_name;
            dynClass = contact.tg_name === currentContact?.tg_name ? classes.active : '';
          }

          return (
            <li key={key} className={dynClass} onClick={(e) => openUserChat(e, ids)}>
              <div className={classes.userLeftBlock}>
                <div className="user-avatar">
                  <img src={avatar} alt="not present" />
                </div>
              </div>
              <div className={classes.userRightBlock}>
                <div className={classes.topRow}>
                  <div className={classes.contactName}>{contact.name}</div>
                  <div className={classes.time}>{getContactShortTime(contact)}</div>
                </div>
                <div className={classes.bottomRow}>
                  <div className={classes.leftText}>
                    <div className={classes.hallName}>{contact.hall_name}</div>
                    <div className={classes.message}>
                      {contact.last_message === ''
                        ? 'Attachment (🖼️ Image/🎬 Video/📁 File)'
                        : contact.last_message}
                    </div>
                  </div>
                  {hasUnreadMessages(contact) && (
                    <div className={classes.counter}>
                      <div>
                        <NumberFlow
                          format={{ notation: 'compact' }}
                          trend={0}
                          value={Number(
                            hasUnreadMessages(contact) ? getUnreadMessagesMark(contact) : ''
                          )}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </SimpleBar>
  );
};

export default memo(ContactList);
