import React, { FC, memo, MouseEvent } from 'react';
import { Button, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import './style.scss';
import { EDITABLE_CATEGORY, IGalleryImage } from 'types/IHallImagesGet';

interface ISaveImageTemplateDlgProps {
  imageSaveDlgOpen: boolean;
  imgAddTemplateCategoryChange: (event: any) => void;
  imagesFromGallery?: IGalleryImage[];
  getCategoriesOfImages: () => string[];
  cancelAddImageTemplete: () => void;
  confirmAddImageTemplete: () => void;
}

const SaveImageTemplateDlg: FC<ISaveImageTemplateDlgProps> = (props) => {
  const {
    imageSaveDlgOpen,
    imgAddTemplateCategoryChange,
    imagesFromGallery,
    getCategoriesOfImages,
    cancelAddImageTemplete,
    confirmAddImageTemplete,
  } = props;

  return (
    <Modal returnFocusAfterClose={true} isOpen={imageSaveDlgOpen} centered className="upl-img-dlg">
      <ModalHeader>
        <span className="upl-dlg-header">
          <span className="upl-dlg-header-msg">Save image as a template?</span>
        </span>
      </ModalHeader>
      <ModalBody>
        <div className="upl-dlg-img-templ-body">
          <span>For quick access to the image you can save it to the library as a template</span>

          <span className="upl-dlg-img-templ-list">
            <span className="upl-dlg-img-templ-lbl">Category</span>
            <span>
              <Input
                id="imgAddTemplateCategory"
                className="upl-dlg-img-templ-ctrl"
                name="imgAddTemplateCategory"
                type="select"
                disabled
                value={EDITABLE_CATEGORY}
                onChange={imgAddTemplateCategoryChange}
              >
                {imagesFromGallery &&
                  getCategoriesOfImages().map((category, i) => {
                    return <option key={'catgr' + i}>{category}</option>;
                  })}
              </Input>
            </span>
          </span>

          <span className="upl-dlg-btns">
            <span className="dlg-btn-ok">
              <Button
                outline
                color="link"
                className="upl-dlg-btn-cancel-ctrl"
                onClick={cancelAddImageTemplete}
              >
                Skip
              </Button>
            </span>
            <span className="dlg-btn-ok">
              <Button
                color="primary"
                type="button"
                className="upl-dlg-btn-ok-ctrl"
                onClick={confirmAddImageTemplete}
              >
                Save
              </Button>
            </span>
          </span>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default memo(SaveImageTemplateDlg);
