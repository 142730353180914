import React, { FC, memo, useState } from 'react';
import { IDialog } from 'models/Dialog';
import DialogList from './DialogList';
import classes from './style.module.scss';
import PlayerInfo from './PlayerInfo';
import { IDialogController } from 'types/IDialogController';
import { search } from 'types/React';
import { ICangeStatus, IChangePlayerId, IGalleryTransport, ISwitchVip } from 'types/IHallImagesGet';
import {
  BalanceMode,
  IBalanceChanges,
  IBalanceModeChanges,
  IPlayerBalance,
  IWeeklyResetChanges,
} from 'models/Balance';
import { IPlayerInfo, IPlayerInfoChanges } from 'models/Player';
import { IChatSupport } from 'types/Chat';

interface IDialogsProps extends IChatSupport, IGalleryTransport {
  playerInfo: IPlayerInfo;
  playerBalance: IPlayerBalance;
  updatedBalance: number | null;
  updatedBalanceMode: BalanceMode | null;
  loginName: string;
  dialog: IDialog[];
  dlgCtrl: IDialogController;
  currentContactBackClear: () => void;
  savePlayerInfo: (cc: IPlayerInfoChanges) => void;
  changeBalance: (cc: IBalanceChanges) => void;
  changeBalanceMode: (cc: IBalanceModeChanges) => void;
  sendReset: (cc: IWeeklyResetChanges) => void;
  changeStatus: (cc: ICangeStatus) => void;
  switchVip: (cc: ISwitchVip) => void;
  savePlayerId: (cc: IChangePlayerId) => void;
  type: string;
  userInfo: any;
  invitedPlayerInfo: any;
}

const Dialogs: FC<IDialogsProps> = (props) => {
  const {
    currentContact,
    imagesFromGallery,
    messagesFromGallery,
    playerInfo,
    playerBalance,
    updatedBalance,
    updatedBalanceMode,
    loginName,
    savePlayerInfo,
    changeBalance,
    changeBalanceMode,
    sendReset,
    changeStatus,
    switchVip,
    savePlayerId,
    getHallImages,
    getHallMessages,
    createMsgTemplate,
    dialog,
    sendMessage,
    dlgCtrl,
    currentContactBackClear,
    deleteMsgTemplate,
    type,
    userInfo,
    invitedPlayerInfo,
  } = props;

  // const dlgCnt = search('chat-left-hide');
  const [showPlayerInfo, setShowPlayerInfo] = useState<boolean>(false);

  function tooglePlayerInfo() {
    let result: boolean = showPlayerInfo;
    result = !result;
    setShowPlayerInfo(result);
  }

  return (
    <div className={`${classes.userChat} ${currentContact ? classes.userChatHide : ''}`}>
      {currentContact ? (
        <span className={classes.userDialog}>
          <DialogList
            dialog={dialog}
            playerInfo={playerInfo}
            loginName={loginName}
            dlgCtrl={dlgCtrl}
            showPlayerInfo={showPlayerInfo}
            tooglePlayerInfo={tooglePlayerInfo}
            currentContact={currentContact}
            imagesFromGallery={imagesFromGallery}
            messagesFromGallery={messagesFromGallery}
            sendMessage={sendMessage}
            getHallImages={getHallImages}
            createMsgTemplate={createMsgTemplate}
            deleteMsgTemplate={deleteMsgTemplate}
            getHallMessages={getHallMessages}
            currentContactBackClear={currentContactBackClear}
            type={type}
          />
          <PlayerInfo
            playerInfo={playerInfo}
            updatedBalance={updatedBalance}
            updatedBalanceMode={updatedBalanceMode}
            showPlayerInfo={showPlayerInfo}
            tooglePlayerInfo={tooglePlayerInfo}
            savePlayerInfo={savePlayerInfo}
            changeBalance={changeBalance}
            changeBalanceMode={changeBalanceMode}
            sendReset={sendReset}
            changeStatus={changeStatus}
            switchVip={switchVip}
            savePlayerId={savePlayerId}
            playerBalance={playerBalance}
            currentContact={currentContact}
            type={type}
            userInfo={userInfo}
            invitedPlayerInfo={invitedPlayerInfo}
          />
        </span>
      ) : (
        <div className={classes.noContent}>
          <div className={classes.title}>Select a chat to start messaging</div>
        </div>
      )}
    </div>
  );
};

export default memo(Dialogs);
