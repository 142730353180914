import { PeerMessage, TelegramBotMessage } from "models/Message/type";

export interface WebMessage {
    from_user_id: number;
    to_user_id: number;
}

export function isWebMessage(msg: TelegramBotMessage | PeerMessage): boolean {
    const newMsg: WebMessage = JSON.parse(JSON.stringify(msg));
    if (newMsg.from_user_id && newMsg.from_user_id > 0)
        return true;
    if (newMsg.to_user_id && newMsg.to_user_id > 0)
        return true;
    return false;
}
