import { FC, memo } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { Navbar } from 'reactstrap';
import classes from './style.module.scss';
import { AUTH_TAG } from 'types/Chat';
import { PATH_HOME, PATH_HOME_LOGIN } from 'constants/paths';

const UpsidePanel: FC = () => {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies([AUTH_TAG]);

  function onLogoutClick() {
    navigate(PATH_HOME_LOGIN);
    removeCookie('auth-token');
  }

  const checkDomain = () => {
    return window.location.hostname !== 'chat.paymentguru.site' ? true : false;
  };

  return (
    <div className={classes.topMenu}>
      <Navbar className={classes.sideMenuNav} role="tablist">
        <a className={classes.logo} href={PATH_HOME}>
          Payment Guru
        </a>

        {checkDomain() && (
          <button onClick={onLogoutClick} className="button default">
            Logout
          </button>
        )}
      </Navbar>
    </div>
  );
};

export default memo(UpsidePanel);
