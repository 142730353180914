import { StateCreator } from 'zustand';

interface SessionState {
  userId: string | null;
  authToken: string | null;
  isLoggedIn: boolean;
}

interface SessionActions {
  login: (userId: string, authToken: string) => void;
  logout: () => void;
  updateSession: (authToken: string) => void;
}

const createSessionSlice: StateCreator<SessionState & SessionActions> = (set) => ({
  userId: null,
  authToken: null,
  isLoggedIn: false,
  login: (userId: string, authToken: string) => set({ userId, authToken, isLoggedIn: true }),
  logout: () => set({ userId: null, authToken: null, isLoggedIn: false }),
  updateSession: (authToken: string) => set({ authToken }),
});

export default createSessionSlice;
export type { SessionState, SessionActions };
