import React, {
  ReactNode,
  ChangeEvent,
  KeyboardEvent,
  FC,
  FormEvent,
  memo,
  useState,
  useEffect,
  useRef,
} from 'react';
import { Button, Input, Form, Tooltip } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import sendBtn from 'assets/images/chats/Send_button.svg';
import sendImg from 'assets/images/chats/send.svg';
import classes from './style.module.scss';
import Smiles from '../Smiles';
import { IChatSupport, SAVE_CHAT_WORK } from 'types/Chat';
import { MESSAGE_SYMBOLS_LIMIT, prepareMultiChunkMessage } from 'types/Text';
import { IGalleryTransport } from 'types/IHallImagesGet';
import AddImages from '../AddImages';
import { IOutgoing } from 'models/Message/type';
import clearSearchInput from 'assets/images/chats/clear-selected-image.svg';
import { toogleAdd, toogleDel } from 'types/React';
import { IShortcut } from 'models/Shortcut/type';

interface IInputBlockProps extends IChatSupport, IGalleryTransport {
  textInputHeight: number;
  setTextInputHeight: (height: number) => void;
  setTextInput: (current: string) => void;
  setShowCommands: (showOn: boolean) => void;
  shortcutMessage: string;
  shortcuts: IShortcut[];
  setFlagScroll: (flag: boolean) => void;
  type: string;
}

type FileType = 'image' | 'video' | 'other';

const InputBlock: FC<IInputBlockProps> = (props) => {
  const {
    shortcutMessage,
    sendMessage,
    getHallImages,
    createMsgTemplate,
    shortcuts,
    getHallMessages,
    imagesFromGallery,
    messagesFromGallery,
    currentContact,
    textInputHeight,
    setTextInputHeight,
    setTextInput,
    setShowCommands,
    deleteMsgTemplate,
    setFlagScroll,
    type,
  } = props;

  const [textMessage, setTextMessage] = useState<string>('');
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [imagePaths, setImagePaths] = useState<string[]>([]);
  const [showOn, setShowOn] = useState<boolean>(true);

  //file input
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [ifPreviewImage, setIfPreviewImage] = useState<boolean>(false);
  const [previewSrc, setPreviewSrc] = useState<{ type: string; src?: string }>({ type: 'none' });
  const [file, setFile] = useState<any>(null);
  const [fileBase64, setFileBase64] = useState<string>('');
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  // end file input

  const textAreaRef = useRef<Input>(null);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    // console.log(e.target.style.overflowY);
    // if (e.target.scrollHeight <= 149) {
    //   e.target.style.overflowY = 'hidden';
    //   if (e.target.value.length > textMessage.length) {
    //     const scrollHeight = e.target.scrollHeight;
    //     e.target.style.height = scrollHeight + 'px';
    //   } else {
    //     e.target.style.height = '32px';
    //     const scrollHeight = e.target.scrollHeight;
    //     e.target.style.height = scrollHeight + 'px';
    //   }
    // } else {
    //   e.target.style.overflowY = 'scroll';
    // }
    setTextMessage(e.target.value);

    // procTextHeight(e.target.value);
  };

  // function procTextHeight(text: string) {
  //   if (text !== null && text !== undefined) {
  //     setShowCommands(text.startsWith('/'));
  //     setTextInput(text);

  //     const lines = 1 + (text.match(/\n/g) || []).length;
  //     // console.log("Message's lines: " + lines);
  //     if (lines <= 2) setTextInputHeight(50);
  //     else if (lines > 5) {
  //       setTextInputHeight(100);
  //       toogleDel('send-message-ctrl', 'send-message-scroll-without');
  //       toogleAdd('send-message-ctrl', 'send-message-scroll');
  //     } else {
  //       setTextInputHeight(lines * 20);
  //       toogleDel('send-message-ctrl', 'send-message-scroll');
  //       toogleAdd('send-message-ctrl', 'send-message-scroll-without');
  //     }
  //   } else setTextInputHeight(50);
  // }

  function refreshImages() {
    setShowOn(false);
    setTimeout(() => {
      setShowOn(true);
    }, 300);
  }

  function lunchDelivery(textMessage: string) {
    let hasImages = imageFiles !== null && imageFiles !== undefined && imageFiles.length > 0;

    const hasSomething =
      (textMessage !== null && textMessage !== undefined && textMessage.length > 0) ||
      (imagePaths !== null && imagePaths !== undefined && imagePaths.length > 0) ||
      (imageFiles !== null && imageFiles !== undefined && imageFiles.length > 0) ||
      !!file;
    if (!hasSomething) return;

    if (textMessage.startsWith('/')) {
      const searchCmd = textMessage.substring(1);
      const msg = shortcuts.filter((k) => searchCmd === k.command);
      if (msg.length > 0) textMessage = '' + msg[0].description;
      setShowCommands(false);
    }

    setFlagScroll(true);
    let multiParts = prepareMultiChunkMessage(textMessage, hasImages);
    let newMsg: IOutgoing = {
      message: textMessage,
      imgSrc: imagePaths[0],
      imgObj: imageFiles[0],
    };
    multiParts.forEach((msgPart) => {
      if (hasImages) {
        sendMessage(newMsg);
        hasImages = false;
      } else {
        newMsg.message = msgPart;
        sendMessage({ ...newMsg, ...checkFile() });
      }
    });
    if (fileInputRef?.current) fileInputRef.current.value = '';
    setFile(null);
    setIfPreviewImage(false);
    setPreviewSrc({ type: 'none' });
    setFileBase64('');
    setTextMessage('');
    setImageFiles([]);
    setImagePaths([]);
    refreshImages();
    // procTextHeight('');
  }

  useEffect(() => {
    if (!SAVE_CHAT_WORK) {
      setTextMessage('');
      setImageFiles([]);
      setImagePaths([]);
    }
  }, [currentContact]);

  useEffect(() => {
    setTextMessage(shortcutMessage);
  }, [shortcutMessage]);

  useEffect(() => {
    const textarea = document.getElementsByTagName('textarea')[0];
    if (textarea.scrollHeight <= 149) {
      textarea.style.overflowY = 'hidden';
      if (textarea.value.length > textMessage.length) {
        const scrollHeight = textarea.scrollHeight;
        textarea.style.height = scrollHeight + 'px';
      } else {
        textarea.style.height = '32px';
        const scrollHeight = textarea.scrollHeight;
        textarea.style.height = scrollHeight + 'px';
      }
    } else {
      textarea.style.overflowY = 'scroll';
    }
  }, [textMessage]);

  const onAddMessage = (e: FormEvent<HTMLFormElement>, textMessage: string) => {
    e.preventDefault();

    lunchDelivery(textMessage);
  };

  const onkeyDownHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.ctrlKey && !e.shiftKey) {
      e.preventDefault();

      lunchDelivery(textMessage);
    }

    if (e.key === 'Enter' && e.ctrlKey) {
      e.preventDefault();

      setTextMessage(textMessage + '\n');
      e.currentTarget.scrollTo({
        top: e.currentTarget.scrollHeight,
      });
    }
  };

  function onkeyUp() {
    // procTextHeight(textMessage);
  }

  function onFocus() {
    // procTextHeight(textMessage);
  }

  function smileProc(smile: string) {
    const smileyMsg = '' + textMessage + smile;
    setTextMessage(smileyMsg);
  }

  function selectedImageMouseIn() {
    // console.log('in-in');
    if (imagePaths && imagePaths.length > 0 && imagePaths[0]) setImgSelect(!true);
  }

  function selectedImageMouseOut() {
    // console.log('out-out');
    setImgSelect(true);
  }

  function imgSelectClose() {
    console.log('img-select-close');
    setImagePaths([]);
    setImageFiles([]);
  }

  useEffect(() => {
    if (imagePaths.length && ifPreviewImage) {
      setImagePaths([]);
      setImageFiles([]);
    }
  }, [ifPreviewImage]);

  useEffect(() => {
    if (ifPreviewImage && imagePaths.length) {
      setFile(null);
      setIfPreviewImage(false);
      setPreviewSrc({ type: 'none' });
      setFileBase64('');
    }
  }, [imagePaths]);

  const checkFile = () => {
    if (!!fileBase64) {
      return { attachment: { file: fileBase64, fileName: file?.name, fileType: file?.type } };
    }
    return {};
  };

  const getFileType = (file: File): FileType => {
    if (file.type.startsWith('image')) {
      return 'image';
    } else if (file.type.startsWith('video')) {
      return 'video';
    } else if (file.name) {
      const extension = file.name.split('.').pop()?.toLowerCase();
      if (extension && ['png', 'jpg', 'jpeg', 'gif'].includes(extension)) {
        return 'image';
      } else if (extension && ['mp4', 'mov', 'avi'].includes(extension)) {
        return 'video';
      }
    }

    return 'other';
  };

  const uploadFile = () => {
    if (fileInputRef.current) {
      setTooltipOpen(false);
      fileInputRef.current.click();
    }
  };

  const renderPreview = (): ReactNode => {
    switch (previewSrc.type) {
      case 'image':
        return <img src={previewSrc.src} alt="preview" className={classes.previewImage}></img>;
      case 'video':
        return (
          <>
            <div className={classes.wrapperIcon} id="tooltip">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h1.5C5.496 19.5 6 18.996 6 18.375m-3.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-1.5A1.125 1.125 0 0 1 18 18.375M20.625 4.5H3.375m17.25 0c.621 0 1.125.504 1.125 1.125M20.625 4.5h-1.5C18.504 4.5 18 5.004 18 5.625m3.75 0v1.5c0 .621-.504 1.125-1.125 1.125M3.375 4.5c-.621 0-1.125.504-1.125 1.125M3.375 4.5h1.5C5.496 4.5 6 5.004 6 5.625m-3.75 0v1.5c0 .621.504 1.125 1.125 1.125m0 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m1.5-3.75C5.496 8.25 6 7.746 6 7.125v-1.5M4.875 8.25C5.496 8.25 6 8.754 6 9.375v1.5m0-5.25v5.25m0-5.25C6 5.004 6.504 4.5 7.125 4.5h9.75c.621 0 1.125.504 1.125 1.125m1.125 2.625h1.5m-1.5 0A1.125 1.125 0 0 1 18 7.125v-1.5m1.125 2.625c-.621 0-1.125.504-1.125 1.125v1.5m2.625-2.625c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125M18 5.625v5.25M7.125 12h9.75m-9.75 0A1.125 1.125 0 0 1 6 10.875M7.125 12C6.504 12 6 12.504 6 13.125m0-2.25C6 11.496 5.496 12 4.875 12M18 10.875c0 .621-.504 1.125-1.125 1.125M18 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m-12 5.25v-5.25m0 5.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125m-12 0v-1.5c0-.621-.504-1.125-1.125-1.125M18 18.375v-5.25m0 5.25v-1.5c0-.621.504-1.125 1.125-1.125M18 13.125v1.5c0 .621.504 1.125 1.125 1.125M18 13.125c0-.621.504-1.125 1.125-1.125M6 13.125v1.5c0 .621-.504 1.125-1.125 1.125M6 13.125C6 12.504 5.496 12 4.875 12m-1.5 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M19.125 12h1.5m0 0c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h1.5m14.25 0h1.5"
                />
              </svg>
            </div>
            <Tooltip
              isOpen={tooltipOpen}
              placement={'top'}
              toggle={toggle}
              target={'tooltip'}
              className="tooltipWrapper"
            >
              {file.name}
            </Tooltip>
          </>
        );
      case 'other':
        return (
          <>
            <div className={classes.wrapperIcon} id="tooltip">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                />
              </svg>
            </div>
            <Tooltip
              isOpen={tooltipOpen}
              placement={'top'}
              toggle={toggle}
              target={'tooltip'}
              className="tooltipWrapper"
            >
              {file.name}
            </Tooltip>
          </>
        );
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files?.length) {
      const maxSizeInBytes = 5 * 1024 * 1024; // 5MB

      if (files[0].size > maxSizeInBytes) {
        if (fileInputRef?.current) fileInputRef.current.value = '';
        setFile(null);
        setIfPreviewImage(false);
        setPreviewSrc({ type: 'none' });
        setFileBase64('');
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result as string;
        setFileBase64(base64String);
      };

      reader.readAsDataURL(files[0]);

      setFile(files[0]);
      console.log(files[0]);
      const type = getFileType(files[0]);
      const src = type === 'image' ? URL.createObjectURL(files[0]) : '';
      setPreviewSrc({ type, src });
      setIfPreviewImage(true);
      // Perform upload or preview operations here.
      // You will access the files via 'event.target.files'
    }
  };

  const [imgSelect, setImgSelect] = useState<boolean>(true);

  return (
    <Form onSubmit={(e) => onAddMessage(e, textMessage)} className={classes.formBox}>
      <div className={classes.sendMessageBlock}>
        <div className={classes.sendInputBlock}>
          <div className={classes.blockActions}>
            <div className={classes.addEmoji}>
              <Smiles timeout={120} setChosenSmile={smileProc} />
            </div>
            {(type === 'operators' || type === 'support') && (
              <div className={classes.fileWrapper}>
                <div
                  className={`${classes.btnFile} ${ifPreviewImage ? classes.preview : ''}`}
                  onClick={uploadFile}
                >
                  <input
                    type="file"
                    id="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                  {ifPreviewImage ? (
                    renderPreview()
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="icon"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13"
                      />
                    </svg>
                  )}
                </div>
              </div>
            )}
            {type === 'support' && (
              <div className={classes.addFile}>
                <AddImages
                  side={32}
                  idHall={currentContact?.hall_id ?? 0}
                  getHallImages={getHallImages}
                  getHallMessages={getHallMessages}
                  imagesFromGallery={imagesFromGallery}
                  messagesFromGallery={messagesFromGallery}
                  createMsgTemplate={createMsgTemplate}
                  deleteMsgTemplate={deleteMsgTemplate}
                  textMessage={textMessage}
                  setTextMessage={setTextMessage}
                  imageFiles={imageFiles}
                  setImageFiles={setImageFiles}
                  imagePaths={imagePaths}
                  setImagePaths={setImagePaths}
                />
                <div
                  className={classes.imgPreview}
                  onMouseOver={selectedImageMouseIn}
                  onMouseOut={selectedImageMouseOut}
                >
                  {imagePaths.map((file, i) => (
                    <img
                      key={i}
                      className={classes.imgInsert}
                      src={file}
                      alt="not present"
                      style={{ width: '45px', height: '45px' }}
                    />
                  ))}
                  {/* {!imgSelect && (
                    <div className="img-insert-cancel">
                      <label
                        id="images"
                        className="img-insert-cancel-ctrl"
                        onClick={imgSelectClose}
                      >
                        <img src={clearSearchInput} alt="not present"></img>
                      </label>
                    </div>
                  )} */}
                </div>
              </div>
            )}
          </div>
          <div className={classes.borderBox}>
            <Input
              type="textarea"
              ref={textAreaRef}
              value={textMessage}
              rows={1}
              onChange={onChangeHandler}
              className={`${classes.sendMessageCtrl} ${type === 'support' ? classes.typeOne : classes.typeTwo}`}
              placeholder={'"Type a message..."'}
              // className="send-message-ctrl send-message-scroll-without"
              // style={{ resize: 'none', height: textInputHeight }}
              maxLength={MESSAGE_SYMBOLS_LIMIT}
              onKeyDown={onkeyDownHandler}
              onKeyUp={onkeyUp}
              onFocus={onFocus}
            />
            {textMessage.length > MESSAGE_SYMBOLS_LIMIT && (
              <span className="send-message-error">{'Max Symbols'}</span>
            )}
          </div>
        </div>
        <div className={classes.sendButton}>
          <button type="submit">
            <img src={sendImg} alt="not present" />
          </button>
        </div>
      </div>
    </Form>
  );
};

export default memo(InputBlock);
