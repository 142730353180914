// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shortcuts-block {
  padding: 1px;
  border: 1px solid #e9e9eb;
  width: 100%;
  background-color: white;
}

.shortcut-list {
  color: #4A4A4A;
  background-color: white;
}
.shortcut-list li {
  transition: all 0.4s;
  color: #4A4A4A;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.shortcut-list li:hover {
  background-color: #EFF4F5;
}
.shortcut-list li.active {
  background-color: white;
}

.shortcut-text {
  margin-left: 121px;
}

.shortcut-message-list {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Dialog/ShortcutBlock/style.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,yBAAA;EACA,WAAA;EACA,uBAAA;AACJ;;AAIA;EACI,cAAA;EACA,uBAAA;AADJ;AAGI;EACI,oBAAA;EAEA,cAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAFR;AAIQ;EACI,yBAAA;AAFZ;AAKQ;EACI,uBAAA;AAHZ;;AAQA;EACI,kBAAA;AALJ;;AAQA;EACI,YAAA;AALJ","sourcesContent":[".shortcuts-block {\r\n    padding: 1px;\r\n    border: 1px solid #e9e9eb;\r\n    width: 100%;\r\n    background-color: white;\r\n\r\n}\r\n\r\n\r\n.shortcut-list {\r\n    color: #4A4A4A;\r\n    background-color: white;\r\n\r\n    li {\r\n        transition: all 0.4s;\r\n\r\n        color: #4A4A4A;\r\n        height: 40px;\r\n        display: flex;\r\n        align-items: center;\r\n        justify-content: space-between;\r\n\r\n        &:hover {\r\n            background-color: #EFF4F5;\r\n        }\r\n\r\n        &.active {\r\n            background-color: white;\r\n        }\r\n    }\r\n}\r\n\r\n.shortcut-text {\r\n    margin-left: 121px;\r\n}\r\n\r\n.shortcut-message-list {\r\n    height: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
