import React, { FC, memo, useState } from 'react';
import { IDialog } from 'models/Dialog';
import classes from '../message.module.scss';
import MultyMsg from 'components/Dialog/MultyMsg';
import { getShortTime } from 'types/Time';
import { Button } from 'reactstrap';

interface IButtonsRowProps {
  dialogItem: IDialog;
  prevDialogItem: IDialog;
  nextDialogItem: IDialog;
  keyNum: string;
}

const ButtonsRow: FC<IButtonsRowProps> = (props) => {
  const { dialogItem, nextDialogItem, keyNum } = props;
  const { message } = dialogItem;
  const { text } = message;

  const [paid, setPaid] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);

  function onProcessClicked() {
    console.log('Process clicked');
    setProcessing(true);
  }

  function onCancelProcessingClicked() {
    console.log('cancel processing clicked');
    setProcessing(false);
  }

  function onPayProcessingClicked() {
    console.log('pay processing clicked');
    setPaid(true);
  }

  return (
    <li key={keyNum} style={dialogItem.isNextBlock(nextDialogItem) ? { marginBottom: '8px' } : {}}>
      <div className={classes.conversationList}>
        <div className={classes.ctextWrapContent}>
          <p className={classes.chatParty}>
            <span>
              <b>
                <strong>{message.senderName}</strong>
              </b>
            </span>
          </p>
          <p>
            <MultyMsg msg={text} />
            {paid ? (
              <>&nbsp;</>
            ) : (
              <>
                {processing ? (
                  <span className={classes.btnsCtrl}>
                    <button onClick={onCancelProcessingClicked} className="button default">
                      Cancel
                    </button>
                    <span style={{ width: '20px' }}></span>
                    <button onClick={onPayProcessingClicked} className="button primary">
                      Pay
                    </button>
                  </span>
                ) : (
                  <span className={classes.btnsCtrl}>
                    <button onClick={onProcessClicked} className="button primary">
                      Process
                    </button>
                  </span>
                )}
              </>
            )}
          </p>
          <p className={classes.chatTime}>
            <span>{getShortTime(message.date)}</span>
          </p>
        </div>
      </div>
    </li>
  );
};

export default memo(ButtonsRow);
