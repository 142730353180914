export enum Blacklisted {
  OK = 0,
  BLACKLISTED = 1,
  TEMPSE = 2,
  NO = 3,
}

export function isBlacklisted(value: Blacklisted | null): boolean {
  if (value !== null && value !== undefined && value === Blacklisted.BLACKLISTED) return true;
  return false;
}

export function notBlacklisted(value: Blacklisted | null): boolean {
  const result = isBlacklisted(value);
  return !result;
}

export interface IPlayerInfo {
  id: number;
  payId: string;
  ref: number;
  playerid: number;
  realName: string;
  tg_name: string | null;
  description: string | null;
  status: Blacklisted | null;
  label: string | null;
  weekLimit: number | null;
  creditLimit: number | null;
  payAmount: number | null;
  remainTRX: number | null;
  balance: number | null;
  balanceMode: number | null;
  balanceLastWeek: number | null;
  balanceLastWeekBefore: number | null;
  weeklyReset: boolean;
  debt: number | null;
  adsMessages: boolean;
  creditMessages: boolean;
  vip: boolean;
  created_at: Date;
  nextWithdrawal: Date;
  lastWithdrawal: Date;
}

export function createEmptyPlayerInfo(): IPlayerInfo {
  return {
    id: 0,
    payId: '',
    ref: 0,
    playerid: 0,
    realName: '',
    tg_name: '',
    description: '',
    status: Blacklisted.OK,
    label: '',
    weekLimit: 0,
    creditLimit: 0,
    payAmount: 0,
    remainTRX: 0,
    balance: 0,
    balanceMode: 1,
    balanceLastWeek: 0,
    balanceLastWeekBefore: 0,
    weeklyReset: false,
    debt: 0,
    adsMessages: false,
    creditMessages: false,
    vip: false,
    created_at: new Date(),
    nextWithdrawal: new Date(),
    lastWithdrawal: new Date(),
  };
}

export interface IPlayerInfoChanges {
  description: string | null;
  weekLimit: number | null;
  creditLimit: number | null;
  payAmount: number | null;
  adsMessages: boolean;
  creditMessages: boolean;
}
