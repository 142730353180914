import React, { FC, memo, useEffect, useState } from 'react';

import { Modal, ModalBody, ModalFooter, ModalHeader, Input } from 'reactstrap';
import { useCookies } from 'react-cookie';
import { AUTH_TAG } from 'types/Chat';
import SearchBlock from './SearchBlock';
import ContactList from './ContactList';
import classes from './style.module.scss';
import { IContact } from 'models/Contact';
import NumberFlow from '@number-flow/react';
import useAppStore from 'store';

type TypeChat = 'operators' | 'support' | 'invite';

interface error {
  value: boolean;
  message: string;
}

interface IContactsProps {
  currentContact?: IContact;
  contacts: IContact[];
  type: string;
  setCurrentContact: (cc: IContact) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
  inviteUser: (data: { tgName: string; realName: string; hall: number }) => void;
  halls: any;
}

const Contacts: FC<IContactsProps> = (props) => {
  const {
    contacts,
    currentContact,
    type,
    setCurrentContact,
    loading,
    setLoading,
    inviteUser,
    halls,
  } = props;

  const [currentFilteredContacts, setCurrentFilteredContacts] = useState<IContact[]>([]);

  const [cookies, setCookie, removeCookie] = useCookies([AUTH_TAG]);

  // modal invite user
  const [showModal, setShowModal] = useState<boolean>(false);
  const [tgName, setTgName] = useState<string>('');
  const [realName, setRealName] = useState<string>('');
  const [hall, setHall] = useState<string>('');
  const [errors, setErrors] = useState<error[]>([]);

  const groups = useAppStore((state) => state.groups);
  const activeGroup = useAppStore((state) => state.activeGroup);
  const setActiveGroup = useAppStore((state) => state.setActiveGroup);

  useEffect(() => {
    if (
      currentContact !== null &&
      currentContact !== undefined
      // currentContact !== null &&
      // currentContact !== undefined &&
      // currentContact.pay_id !== null &&
      // currentContact.pay_id !== undefined &&
      // currentContact.pay_id.length > 0
    ) {
      const contact: IContact = currentContact;
      contact.unreadMessagesNum = 0;

      // toogleAdd('chat-left', 'chat-left-hide');
      // toogleAdd('user-chat', 'user-chat-hide');
    }
  }, [currentContact]);

  const [rememberSearch, setRememberSearch] = useState<string>('');

  useEffect(() => {
    processSearch(rememberSearch);
  }, [contacts, rememberSearch]);

  const processSearch = (value: string) => {
    if (value.length < 1) {
      setCurrentFilteredContacts(contacts);
      return;
    }
    const filteredContacts = contacts.filter((item) => {
      const { name, id, description } = item;
      const contactInfo = [`${name}`, `${id}`];
      if (description) {
        contactInfo.push(description);
      }
      return contactInfo.some((field) => field.toLowerCase().includes(value));
    });
    setCurrentFilteredContacts(filteredContacts);
  };

  const onSearchHandler = (param: string) => {
    let value = param;
    console.log('search handler: <' + value + '>');
    if (param === null || param === undefined) param = '';
    setRememberSearch(value);
  };

  const onClearSearchHandler = () => {
    console.log('clear search ');
    setRememberSearch('');
  };

  const validateInvite = () => {
    setErrors([]);
    const aErrors: any[] = [];
    aErrors.push(
      !tgName.length
        ? { value: true, message: 'Field is required' }
        : tgName[0] !== '@'
          ? { value: true, message: 'The first symbol must be @' }
          : tgName.match(/[@A-Za-z0-9_]/g) === null
            ? { value: true, message: 'You can use @,a-z,A-Z,0-9 and underscores' }
            : ''
    );
    aErrors.push(
      !realName.length
        ? { value: true, message: 'Field is required' }
        : realName.match(/[A-Za-z0-9_-]/g) === null
          ? { value: true, message: 'You can use a-z,A-Z,0-9, hyphen and underscores' }
          : ''
    );
    aErrors.push(!hall ? { value: true, message: 'Field is required' } : '');
    setErrors(aErrors);
    return !aErrors.find((item: any) => item.value === true);
  };

  const closeModal = () => {
    setShowModal(false);
    setErrors([]);
    setTgName('');
    setHall('');
    setRealName('');
  };

  const fetchInviteUser = () => {
    console.log('FETCH INVITE USER', tgName, realName);
    if (validateInvite()) {
      const idHall = +hall;
      inviteUser({ tgName, realName, hall: idHall });
      closeModal();
    } else {
      console.log('error');
    }
  };

  useEffect(() => {
    if (halls.length) setHall(`${halls[0]?.id}`);
  }, [halls]);

  const unreadMessage = (count: number) => {
    return count > 100 ? '99+' : count;
  };

  const showBtnInvite = () => {
    return !['hall', 'merchant'].includes(cookies['auth-token']?.role);
  };

  const closeBtn = (
    <button className={classes.btnClose} onClick={closeModal}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
      </svg>
    </button>
  );

  return (
    <div className={`${classes.chatLeft} ${currentContact ? classes.chatLeftHide : ''}`}>
      <Modal
        returnFocusAfterClose={true}
        isOpen={showModal}
        centered
        className={classes.modalDialog}
      >
        <ModalHeader className={classes.modalHeader} close={closeBtn}>
          <span>
            <span>Invite user</span>
          </span>
        </ModalHeader>
        <ModalBody className={classes.modalBody}>
          <div className={classes.inputBox}>
            <div className={classes.headerInput}>
              <span>TG Name</span>
            </div>
            <Input
              type="text"
              value={tgName}
              onChange={(e) => setTgName(e.target.value)}
              placeholder="@TG Name"
              invalid={errors[0]?.value}
            />
            {errors[0]?.value && <div className={classes.error}>{errors[0]?.message}</div>}
          </div>
          <div className={classes.inputBox}>
            <div className={classes.headerInput}>
              <span>Real Name</span>
            </div>
            <Input
              type="text"
              value={realName}
              onChange={(e) => setRealName(e.target.value)}
              placeholder="Real name"
              invalid={errors[1]?.value}
            />
            {errors[1]?.value && <div className={classes.error}>{errors[1]?.message}</div>}
          </div>
          <div className={classes.inputBox}>
            <div className={classes.headerInput}>
              <span>Hall</span>
            </div>
            <Input
              id="hall"
              type="select"
              name="hall"
              value={hall}
              onChange={(event) => setHall(event.target.value)}
              invalid={errors[2]?.value}
            >
              {halls.length > 0 ? (
                halls.map((item: any, index: number) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))
              ) : (
                <option disabled value="">
                  No values
                </option>
              )}
            </Input>
            {errors[2]?.value && <div className={classes.error}>{errors[1]?.message}</div>}
          </div>
        </ModalBody>
        <ModalFooter className={classes.modalFooter}>
          <button className="button primary" onClick={() => fetchInviteUser()}>
            Add
          </button>
        </ModalFooter>
      </Modal>
      {showBtnInvite() && (
        <button className={classes.btnInvite} onClick={() => setShowModal(true)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z"
            />
          </svg>
        </button>
      )}
      <div className={classes.headerContacts}>
        <div className={classes.titleHeader}>My chats</div>
        <div className={classes.wrapperTabs}>
          {groups.map((group) => (
            <div
              className={`${classes.tabItem} ${activeGroup.value === group.value ? classes.active : ''}`}
              key={group.value}
              onClick={() => {
                setLoading(true);
                // setType(group.value as TypeChat);
                setActiveGroup(group);
              }}
            >
              <span>{group.title}</span>
              {group.unreadCount > 0 && (
                <div className={classes.unreadCount}>
                  <NumberFlow
                    format={{ notation: 'compact' }}
                    trend={0}
                    value={Number(unreadMessage(group.unreadCount))}
                  />
                </div>
              )}
            </div>
          ))}
          {/* {typesChats.map((tab) => (
            <div
              className={`${classes.tabItem} ${type === tab.value ? classes.active : ''}`}
              key={tab.value}
              onClick={() => {
                setLoading(true);
                setType(tab.value as TypeChat);
              }}
            >
              {tab.title}
            </div>
          ))} */}
        </div>
      </div>

      <SearchBlock
        onSearch={onSearchHandler}
        onClear={onClearSearchHandler}
        placeholder={'Search messages or users'}
      />

      {loading ? (
        <div className={classes.boxLoader}>
          <span className={classes.loader}></span>
        </div>
      ) : (
        // <div>Contacts</div>
        <ContactList
          contacts={currentFilteredContacts}
          currentContact={currentContact}
          type={type}
          setCurrentContact={setCurrentContact}
        />
      )}
    </div>
  );
};

export default memo(Contacts);
