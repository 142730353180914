import React, { FC, memo, useEffect, useState } from 'react';
import { IDialog } from 'models/Dialog';
import classes from './style.module.scss';
import TitleBlock from '../TitleBlock';
import InputBlock from '../InputBlock';
import { IDialogController } from 'types/IDialogController';
import { toogleMultyProc, toogleProc } from 'types/React';
import { IGalleryTransport } from 'types/IHallImagesGet';
import { IPlayerInfo } from 'models/Player';
import ShortcutBlock from '../ShortcutBlock';
import { IShortcut } from 'models/Shortcut/type';
import Rows from './Rows';
import { IChatSupport } from 'types/Chat';

interface IDialogListProps extends IChatSupport, IGalleryTransport {
  dialog: IDialog[];
  playerInfo: IPlayerInfo;
  loginName: string;
  showPlayerInfo: boolean;
  dlgCtrl: IDialogController;
  tooglePlayerInfo: () => void;
  currentContactBackClear: () => void;
  type: string;
}

const DialogList: FC<IDialogListProps> = (props) => {
  const {
    dialog,
    loginName,
    showPlayerInfo,
    imagesFromGallery,
    messagesFromGallery,
    currentContact,
    sendMessage,
    getHallImages,
    createMsgTemplate,
    deleteMsgTemplate,
    getHallMessages,
    tooglePlayerInfo,
    dlgCtrl,
    currentContactBackClear,
    type,
  } = props;

  const [textInputHeight, setTextInputHeight] = useState<number>(50);
  const [textInput, setTextInput] = useState<string>('');
  const [showCommands, setShowCommands] = useState<boolean>(false);
  const [commandsHeight, setCommandsHeight] = useState<number>(150);
  const [shortcuts, setShortcuts] = useState<IShortcut[]>([]);
  const [shortcutMessage, setShortcutMessage] = useState<string>('');
  const [flagScroll, setFlagScroll] = useState<boolean>(false);

  useEffect(() => {
    let arr: IShortcut[] = [];
    if (messagesFromGallery)
      messagesFromGallery.map((msg) => {
        arr.push({
          name: msg.title,
          description: msg.body,
          command: msg.shortcat,
        });
      });

    setShortcuts(arr);
  }, [messagesFromGallery]);

  useEffect(() => {
    // scrolltoBottom();
    setShowCommands(false);
  }, [dialog]);

  useEffect(() => {
    setCommandsHeight(showCommands ? 150 : 0);
  }, [showCommands]);

  useEffect(() => {
    // Change show PLAYER INFO
    // toogleProc('main-content', 'dialog-content-fullwidth', showPlayerInfo);
    // toogleProc('user-sending', 'dialog-content-half', showPlayerInfo);
    // toogleProc('player-info', 'player-info-unhi', showPlayerInfo);
    toogleMultyProc('dtc', 'dtc-hide', showPlayerInfo);
  }, [showPlayerInfo]);

  function mobileClose() {
    toogleMultyProc('dtc', 'dtc-hide', showPlayerInfo);
  }

  function setInputTextMessage(msg: string) {
    setShortcutMessage(msg);
  }

  const currentContactName = currentContact?.name ?? '';

  return (
    <div className={`${classes.mainContent} ${showPlayerInfo ? classes.openPlayerInfo : ''}`}>
      <TitleBlock
        currentContact={currentContact}
        showPlayerInfo={showPlayerInfo}
        tooglePlayerInfo={tooglePlayerInfo}
        mobileClose={mobileClose}
        currentContactBackClear={currentContactBackClear}
        type={type}
      />

      <Rows
        dialog={dialog}
        loginName={loginName}
        currentContactName={currentContactName}
        dlgCtrl={dlgCtrl}
        flagScroll={flagScroll}
        setFlagScroll={setFlagScroll}
      />

      {showCommands && (
        <ShortcutBlock
          shortcuts={shortcuts}
          setInputTextMessage={setInputTextMessage}
          currentContact={currentContact}
          commandsHeight={commandsHeight}
          setCommandsHeight={setCommandsHeight}
          showCommands={showCommands}
          inputTextMessage={textInput}
          setShowCommands={setShowCommands}
        />
      )}

      <InputBlock
        shortcutMessage={shortcutMessage}
        sendMessage={sendMessage}
        currentContact={currentContact}
        shortcuts={shortcuts}
        imagesFromGallery={imagesFromGallery}
        messagesFromGallery={messagesFromGallery}
        getHallImages={getHallImages}
        getHallMessages={getHallMessages}
        createMsgTemplate={createMsgTemplate}
        deleteMsgTemplate={deleteMsgTemplate}
        textInputHeight={textInputHeight}
        setTextInputHeight={setTextInputHeight}
        setTextInput={setTextInput}
        setShowCommands={setShowCommands}
        setFlagScroll={setFlagScroll}
        type={type}
      />
    </div>
  );
};

export default memo(DialogList);
