import { StateCreator } from 'zustand';

interface Message {
  id: string;
  dialogId: string;
  sender: string;
  text: string;
}

interface MessagesState {
  messages: { [dialogId: string]: Message[] };
}

interface MessagesActions {
  addMessage: (message: Message) => void;
  loadMessages: (dialogId: string, messages: Message[]) => void;
}

const createMessagesSlice: StateCreator<MessagesState & MessagesActions> = (set) => ({
  messages: {},
  addMessage: (message: Message) =>
    set((state) => ({
      messages: {
        ...state.messages,
        [message.dialogId]: [...(state.messages[message.dialogId] || []), message],
      },
    })),
  loadMessages: (dialogId: string, messages: Message[]) =>
    set((state) => ({
      messages: {
        ...state.messages,
        [dialogId]: messages,
      },
    })),
});

export default createMessagesSlice;
export type { MessagesState, MessagesActions };
