import { BalanceMode, BalanceModeLabel } from 'models/Balance';

const DELIM = ' ';

export function moneySpacing(value: any): string {
  let result = '';
  if (value === null || value === undefined) {
    result = '0';
    return result;
  }
  const v2: number = value;
  let sum = ('' + v2).indexOf('.') > -1 ? '' + v2.toFixed(2) : '' + v2;
  const d = sum.length % 3;
  result += sum.substring(0, d);
  sum = sum.substring(d);
  while (sum.length > 0) {
    result += DELIM + sum.substring(0, 3);
    sum = sum.substring(3);
  }
  if (result.startsWith(' ')) result = result.substring(1);
  if (result.indexOf(' .') > -1) result = result.replace(' .', '.');
  return result;
}

export function getCurrentBalance(
  playerInfoBalance: number | null,
  updatedBalance: number | null
): number {
  if (updatedBalance) return updatedBalance;
  if (playerInfoBalance) return playerInfoBalance;
  return 0;
}

export function getCurrentBalanceMode(
  playerInfoBalanceMode: BalanceMode | null,
  updatedBalanceMode: BalanceMode | null
): BalanceMode {
  if (updatedBalanceMode) return updatedBalanceMode;
  if (playerInfoBalanceMode) return playerInfoBalanceMode;
  return BalanceMode.MODE_BALANCE;
}

export function getBalanceMode(mode: any): string {
  let result = '';
  if (mode !== null && mode !== undefined)
    switch (mode) {
      case BalanceMode.MODE_CREDIT:
        result = BalanceModeLabel.CREDIT;
        break;
      case BalanceMode.MODE_BALANCE:
        result = BalanceModeLabel.BALANCE;
        break;
      default:
        result = '';
    }
  return result;
}

export const MONEY_LIMIT = 7;

export function yesNoAnser(value: any): string {
  if (value !== null && value !== undefined && value === 1) return 'Yes';
  if (value !== null && value !== undefined && value === true) return 'Yes';
  return 'No';
}

export function textSpacing(value: any): string {
  if (value !== null && value !== undefined && ('' + value).length > 0) return value;
  return ' ';
}

export function presents(value: any): boolean {
  if (value !== null && value !== undefined && (value === true || value > 0)) return true;
  return false;
}

export function presentsNot(value: any): boolean {
  const result = presents(value);
  return !result;
}
