// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_chatDayTitle__PumDT {
  position: relative;
  margin-bottom: 32px;
  margin-top: 32px;
  display: flex;
  justify-content: center;
  width: 100%;
}
.style_chatDayTitle__PumDT .style_title__Hny-n {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: #626d7a;
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/Dialog/DialogList/Rows/DateRow/style.module.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,WAAA;AADF;AAGE;EACE,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,cCDa;EDEb,UAAA;AADJ","sourcesContent":["@import '../../../../../variables.scss';\n\n.chatDayTitle {\n  position: relative;\n  margin-bottom: 32px;\n  margin-top: 32px;\n  display: flex;\n  justify-content: center;\n  width: 100%;\n\n  .title {\n    font-size: 15px;\n    line-height: 20px;\n    font-weight: 400;\n    color: $text-color-700;\n    z-index: 1;\n  }\n}\n","$white: #ffffff;\n$black: #000000;\n\n$gray-background: #f5f5f7;\n$gray-border: #dce1e6;\n$gray-box-border: #e9e9eb;\n$gray-active: #eaedef;\n$active-color: #5f8fdf;\n$active-color-op: rgba(95, 143, 223, 0.4);\n// $active-color: #00ff95;\n$sender-message-color: #cce4ff;\n\n$text-color-800: #4a596a;\n$text-color-700: #626d7a;\n$text-color-600: #909399;\n\n$active-color-2: #ec205f;\n\n// $header-color: #add8e6;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatDayTitle": `style_chatDayTitle__PumDT`,
	"title": `style_title__Hny-n`
};
export default ___CSS_LOADER_EXPORT___;
