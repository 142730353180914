import axios from 'axios';
import { PATH_HOME_LOGIN } from 'constants/paths';
import { AuthInfo, IAuthInfo } from './IAuthInfo';
import { IS_VALID_TOKEN_URL, LOGIN_CS_URL, LOGIN_URL } from './Chat';

export const fetchLogin = async (login: string, password: string) => {
  const data = {
    login: `${login}`,
    password: `${password}`,
  };
  const apiPath = LOGIN_URL;

  const response = await axios.post(apiPath, data);
  return response.data;
};

export const fetchAutologin = async (token: string) => {
  const config = {
    headers: {
      'Access-Control-Allow-Credentials': 'true',
    },
    withCredentials: true,
  };
  const apiPath = LOGIN_CS_URL;

  const data = { token };

  const response = await axios.post(apiPath, data, config);
  return response.data;
};

export class CheckTokenWithNoContactsProvider {
  constructor() {}

  async checkToken(token: string, ready: any) {
    axios
      .post(IS_VALID_TOKEN_URL, {
        token: '' + token,
      })
      .then((response) => {
        const rd = response.data;
        if (ready) ready(rd);
      })
      .catch((err) => {
        console.error(err);
      });
  }
}
