import { GuruchatAuth, GuruchatContact, GuruchatGroup, GuruchatSocket } from './soket';
import { Chat } from './Chat';
import { IPlayerInfoChanges } from 'models/Player';
import { IBalanceChanges } from 'models/Balance';
import {
  ICangeStatus,
  IChangePlayerId,
  IHallImagesGet,
  IHallMessagesGet,
  ITemplateMessageCreate,
  ITemplateMessageDelete,
} from './IHallImagesGet';
import { IContact } from 'models/Contact';
import {
  GetContacts,
  GetDialog,
  GetDialogPage,
  GetPlayerInfo,
  IOutgoing,
  ImageMessage,
  ImagesMessage,
  UpdatePlayerInfo,
  GetGroupDialog,
  GetGroupDialogPage,
} from 'models/Message/type';

export function cmdGetPlayerInfo(
  contact: GuruchatContact,
  socket: GuruchatSocket,
  clearAuthOnError: any,
  recoverySteps: any
) {
  if (contact) {
    if (socket) {
      try {
        const env: GetPlayerInfo = {
          payId: contact.pay_id,
          type: Chat.GET_PLAYER_INFO,
        };
        socket.send(JSON.stringify(env));
      } catch (e) {
        console.error('message out error: ', e);
        socket = null;
        clearAuthOnError();
      }
    }
    if (!socket) recoverySteps();
  }
}

export function cmdGetUserInfo(
  contact: GuruchatContact,
  socket: GuruchatSocket,
  clearAuthOnError: any,
  recoverySteps: any
) {
  if (contact) {
    if (socket) {
      try {
        const env = {
          operatorId: contact?.operator_id,
          type: Chat.GET_USER_INFO,
        };
        socket.send(JSON.stringify(env));
      } catch (e) {
        console.error('message out error: ', e);
        socket = null;
        clearAuthOnError();
      }
    }
    if (!socket) recoverySteps();
  }
}

export function cmdUpdatePlayerInfo(
  contact: GuruchatContact,
  socket: GuruchatSocket,
  cc: IPlayerInfoChanges,
  clearAuthOnError: any,
  recoverySteps: any
) {
  if (contact) {
    if (socket)
      try {
        const env: UpdatePlayerInfo = {
          payId: contact.pay_id,
          type: Chat.SET_PLAYER_INFO,
          weekLimit: cc.weekLimit,
          description: cc.description,
          creditLimit: cc.creditLimit,
          payAmount: cc.payAmount,
          adsMessages: cc.adsMessages,
          creditMessages: cc.creditMessages,
        };
        socket.send(JSON.stringify(env));
      } catch (e) {
        console.error('message out error: ', e);
        socket = null;
        clearAuthOnError();
      }
    if (!socket) recoverySteps();
  }
}

export function cmdUpdateMoneyBalance(
  contact: GuruchatContact,
  socket: GuruchatSocket,
  authInfo: GuruchatAuth,
  cc: IBalanceChanges,
  clearAuthOnError: any,
  recoverySteps: any
) {
  if (contact) {
    if (socket && authInfo)
      try {
        let env: any = cc;
        env.operator_id = authInfo.operator_id;
        env.operator_name = authInfo.operator_name;
        socket.send(JSON.stringify(env));
      } catch (e) {
        console.error('message out error: ', e);
        socket = null;
        clearAuthOnError();
      }
    if (!socket) recoverySteps();
  }
}

export function cmdChangeStatusSend(
  contact: GuruchatContact,
  socket: GuruchatSocket,
  authInfo: GuruchatAuth,
  cc: ICangeStatus,
  clearAuthOnError: any,
  recoverySteps: any
) {
  if (contact) {
    if (socket && authInfo)
      try {
        let env: any = cc;
        env.operator_id = authInfo.operator_id;
        env.operator_name = authInfo.operator_name;
        env.contact_id = contact?.id;
        env.payId = contact?.pay_id;
        socket.send(JSON.stringify(env));
      } catch (e) {
        console.error('message out error: ', e);
        socket = null;
        clearAuthOnError();
      }
    if (!socket) recoverySteps();
  }
}

export function cmdSwitchVipSend(
  contact: GuruchatContact,
  socket: GuruchatSocket,
  authInfo: GuruchatAuth,

  cc: ICangeStatus,
  clearAuthOnError: any,
  recoverySteps: any
) {
  if (contact) {
    if (socket && authInfo)
      try {
        let env: any = cc;
        env.operator_id = authInfo.operator_id;
        env.operator_name = authInfo.operator_name;
        env.contact_id = contact?.id;
        env.payId = contact?.pay_id;
        socket.send(JSON.stringify(env));
      } catch (e) {
        console.error('message out error: ', e);
        socket = null;
        clearAuthOnError();
      }
    if (!socket) recoverySteps();
  }
}

export function cmdSavePlayerId(
  contact: GuruchatContact,
  socket: GuruchatSocket,
  authInfo: GuruchatAuth,
  cc: IChangePlayerId,
  clearAuthOnError: any,
  recoverySteps: any
) {
  if (contact) {
    if (socket && authInfo)
      try {
        let env: any = cc;
        env.operator_id = authInfo.operator_id;
        env.operator_name = authInfo.operator_name;
        env.contact_id = contact?.id;
        env.payId = contact?.pay_id;
        socket.send(JSON.stringify(env));
      } catch (e) {
        console.error('message out error: ', e);
        socket = null;
        clearAuthOnError();
      }
    if (!socket) recoverySteps();
  }
}

export function cmdDeleteMsgTemplateSend(
  contact: GuruchatContact,
  socket: GuruchatSocket,
  authInfo: GuruchatAuth,
  cc: ITemplateMessageDelete,
  clearAuthOnError: any,
  recoverySteps: any
) {
  if (contact) {
    if (socket && authInfo)
      try {
        let env: any = cc;
        env.operator_id = authInfo.operator_id;
        env.operator_name = authInfo.operator_name;
        env.contact_id = contact?.id;
        env.payId = contact?.pay_id;
        socket.send(JSON.stringify(env));
      } catch (e) {
        console.error('message out error: ', e);
        socket = null;
        clearAuthOnError();
      }
    if (!socket) recoverySteps();
  }
}

export function cmdCreateMsgTemplateSend(
  contact: GuruchatContact,
  socket: GuruchatSocket,
  authInfo: GuruchatAuth,
  cc: ITemplateMessageCreate,
  clearAuthOnError: any,
  recoverySteps: any
) {
  if (contact) {
    if (socket && authInfo)
      try {
        let env: any = cc;
        env.operator_id = authInfo.operator_id;
        env.operator_name = authInfo.operator_name;
        env.contact_id = contact?.id;
        env.payId = contact?.pay_id;
        socket.send(JSON.stringify(env));
      } catch (e) {
        console.error('message out error: ', e);
        socket = null;
        clearAuthOnError();
      }
    if (!socket) recoverySteps();
  }
}

export function cmdUpdateHallMessagesSend(
  contact: GuruchatContact,
  socket: GuruchatSocket,
  authInfo: GuruchatAuth,
  cc: IHallMessagesGet,
  clearAuthOnError: any,
  recoverySteps: any
) {
  if (contact) {
    if (socket && authInfo)
      try {
        let env: any = cc;
        env.operator_id = authInfo.operator_id;
        env.operator_name = authInfo.operator_name;
        env.contact_id = contact?.id;
        env.payId = contact?.pay_id;
        socket.send(JSON.stringify(env));
      } catch (e) {
        console.error('message out error: ', e);
        socket = null;
        clearAuthOnError();
      }
    if (!socket) recoverySteps();
  }
}

export function cmdUpdateHallImagesSend(
  contact: GuruchatContact,
  socket: GuruchatSocket,
  authInfo: GuruchatAuth,
  cc: IHallImagesGet,
  clearAuthOnError: any,
  recoverySteps: any
) {
  if (contact) {
    if (socket && authInfo)
      try {
        let env: any = cc;
        env.operator_id = authInfo.operator_id;
        env.operator_name = authInfo.operator_name;
        socket.send(JSON.stringify(env));
      } catch (e) {
        console.error('message out error: ', e);
        socket = null;
        clearAuthOnError();
      }
    if (!socket) recoverySteps();
  }
}

export function cmdGetDialog(
  contact: GuruchatContact,
  socket: GuruchatSocket,
  authInfo: GuruchatAuth,
  clearAuthOnError: any,
  recoverySteps: any
) {
  if (contact) {
    if (socket && authInfo)
      try {
        const env: GetDialog = {
          operator_id: authInfo?.operator_id,
          contact_id: contact.telegram_id,
          type: Chat.GET_DIALOG,
        };
        socket.send(JSON.stringify(env));
      } catch (e) {
        console.error('message out error: ', e);
        socket = null;
        clearAuthOnError();
      }
    if (!socket) recoverySteps();
  }
}

export function cmdGetDialogPage(
  pageNumber: number,
  contact: GuruchatContact,
  authInfo: GuruchatAuth,
  socket: GuruchatSocket,
  clearAuthOnError: any,
  recoverySteps: any
) {
  if (contact) {
    if (socket && authInfo)
      try {
        const env: GetDialogPage = {
          operator_id: authInfo?.operator_id,
          contact_id: contact.telegram_id,
          type: Chat.GET_DIALOG_PAGE,
          page: pageNumber,
        };
        socket.send(JSON.stringify(env));
      } catch (e) {
        console.error('message out error: ', e);
        socket = null;
        clearAuthOnError();
      }
    if (!socket) recoverySteps();
  }
}

export function cmdGetContacts(
  authInfo: GuruchatAuth,
  socket: GuruchatSocket,
  clearAuthOnError: any,
  recoverySteps: any
) {
  if (socket && authInfo)
    try {
      const env: GetContacts = {
        operator_id: authInfo?.operator_id,
        type: Chat.GET_CONTACTS,
      };
      socket.send(JSON.stringify(env));
    } catch (e) {
      console.error('message out error: ', e);
      socket = null;
      clearAuthOnError();
    }
  if (!socket) recoverySteps();
}

export function cmdSendMessage(
  authInfo: GuruchatAuth,
  socket: GuruchatSocket,
  currentContact: IContact,
  message: IOutgoing,
  clearAuthOnError: any,
  recoverySteps: any,
  type: string
) {
  if (socket && authInfo)
    try {
      // type ImagesMessage
      const env = {
        ...(type === 'operators' && { to_operator: true }),
        operator_id: authInfo?.operator_id,
        contact_id: currentContact.telegram_id,
        hall_id: currentContact.hall_id,
        operator_name: authInfo?.operator_name,
        payId: currentContact.pay_id,
        message: message.message,
        image: message.imgSrc,
        images: message.imgSrc ? [message.imgSrc] : [],
        ...(type === 'support' && { attachment: message.imgSrc }),
        ...(message.hasOwnProperty('attachment') &&
          (type === 'operators' || type === 'support') && { attachment: message.attachment }),
        ...(type === 'operators' && { receiver_id: currentContact?.operator_id }),
        //        status: 'NEW'
        type: Chat.SEND_MESSAGE,
      };
      socket.send(JSON.stringify(env));
    } catch (e) {
      console.error('message out error: ', e);
      socket = null;
      clearAuthOnError();
    }
  if (!socket) recoverySteps();
}

export function cmdSendImageMessage(
  authInfo: GuruchatAuth,
  socket: GuruchatSocket,
  currentContact: IContact,
  imageLink: string,
  imageMessage: string,
  clearAuthOnError: any,
  recoverySteps: any
) {
  if (socket && authInfo)
    try {
      const env: ImageMessage = {
        attachment: '' + imageLink,
        photo: '' + imageLink,
        images: ['' + imageLink],
        operator_id: authInfo.operator_id,
        contact_id: currentContact.telegram_id,
        hall_id: currentContact.hall_id,
        operator_name: authInfo.operator_name,
        payId: currentContact.pay_id,
        type: Chat.SEND_MESSAGE,
        message: '' + imageMessage,
      };
      socket.send(JSON.stringify(env));
    } catch (e) {
      console.error('message out error: ', e);
      socket = null;
      clearAuthOnError();
    }
  if (!socket) recoverySteps();
}

export function cmdGetOperatorContacts(
  authInfo: GuruchatAuth,
  socket: GuruchatSocket,
  clearAuthOnError: any,
  recoverySteps: any
) {
  if (socket && authInfo)
    try {
      const env: GetContacts = {
        operator_id: authInfo?.operator_id,
        type: 'getOperatorContacts',
      };
      socket.send(JSON.stringify(env));
    } catch (e) {
      console.error('message out error: ', e);
      socket = null;
      clearAuthOnError();
    }
  if (!socket) recoverySteps();
}
export function cmdGetOperatorDialog(
  contact: GuruchatContact,
  socket: GuruchatSocket,
  authInfo: GuruchatAuth,
  clearAuthOnError: any,
  recoverySteps: any
) {
  if (contact) {
    if (socket && authInfo && contact?.operator_id)
      try {
        const env: GetDialog = {
          operator_id: contact?.operator_id,
          contact_id: authInfo?.operator_id,
          type: 'getOperatorDialog',
        };
        socket.send(JSON.stringify(env));
      } catch (e) {
        console.error('message out error: ', e);
        socket = null;
        clearAuthOnError();
      }
    if (!socket) recoverySteps();
  }
}
export function cmdGetOperatorDialogPage(
  pageNumber: number,
  contact: GuruchatContact,
  authInfo: GuruchatAuth,
  socket: GuruchatSocket,
  clearAuthOnError: any,
  recoverySteps: any
) {
  // old code
  if (contact) {
    if (socket && authInfo && contact?.operator_id)
      try {
        const env: GetDialogPage = {
          operator_id: contact?.operator_id,
          contact_id: authInfo?.operator_id,
          type: 'getOperatorDialogPage',
          page: pageNumber,
        };
        socket.send(JSON.stringify(env));
      } catch (e) {
        console.error('message out error: ', e);
        socket = null;
        clearAuthOnError();
      }
    if (!socket) recoverySteps();
  }
}

export function cmdGetGroups(
  authInfo: GuruchatAuth,
  socket: GuruchatSocket,
  clearAuthOnError: any,
  recoverySteps: any
) {
  if (socket && authInfo)
    try {
      const env: GetContacts = {
        operator_id: authInfo?.operator_id,
        type: 'getGroups',
      };
      socket.send(JSON.stringify(env));
    } catch (e) {
      console.error('message out error: ', e);
      socket = null;
      clearAuthOnError();
    }
  if (!socket) recoverySteps();
}

export function cmdGetGroupDialog(
  group: GuruchatGroup,
  socket: GuruchatSocket,
  authInfo: GuruchatAuth,
  clearAuthOnError: any,
  recoverySteps: any
) {
  if (group) {
    if (socket && authInfo && group?.group_id)
      try {
        const env: GetGroupDialog = {
          group_id: group?.group_id,
          type: 'getGroupDialog',
        };
        socket.send(JSON.stringify(env));
      } catch (e) {
        console.error('message out error: ', e);
        socket = null;
        clearAuthOnError();
      }
    if (!socket) recoverySteps();
  }
}

export function cmdGetGroupDialogPage(
  pageNumber: number,
  group: GuruchatGroup,
  authInfo: GuruchatAuth,
  socket: GuruchatSocket,
  clearAuthOnError: any,
  recoverySteps: any
) {
  if (group) {
    if (socket && authInfo && group?.group_id)
      try {
        const env: GetGroupDialogPage = {
          group_id: group?.group_id,
          type: 'getGroupDialogPage',
          page: pageNumber,
        };
        socket.send(JSON.stringify(env));
      } catch (e) {
        console.error('message out error: ', e);
        socket = null;
        clearAuthOnError();
      }
    if (!socket) recoverySteps();
  }
}
export function cmdSendGroupMessage() {}
export function cmdUpdateGroupStatusMessage() {}

export function cmdInviteUser(
  data: { tgName: string; realName: string; hall: number },
  socket: GuruchatSocket,
  clearAuthOnError: any,
  recoverySteps: any
) {
  const { tgName, realName, hall } = data;
  if (data) {
    if (socket)
      try {
        const env = {
          type: 'createInvite',
          tgName,
          realName,
          hall,
        };
        socket.send(JSON.stringify(env));
      } catch (e) {
        console.error('message out error: ', e);
        socket = null;
        clearAuthOnError();
      }
    if (!socket) recoverySteps();
  }
}

export function cmdGetInvitedContacts(
  authInfo: GuruchatAuth,
  socket: GuruchatSocket,
  clearAuthOnError: any,
  recoverySteps: any
) {
  if (socket && authInfo)
    try {
      const env: { type: string; operator_id: number } = {
        type: 'getInvitedContacts',
        operator_id: authInfo?.operator_id,
      };
      socket.send(JSON.stringify(env));
    } catch (e) {
      console.error('message out error: ', e);
      socket = null;
      clearAuthOnError();
    }
  if (!socket) recoverySteps();
}

export function cmdGetInvitedDialog(
  contact: GuruchatContact,
  socket: GuruchatSocket,
  clearAuthOnError: any,
  recoverySteps: any
) {
  if (contact) {
    if (socket && contact?.telegram_id)
      try {
        const env: { telegram_id: number; type: string } = {
          telegram_id: contact?.telegram_id,
          type: 'getInvitedDialog',
        };
        socket.send(JSON.stringify(env));
      } catch (e) {
        console.error('message out error: ', e);
        socket = null;
        clearAuthOnError();
      }
    if (!socket) recoverySteps();
  }
}

export function cmdGetInvitedDialogPage(
  pageNumber: number,
  contact: GuruchatContact,
  socket: GuruchatSocket,
  clearAuthOnError: any,
  recoverySteps: any
) {
  if (contact) {
    if (socket)
      try {
        const env: { telegram_id: number; type: string; page: number } = {
          telegram_id: contact?.telegram_id,
          type: 'getInvitedDialogPage',
          page: pageNumber,
        };
        socket.send(JSON.stringify(env));
      } catch (e) {
        console.error('message out error: ', e);
        socket = null;
        clearAuthOnError();
      }
    if (!socket) recoverySteps();
  }
}

export function cmdGetInvitedPlayerInfo(
  contact: GuruchatContact,
  socket: GuruchatSocket,
  clearAuthOnError: any,
  recoverySteps: any
) {
  if (contact) {
    if (socket) {
      try {
        const env = {
          telegram_id: contact?.telegram_id,
          type: 'getInvitedPlayerInfo',
        };
        socket.send(JSON.stringify(env));
      } catch (e) {
        console.error('message out error: ', e);
        socket = null;
        clearAuthOnError();
      }
    }
    if (!socket) recoverySteps();
  }
}

export function cmdSendInvitedMessage(
  authInfo: GuruchatAuth,
  socket: GuruchatSocket,
  currentContact: IContact,
  message: IOutgoing,
  clearAuthOnError: any,
  recoverySteps: any
) {
  if (socket && authInfo)
    try {
      const env = {
        operator_id: authInfo?.operator_id,
        operator_name: authInfo?.operator_name,
        telegram_id: currentContact?.telegram_id,
        message: message.message,
        type: 'sendInvitedMessage',
      };
      socket.send(JSON.stringify(env));
    } catch (e) {
      console.error('message out error: ', e);
      socket = null;
      clearAuthOnError();
    }
  if (!socket) recoverySteps();
}
