// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_modalDialog__-F7kj {
  width: 328px;
}
@media (max-width: 576px) {
  .style_modalDialog__-F7kj {
    width: auto;
  }
}
.style_modalDialog__-F7kj .style_modalHeader__rcUg- {
  justify-content: space-between;
  border: none;
}
.style_modalDialog__-F7kj .style_modalHeader__rcUg- .style_dlgHeader__CUVZM {
  display: flex;
  align-items: center;
  gap: 16px;
}
.style_modalDialog__-F7kj .style_modalHeader__rcUg- .style_dlgHeader__CUVZM .style_dlgHeaderIco__m\\+U3l {
  position: static;
}
.style_modalDialog__-F7kj .style_modalFooter__ZoCev {
  border: none;
}
.style_modalDialog__-F7kj .style_modalFooter__ZoCev .style_dlgBtns__FFwhg {
  width: 100%;
  display: flex;
  gap: 16px;
}
.style_modalDialog__-F7kj .style_modalFooter__ZoCev .style_dlgBtns__FFwhg button {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Dialog/PlayerInfo/CancelChangesDlg/style.module.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;AADF;AAGE;EAHF;IAII,WAAA;EAAF;AACF;AAEE;EACE,8BAAA;EACA,YAAA;AAAJ;AAEI;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAAN;AAEM;EACE,gBAAA;AAAR;AAKE;EACE,YAAA;AAHJ;AAKI;EACE,WAAA;EACA,aAAA;EACA,SAAA;AAHN;AAKM;EACE,WAAA;AAHR","sourcesContent":["@import '../../../../variables.scss';\n\n.modalDialog {\n  width: 328px;\n\n  @media (max-width: 576px) {\n    width: auto;\n  }\n\n  .modalHeader {\n    justify-content: space-between;\n    border: none;\n\n    .dlgHeader {\n      display: flex;\n      align-items: center;\n      gap: 16px;\n\n      .dlgHeaderIco {\n        position: static;\n      }\n    }\n  }\n\n  .modalFooter {\n    border: none;\n\n    .dlgBtns {\n      width: 100%;\n      display: flex;\n      gap: 16px;\n\n      button {\n        width: 100%;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalDialog": `style_modalDialog__-F7kj`,
	"modalHeader": `style_modalHeader__rcUg-`,
	"dlgHeader": `style_dlgHeader__CUVZM`,
	"dlgHeaderIco": `style_dlgHeaderIco__m+U3l`,
	"modalFooter": `style_modalFooter__ZoCev`,
	"dlgBtns": `style_dlgBtns__FFwhg`
};
export default ___CSS_LOADER_EXPORT___;
