// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_smilesCtrl__01i0Q {
  color: #909399;
  transition: color 0.15s;
}
.style_smilesCtrl__01i0Q:hover {
  color: #5f8fdf;
}

.style_btnClose__Wsq20 {
  background: transparent;
  border: none;
  padding: 0;
}
.style_btnClose__Wsq20 svg {
  fill: #909399;
}

.style_modalDialog__VwwmO .style_modalHeader__LOQEY {
  justify-content: space-between;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Dialog/Smiles/style.module.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAQA;EACE,cCKe;EDJf,uBAAA;AAPF;AASE;EACE,cCNW;ADDf;;AAWA;EACE,uBAAA;EACA,YAAA;EACA,UAAA;AARF;AAUE;EACE,aCTa;ADCjB;;AAaE;EACE,8BAAA;EACA,YAAA;AAVJ","sourcesContent":["@import '../../../variables.scss';\n\n// .smilelist {\n//   width: 100%;\n//   height: 300px;\n//   overflow-y: scroll;\n// }\n\n.smilesCtrl {\n  color: $text-color-600;\n  transition: color 0.15s;\n\n  &:hover {\n    color: $active-color;\n  }\n}\n\n.btnClose {\n  background: transparent;\n  border: none;\n  padding: 0;\n\n  svg {\n    fill: $text-color-600;\n  }\n}\n\n.modalDialog {\n  .modalHeader {\n    justify-content: space-between;\n    border: none;\n  }\n}\n","$white: #ffffff;\n$black: #000000;\n\n$gray-background: #f5f5f7;\n$gray-border: #dce1e6;\n$gray-box-border: #e9e9eb;\n$gray-active: #eaedef;\n$active-color: #5f8fdf;\n$active-color-op: rgba(95, 143, 223, 0.4);\n// $active-color: #00ff95;\n$sender-message-color: #cce4ff;\n\n$text-color-800: #4a596a;\n$text-color-700: #626d7a;\n$text-color-600: #909399;\n\n$active-color-2: #ec205f;\n\n// $header-color: #add8e6;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"smilesCtrl": `style_smilesCtrl__01i0Q`,
	"btnClose": `style_btnClose__Wsq20`,
	"modalDialog": `style_modalDialog__VwwmO`,
	"modalHeader": `style_modalHeader__LOQEY`
};
export default ___CSS_LOADER_EXPORT___;
