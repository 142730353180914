import React, { FC, memo, MouseEvent } from 'react';
import { Button } from 'reactstrap';
import avatar from 'assets/images/chats/avatar.svg';
import info from 'assets/images/chats/info.svg';
import classes from './style.module.scss';
import { toogleDel } from 'types/React';
import { IContact } from 'models/Contact';

interface ITitleBlockProps {
  currentContact?: IContact;
  showPlayerInfo: boolean;
  tooglePlayerInfo: () => void;
  mobileClose: () => void;
  currentContactBackClear: () => void;
  type: string;
}

const TitleBlock: FC<ITitleBlockProps> = (props) => {
  const { currentContact, tooglePlayerInfo, showPlayerInfo, currentContactBackClear, type } = props;

  function closeUserChat(e: MouseEvent<HTMLButtonElement>) {
    // e.preventDefault();
    console.log('closeUSERINFO');
    if (window.innerWidth <= 556) {
      if (showPlayerInfo) tooglePlayerInfo();
      if (!showPlayerInfo) currentContactBackClear();
    } else {
      currentContactBackClear();
    }

    // toogleDel('user-chat', 'user-chat-hide');
    // toogleDel('chat-left', 'chat-left-hide');
  }

  const onProfileClick = () => {
    tooglePlayerInfo();
  };

  return (
    <div className={classes.dialogTitle}>
      <div className={classes.userLeftBlock}>
        <div className={classes.backToDialog}>
          <button onClick={closeUserChat}>
            <i className="ri-arrow-left-s-line"></i>
          </button>
        </div>
        <div className={`${classes.userAvatar} ${showPlayerInfo ? classes.none : ''}`}>
          <img src={avatar} alt="not present" />
        </div>
        <div className={`${classes.userName} ${showPlayerInfo ? classes.none : ''}`}>
          {currentContact?.name}
        </div>
      </div>
      <div className={classes.userRightBlock}>
        <button onClick={onProfileClick} className={showPlayerInfo ? classes.none : ''}>
          <img src={info} alt="not present" />
        </button>
      </div>
    </div>
  );
};

export default memo(TitleBlock);
