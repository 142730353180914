// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `select:focus,
select:focus-within {
  box-shadow: none !important;
}

.input-valid.is-invalid {
  border-color: #ec205f !important;
}
.input-valid.is-invalid:focus {
  outline: 3px solid rgba(236, 32, 95, 0.2);
}

.tooltipWrapper .tooltip-inner {
  background-color: #ffffff;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  border: 1px solid #dce1e6;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
}
.tooltipWrapper .tooltip-arrow,
.tooltipWrapper .tooltip-arrow::before {
  border-top-color: #ffffff !important;
}`, "",{"version":3,"sources":["webpack://./src/globals.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;;EAEE,2BAAA;AADF;;AAIA;EACE,gCAAA;AADF;AAGE;EACE,yCAAA;AADJ;;AAME;EACE,yBCjBI;EDkBJ,yCAAA;EACA,yBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;EAEA,YAAA;AAJJ;AAOE;;EAEE,oCAAA;AALJ","sourcesContent":["@import './variables.scss';\n\nselect:focus,\nselect:focus-within {\n  box-shadow: none !important;\n}\n\n.input-valid.is-invalid {\n  border-color: #ec205f !important;\n\n  &:focus {\n    outline: 3px solid rgba(236, 32, 95, 0.2);\n  }\n}\n\n.tooltipWrapper {\n  .tooltip-inner {\n    background-color: $white;\n    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);\n    border: 1px solid $gray-border;\n    max-width: 120px;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n\n    color: black;\n  }\n\n  .tooltip-arrow,\n  .tooltip-arrow::before {\n    border-top-color: $white !important;\n  }\n}\n","$white: #ffffff;\n$black: #000000;\n\n$gray-background: #f5f5f7;\n$gray-border: #dce1e6;\n$gray-box-border: #e9e9eb;\n$gray-active: #eaedef;\n$active-color: #5f8fdf;\n$active-color-op: rgba(95, 143, 223, 0.4);\n// $active-color: #00ff95;\n$sender-message-color: #cce4ff;\n\n$text-color-800: #4a596a;\n$text-color-700: #626d7a;\n$text-color-600: #909399;\n\n$active-color-2: #ec205f;\n\n// $header-color: #add8e6;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
