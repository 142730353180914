import Contacts from 'components/Contacts';
import Dialogs from 'components/Dialog';
import { PATH_HOME_LOGIN } from 'constants/paths';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import {
  BalanceMode,
  ChatRecoveryMode,
  IBalanceChanges,
  IBalanceModeChanges,
  IPlayerBalance,
  IWeeklyResetChanges,
  createEmptyPlayerBalance,
} from 'models/Balance';
import { IContact } from 'models/Contact';
import { IGroup } from 'models/Group';
import { Dialog, DialogTypeEnum, IDialog } from 'models/Dialog';
import { MessageTypeEnum } from 'models/Message';
import {
  FetchContactMessages,
  IOutgoing,
  PeerMessage,
  TelegramBotMessage,
  fetchPeerPhoto,
  fetchPhoto,
  getPeerMessage,
  getPeerName,
} from 'models/Message/type';
import { IPlayerInfo, IPlayerInfoChanges, createEmptyPlayerInfo } from 'models/Player';
import { FC, useEffect, useState, useRef } from 'react';
import {
  AUTH_TAG,
  Chat,
  IMAGES_PATH,
  LAST_DIALOG_PAGE_IDX,
  chatlog,
  crm,
  getHostPortWSS,
  incrementNum,
} from 'types/Chat';
import { IAuthInfo, SomewhereOnlineMessage } from 'types/IAuthInfo';
import {
  ICangeStatus,
  IChangePlayerId,
  IGalleryImage,
  IGalleryMessage,
  IHallImagesGet,
  IHallMessagesGet,
  ISwitchVip,
  ITemplateMessageCreate,
  ITemplateMessageDelete,
} from 'types/IHallImagesGet';
import { CheckTokenWithNoContactsProvider } from 'types/Login';
import {
  cmdChangeStatusSend,
  cmdCreateMsgTemplateSend,
  cmdDeleteMsgTemplateSend,
  cmdGetContacts,
  cmdGetDialog,
  cmdGetDialogPage,
  cmdGetPlayerInfo,
  cmdGetUserInfo,
  cmdSavePlayerId,
  cmdSendImageMessage,
  cmdSendMessage,
  cmdSwitchVipSend,
  cmdUpdateHallImagesSend,
  cmdUpdateHallMessagesSend,
  cmdUpdateMoneyBalance,
  cmdUpdatePlayerInfo,
  cmdGetOperatorContacts,
  cmdGetOperatorDialog,
  cmdGetOperatorDialogPage,
  cmdGetGroups,
  cmdGetGroupDialog,
  cmdGetGroupDialogPage,
  cmdSendGroupMessage,
  cmdUpdateGroupStatusMessage,
  cmdInviteUser,
  cmdGetInvitedContacts,
  cmdGetInvitedDialog,
  cmdGetInvitedDialogPage,
  cmdGetInvitedPlayerInfo,
  cmdSendInvitedMessage,
} from 'types/commands';
import { ENV } from '../.env';
import { GuruchatContact, GuruchatGroup, GuruchatSocket } from 'types/soket';
import { ChatCommand } from 'types/ChatCommand';
import { Dispatcher, IChatResponceProc } from 'types/IChatResponceProc';
import { isWebMessage } from 'types/WebMessage';
// toast
import { ToastContainer, toast, Bounce } from 'react-toastify';
import useAppStore from 'store';
import { Group } from 'store/groups';

interface keys {
  [key: string]: any;
}

const env = ENV || 'dev';
const config = require('../config/config.json')[env];

const Guruchat: FC = () => {
  const navigate = useNavigate();
  const ws = useRef<WebSocket | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const reconnectInterval = useRef<NodeJS.Timeout | number | null>(null);
  const pingInterval = useRef<NodeJS.Timeout | number | null>(null);
  const isManuallyClosed = useRef<boolean>(false);
  const [contacts, setContacts] = useState<IContact[]>([]);
  const [currentContact, setCurrentContact] = useState<IContact>();
  const [groups, setGroups] = useState<IGroup[]>([]);
  const [currentGroup, setCurrentGroup] = useState<IGroup>();
  const [currentDialogPage, setCurrentDialogPage] = useState<number>(0);
  const [imageLink, setImageLink] = useState<string>('');
  const [imageMessage, setImageMessage] = useState<string>('');
  const [message, setMessage] = useState<TelegramBotMessage | null>(null);
  const [dialog, setDialog] = useState<IDialog[]>([]);
  const [dialogPage, setDialogPage] = useState<IDialog[]>([]);
  const [authInfo, setAuthInfo] = useState<IAuthInfo | null>(null);
  const [reopenCount, setReopenCount] = useState<number>(1);
  const [playerInfo, setPlayerInfo] = useState<IPlayerInfo>(createEmptyPlayerInfo());
  const [userInfo, setUserInfo] = useState({});
  const [invitedPlayerInfo, setInvitedPlayerInfo] = useState({});
  const [playerInfoBalance, setPlayerInfoBalance] = useState<number | null>(null);
  const [playerInfoBalanceMode, setPlayerInfoBalanceMode] = useState<BalanceMode | null>(
    BalanceMode.MODE_BALANCE
  );
  const [playerBalance, setPlayerBalance] = useState<IPlayerBalance>(createEmptyPlayerBalance());
  const [imagesFromGallery, setImagesFromGallery] = useState<IGalleryImage[] | undefined>();
  const [messagesFromGallery, setMessagesFromGallery] = useState<IGalleryMessage[] | undefined>();
  const [loadingContactLists, setLoadingContactLoading] = useState<boolean>(false);
  const [cookies, setCookie, removeCookie] = useCookies([AUTH_TAG]);

  // types chat
  // type TypeChat = 'operators' | 'support' | 'invite';
  // const [type, setType] = useState<TypeChat>(() => {
  //   const savedType = sessionStorage.getItem('chatType');
  //   return (savedType as TypeChat) || 'operators';
  // });

  const [flag, setFlag] = useState<number>();
  const [clearDialog, setClearDialog] = useState<number>();

  const [halls, setHalls] = useState<any>([]);

  // store
  const activeGroup = useAppStore((state) => state.activeGroup);
  const groupsStore = useAppStore((state) => state.groups);
  const setGroupsStore = useAppStore((state) => state.setGroups);
  const activeGroupRef = useRef<Group | null>(activeGroup);

  function currentContactBackClear() {
    let emptyContact;
    setCurrentContact(emptyContact);
  }

  function fetchHallMessages() {
    const arg = null;
    const idHall = currentContact?.hall_id ?? 0;
    const messagesHallReq: IHallMessagesGet = {
      hall_id: idHall,
      type: Chat.SEND_COMMAND,
      command: ChatCommand.GET_HALL_MESSAGES,
      argument: arg,
    };
    getHallMessages(messagesHallReq);
  }

  function updateDialog(newMsg: PeerMessage) {
    console.log('------MESSAGE------', newMsg);
    const newMsgsTime = new Date();
    const msg: IDialog = Dialog.createDialog(
      newMsg.senderName === authInfo?.operator_name ? DialogTypeEnum.out : DialogTypeEnum.in,
      newMsg.chat_message_id,
      // newMsgsTime.getTime(),
      MessageTypeEnum.text,
      newMsg.message_status,
      newMsgsTime,
      getPeerName(newMsg),
      fetchPeerPhoto(newMsg),
      getPeerMessage(newMsg),
      newMsg.hasOwnProperty('media') ? newMsg.media : ''
    );
    let allMsg: IDialog[] = [...dialog];
    allMsg.push(msg);
    setDialog(allMsg);
  }

  function updateStatusMessage(telegram_id: number, idHall: number) {
    ws.current?.send(
      JSON.stringify({
        type: 'updateStatusMessages',
        telegramId: telegram_id,
        hall_id: idHall,
      })
    );
  }

  function updateOperatorStatusMessage(operator_id: number, currentContact: IContact | undefined) {
    ws.current?.send(
      JSON.stringify({
        type: 'updateOperatorStatusMessages',
        operatorId: operator_id,
        contactId: currentContact?.operator_id,
      })
    );
  }

  // function updateContacts(telegramId: number): void {
  //   setFlag(telegramId);
  // }

  function savePlayerInfo(cc: IPlayerInfoChanges) {
    updatePlayerInfo(cc);
  }

  function changeBalance(cc: IBalanceChanges) {
    updateMoneyBalance(cc);
  }

  function changeBalanceMode(bmc: IBalanceModeChanges) {
    updateMoneyBalanceMode(bmc);
  }

  function sendReset(wrc: IWeeklyResetChanges) {
    updateWeekReset(wrc);
  }

  function getHallImages(message: IHallImagesGet): string[] {
    updateHallImagesSend(message);
    return [];
  }

  function getHallMessages(message: IHallMessagesGet): string[] {
    updateHallMessagesSend(message);
    return [];
  }

  function changeStatus(cc: ICangeStatus) {
    cmdChangeStatusSend(currentContact, ws.current, authInfo, cc, clearAuthOnError, recoverySteps);
  }

  function switchVip(cc: ISwitchVip) {
    cmdSwitchVipSend(currentContact, ws.current, authInfo, cc, clearAuthOnError, recoverySteps);
  }

  function savePlayerId(cc: IChangePlayerId) {
    cmdSavePlayerId(currentContact, ws.current, authInfo, cc, clearAuthOnError, recoverySteps);
  }

  function deleteMsgTemplate(id: number) {
    if (messagesFromGallery)
      setMessagesFromGallery(messagesFromGallery.filter((item) => item.id !== id));
    const arg = '' + id;
    const idHall = currentContact?.hall_id;
    if (idHall) {
      const messagesHallReq: ITemplateMessageDelete = {
        hall_id: idHall,
        type: Chat.SEND_COMMAND,
        command: ChatCommand.DELETE_MESSAGE,
        argument: arg,
      };

      deleteMsgTemplateSend(messagesHallReq);
    }
  }

  function deleteMsgTemplateSend(cc: ITemplateMessageDelete) {
    cmdDeleteMsgTemplateSend(
      currentContact,
      ws.current,
      authInfo,
      cc,
      clearAuthOnError,
      recoverySteps
    );
  }

  function createMsgTemplate(msgTempl: IGalleryMessage) {
    const arg = JSON.stringify({
      name: msgTempl.title,
      shortcut: msgTempl.shortcat,
      message: msgTempl.body,
      id: msgTempl.id,
    });
    console.log('sending create message template__ ' + arg + '____' + JSON.stringify(msgTempl));
    const idHall = currentContact?.hall_id;
    if (idHall) {
      const messagesHallReq: ITemplateMessageCreate = {
        hall_id: idHall,
        type: Chat.SEND_COMMAND,
        command: ChatCommand.STORE_MESSAGE,
        argument: arg,
      };

      createMsgTemplateSend(messagesHallReq);
    }
  }

  function createMsgTemplateSend(cc: ITemplateMessageCreate) {
    cmdCreateMsgTemplateSend(
      currentContact,
      ws.current,
      authInfo,
      cc,
      clearAuthOnError,
      recoverySteps
    );
  }

  function updateHallMessagesSend(cc: IHallMessagesGet) {
    cmdUpdateHallMessagesSend(
      currentContact,
      ws.current,
      authInfo,
      cc,
      clearAuthOnError,
      recoverySteps
    );
  }

  function updateHallImagesSend(cc: IHallImagesGet) {
    cmdUpdateHallImagesSend(
      currentContact,
      ws.current,
      authInfo,
      cc,
      clearAuthOnError,
      recoverySteps
    );
  }

  function sendMessage(message: IOutgoing) {
    try {
      sendMessageInternal(message);
    } catch (e) {
      console.error('send message error: ', e);
      clearAuthOnError();
    }
    if (!ws.current) recoverySteps();
  }

  function sendMessageInternal(message: IOutgoing) {
    if (currentContact) {
      if (ws.current) {
        currentContact.last_message = message.message;
        currentContact.last_message_ts = new Date().getTime() / 1000;

        if (message.imgObj) {
          setImageMessage('' + message.message);
          ws.current.send(message.imgObj);
          return;
        }

        if (['support', 'operators'].includes(activeGroup.value)) {
          cmdSendMessage(
            authInfo,
            ws.current,
            currentContact,
            message,
            clearAuthOnError,
            recoverySteps,
            activeGroup.value
          );
        } else if (activeGroup.value === 'invite') {
          cmdSendInvitedMessage(
            authInfo,
            ws.current,
            currentContact,
            message,
            clearAuthOnError,
            recoverySteps
          );
        }
      }
      if (!ws.current) setReopenCount(reopenCount + 1);
    }
  }

  function dialogPagesUp() {
    let pageIdx = 0;
    if (currentDialogPage !== null && currentDialogPage !== undefined && currentDialogPage >= 0)
      pageIdx = currentDialogPage + 1;
    setCurrentDialogPage(pageIdx);
    activeGroup.value === 'support' && getDialogPage(pageIdx, currentContact);
    activeGroup.value === 'operators' && getOperatorDialogPage(pageIdx, currentContact);
    activeGroup.value === 'invite' && getInvitedDialogPage(pageIdx, currentContact);
    console.log('INDEX INDEX', pageIdx);
  }

  function dialogPagesDown() {
    console.log('DOWN DOWN DOWN');
    // let pageIdx = 0;
    // if (currentDialogPage !== null && currentDialogPage !== undefined && currentDialogPage >= 0)
    //   pageIdx = currentDialogPage - 1;
    // if (pageIdx < 0) pageIdx = 0;
    // setCurrentDialogPage(pageIdx);
    // type === 'support' && getDialogPage(pageIdx, currentContact);
    // type === 'operators' && getOperatorDialogPage(pageIdx, currentContact);
    // console.log('INDEX INDEX', pageIdx);
  }

  const dlgCtrl: any = {
    loadPagesUp: dialogPagesUp,
    loadPagesDown: dialogPagesDown,
  };

  function transformDialog(contactMessages: any[]): IDialog[] {
    const fetch: FetchContactMessages[] = JSON.parse(JSON.stringify(contactMessages));

    let result: IDialog[] = fetch.map((newMsg: FetchContactMessages) => {
      const msg: IDialog = Dialog.createDialog(
        newMsg.senderName === authInfo?.operator_name ? DialogTypeEnum.out : DialogTypeEnum.in,
        newMsg._id,
        MessageTypeEnum.text,
        newMsg.status,
        newMsg.created_at,
        newMsg.senderName,
        fetchPhoto(newMsg),
        newMsg.message,
        newMsg.hasOwnProperty('media') ? newMsg.media : ''
      );
      return msg;
    });

    return result;
  }

  function clearAuthOnError() {
    setAuthInfo(null);
    // setWs(null);
  }

  function recoverySteps() {
    setReopenCount(reopenCount + 1);
  }

  function getPlayerInfo(contact: GuruchatContact) {
    cmdGetPlayerInfo(contact, ws.current, clearAuthOnError, recoverySteps);
  }

  function updatePlayerInfo(cc: IPlayerInfoChanges) {
    cmdUpdatePlayerInfo(currentContact, ws.current, cc, clearAuthOnError, recoverySteps);
  }

  function getUserInfo(contact: GuruchatContact) {
    cmdGetUserInfo(contact, ws.current, clearAuthOnError, recoverySteps);
  }

  function updateWeekReset(wrc: IWeeklyResetChanges) {
    updateMoneyBalance(wrc);
  }

  function updateMoneyBalanceMode(bmc: IBalanceModeChanges) {
    updateMoneyBalance(bmc);
  }

  function updateMoneyBalance(cc: IBalanceChanges) {
    cmdUpdateMoneyBalance(
      currentContact,
      ws.current,
      authInfo,
      cc,
      clearAuthOnError,
      recoverySteps
    );
  }

  function getDialog(contact: GuruchatContact) {
    cmdGetDialog(contact, ws.current, authInfo, clearAuthOnError, recoverySteps);
  }

  function getDialogPage(pageNumber: number, contact: GuruchatContact) {
    cmdGetDialogPage(pageNumber, contact, authInfo, ws.current, clearAuthOnError, recoverySteps);
  }

  function getContacts(socket: GuruchatSocket) {
    cmdGetContacts(authInfo, socket, clearAuthOnError, recoverySteps);
  }

  //--------- NEW METHODS ---------
  function getOperatorContacts(socket: GuruchatSocket) {
    cmdGetOperatorContacts(authInfo, socket, clearAuthOnError, recoverySteps);
  }

  function getOperatorDialog(contact: GuruchatContact) {
    cmdGetOperatorDialog(contact, ws.current, authInfo, clearAuthOnError, recoverySteps);
  }

  function getOperatorDialogPage(pageNumber: number, contact: GuruchatContact) {
    cmdGetOperatorDialogPage(
      pageNumber,
      contact,
      authInfo,
      ws.current,
      clearAuthOnError,
      recoverySteps
    );
  }

  function updateOperatorStatus(cc: ICangeStatus) {
    cmdChangeStatusSend(currentContact, ws.current, authInfo, cc, clearAuthOnError, recoverySteps);
  }
  //--------- END NEW METHODS ---------

  //--------- NEW GROUP METHOD ---------
  function getGroups(socket: GuruchatSocket) {
    cmdGetGroups(authInfo, socket, clearAuthOnError, recoverySteps);
  }

  function getGroupDialog(group: GuruchatGroup) {
    cmdGetGroupDialog(group, ws.current, authInfo, clearAuthOnError, recoverySteps);
  }

  function getGroupDialogPage(pageNumber: number, group: GuruchatGroup) {
    cmdGetGroupDialogPage(pageNumber, group, authInfo, ws.current, clearAuthOnError, recoverySteps);
  }

  function sendGroupMessage() {
    cmdSendGroupMessage();
  }

  function updateGroupStatusMessage() {
    cmdUpdateGroupStatusMessage();
  }
  //--------- END GROUP METHOD ---------

  //--------- NEW INVITED METHOD ---------
  function getInvitedContacts(socket: GuruchatSocket) {
    cmdGetInvitedContacts(authInfo, socket, clearAuthOnError, recoverySteps);
  }

  function getInvitedDialog(contact: GuruchatContact) {
    cmdGetInvitedDialog(contact, ws.current, clearAuthOnError, recoverySteps);
  }

  function getInvitedDialogPage(pageNumber: number, contact: GuruchatContact) {
    cmdGetInvitedDialogPage(pageNumber, contact, ws.current, clearAuthOnError, recoverySteps);
  }

  function getInvitedPlayerInfo(contact: GuruchatContact) {
    cmdGetInvitedPlayerInfo(contact, ws.current, clearAuthOnError, recoverySteps);
  }

  // function sendInvitedMessage(message: IOutgoing) {
  //   if (currentContact)
  //     cmdSendInvitedMessage(
  //       authInfo,
  //       ws.current,
  //       currentContact,
  //       message,
  //       clearAuthOnError,
  //       recoverySteps
  //     );
  // }
  //--------- END INVITED METHOD ---------

  function inviteUser(data: { tgName: string; realName: string; hall: number }) {
    cmdInviteUser(data, ws.current, clearAuthOnError, recoverySteps);
    if (activeGroup.value === 'invite') {
      setLoadingContactLoading(true);
      setCurrentContact(undefined);
      setDialog([]);
      setDialogPage([]);
      getInvitedContacts(ws.current);
    }
  }

  function fetchTokenCookie(): boolean {
    console.log(!cookies.hasOwnProperty('auth-token'), cookies['auth-token']?.length === 0);

    let authObj: IAuthInfo | null = null;

    if (cookies.hasOwnProperty('auth-token') && cookies['auth-token']?.length !== 0) {
      const authObjRec = cookies['auth-token'];
      authObj = {
        token: authObjRec.token,
        operator_id: authObjRec.operator_id,
        operator_name: authObjRec.operator_name,
      };
    } else return true;

    if (
      authObj.token !== null &&
      authObj.token !== undefined &&
      authObj.token.length > 1 &&
      authObj.operator_id > 0
    ) {
      setAuthInfo(authObj);
      return false;
    }

    return true;
  }

  const onSocketOpenProc = () => {
    try {
      ws.current?.send(
        JSON.stringify({
          type: 'getAllowedHalls',
          operatorId: authInfo?.operator_id,
        })
      );
      if (activeGroupRef.current?.value === 'support') {
        getContacts(ws.current);
      }
      if (activeGroupRef.current?.value === 'operators') {
        getOperatorContacts(ws.current);
        // getGroups(wsClient);
      }
      if (activeGroupRef.current?.value === 'invite') {
        getInvitedContacts(ws.current);
      }
    } catch (e) {
      console.error('socket error opening:', e);
    }
  };

  const onSocketErrorProc = (et: Event) => {
    try {
      setAuthInfo(null);
      isManuallyClosed.current = true;
      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        console.log('----- C4 ------');
        ws.current.close();
      }
      console.log('ws closed on error ', et);
    } catch (e) {
      console.error('socket error closing:', e);
    }
  };

  const onSocketCloseProc = () => {
    try {
      console.log('ws closed');

      if (crm === ChatRecoveryMode.CRM_RECONNECT) {
        // setWs(null);
        recoverySteps();
        // startReOpening(authInfo, proxySock);
      } else if (crm === ChatRecoveryMode.CRM_RELOGIN) {
        setAuthInfo(null);
      }
    } catch (e) {
      console.error('socket close error:', e);
    }
  };

  function updateContactsWithNew(recipient: IContact, toUpdate: IContact[]) {
    let cp: IContact[] = [recipient];
    const anotherObject: keys = {
      operators: contacts.filter((contact) => contact.operator_id !== recipient.operator_id),
      support: contacts.filter((contact) => contact.pay_id !== recipient.pay_id),
      invite: contacts.filter((contact) => contact.telegram_id !== recipient.telegram_id),
    };
    const another = anotherObject[activeGroup.value];
    cp = cp.concat(another);
    return cp;
  }

  const logoutIncorrectToken = () => {
    // alert('Hello 3');
    setAuthInfo(null);
    isManuallyClosed.current = true;
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      console.log('----- C1 ------');
      ws.current.close();
    }
    navigate(PATH_HOME_LOGIN);
    removeCookie(AUTH_TAG);
  };

  class ChatProc implements IChatResponceProc {
    constructor() {}

    public onContactArrive(contactsResult: IContact[]) {
      console.log('contacts: ' + JSON.stringify(contactsResult));
      setContacts(contactsResult);

      if (contactsResult.length < 1 && authInfo)
        new CheckTokenWithNoContactsProvider().checkToken(
          authInfo.token,
          (validTokenTrue: boolean) => {
            if (validTokenTrue !== true) {
              setAuthInfo(null);
              // setWs(null);
            }
          }
        );
    }

    public onDialogArrive(contactMessages: IDialog[]) {
      setDialog(transformDialog(contactMessages));
    }

    public onGetDialogPageArrive(contactMessages: any) {
      if (
        contactMessages !== null &&
        contactMessages !== undefined &&
        contactMessages.length >= 0
      ) {
        console.log('DIALOG PAGE', contactMessages);
        const nextPage: IDialog[] = transformDialog(contactMessages);
        setDialogPage(nextPage);
      }
    }

    public onPlayerInfoArrive(result: any[]) {
      const pInfo: IPlayerInfo = result.length >= 1 ? result[0] : result;
      const pBalance: IPlayerBalance = result.length >= 1 + 1 ? result[0 + 1] : result;
      if (pInfo !== null && pInfo !== undefined) setPlayerInfo(pInfo);
      if (pBalance !== null && pBalance !== undefined) setPlayerBalance(pBalance);
    }

    public onPlayerInfoUpdated(result: any) {
      console.log('player info has been updated ' + JSON.stringify(result));
    }

    public onUserInfoArrive(result: any[]) {
      setUserInfo(result[0]);
    }

    public onInvitedPlayerInfoArrive(result: any[]) {
      setInvitedPlayerInfo(result[0]);
    }

    public onImagesHallComplete(cmdResult: IGalleryImage[]) {
      setImagesFromGallery(cmdResult);
    }

    public onMessagesHallComplete(cmdResult: any[]) {
      let messages: IGalleryMessage[] = [];

      if (cmdResult) {
        cmdResult.forEach((element) => {
          const msg: IGalleryMessage = {
            title: element.name,
            body: element.message,
            type: element.category,
            shortcat: element.shortcut,
            id: element.id,
          };
          messages.push(msg);
        });
      }

      setMessagesFromGallery(messages);
    }

    public onChangeBalanceCommandComplete(piBalance: number) {
      setPlayerInfoBalance(piBalance);
    }

    public onChangeBalanceModeCommandComplete(bMode: BalanceMode) {
      setPlayerInfoBalanceMode(bMode);
    }

    public onSetWeeklyResetComplete(cmdResult: any) {
      console.log('set weekly reset complete ' + JSON.stringify(cmdResult));
    }

    public onStoreMessageCommandComplete(cmdResult: any) {
      console.log('store message complete ' + JSON.stringify(cmdResult));
    }

    public onDeleteMessageCommandComplete(cmdResult: any) {
      console.log('delete message complete ' + JSON.stringify(cmdResult));
    }

    public onChangeStatusCommandComplete(cmdResult: any) {
      console.log('change status complete ' + JSON.stringify(cmdResult));
      getPlayerInfo(currentContact);
    }

    public onSwitchVipCommandComplete(cmdResult: any) {
      console.log('change Vip complete ' + JSON.stringify(cmdResult));
      getPlayerInfo(currentContact);
    }

    public onChangePlayerIdComplete(cmdResult: any) {
      console.log('changing PlayerId complete' + JSON.stringify(cmdResult));
    }

    public onImageLinkArrive(data: any) {
      setImageLink(IMAGES_PATH + data.image_link);
    }

    public onChatMsg(data: TelegramBotMessage) {
      const newMsg: TelegramBotMessage = JSON.parse(JSON.stringify(data));
      const webMsg = isWebMessage(newMsg);
      console.log('on chat msg__ ' + JSON.stringify(newMsg) + '__' + JSON.stringify(contacts));
      setMessage(data);
      if (contacts) {
        const result = contacts.filter((contact) => {
          return contact.name === newMsg.senderName || contact.name === newMsg.sender_name;
        });
        if (result && result.length > 0) {
          const flag: keys = {
            operators: currentContact?.operator_id === result[0].operator_id,
            support: currentContact?.pay_id === result[0].pay_id,
            invite: currentContact?.telegram_id === result[0].telegram_id,
          };
          if (flag[activeGroup.value] || result[0].hasOwnProperty('message'))
            result[0].unreadMessagesNum = 0;
          else if (webMsg) result[0].unreadMessagesNum = incrementNum(result[0].unreadMessagesNum);

          let cp: IContact[] = updateContactsWithNew(result[0], contacts);
          setContacts(cp);
        }
      }
    }

    public onUpdateUnreadMessagesNum(Id: number) {
      setFlag(Id);
    }

    public onClearDialog(data: any) {
      setClearDialog(data.telegram_id);
    }

    public onChangeHall(data: any) {
      setHalls(data.result);
      // setHalls([]);
    }

    public onLoginSomewhereElse(data: SomewhereOnlineMessage) {
      setAuthInfo(null);
      isManuallyClosed.current = true;
      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        console.log('----- C2 ------');
        ws.current.close();
      }
      navigate(PATH_HOME_LOGIN);
      removeCookie(AUTH_TAG);
    }

    public onUnreadGroupArrive(data: any) {
      const groupData: Group[] = groupsStore.map((group) => ({
        ...group,
        unreadCount: data.result[group.value],
      }));
      setGroupsStore(groupData);
    }
  }

  const [disPatch] = useState<Dispatcher>(new Dispatcher(new ChatProc(), authInfo?.operator_id));

  function onMessageProc(evtData: any) {
    try {
      const data = JSON.parse(evtData);
      if (data.hasOwnProperty('token') && data.id === cookies['auth-token'].operator_id)
        logoutIncorrectToken();
      if (chatlog) console.log('backend-responce', data);

      disPatch.dispatch(data);
    } catch (e) {
      console.error('socket message :', e);
    }
  }

  // socket connection
  const connect = () => {
    const wsUrl = `${config.SOCKET_PROTOCOL}://${getHostPortWSS(config.SOCKET_HOST, config.SOCKET_PORT)}${config.SOCKET_PATH}?token=${authInfo?.token}`;
    const websocket = new WebSocket(wsUrl);

    ws.current = websocket;

    websocket.onopen = () => {
      pingInterval.current = setInterval(() => {
        if (websocket.readyState === WebSocket.OPEN) {
          websocket.send(JSON.stringify({ type: 'ping' }));
        }
      }, 30000);
      onSocketOpenProc();
      setIsConnected(true);
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current);
        reconnectInterval.current = null;
      }
      isManuallyClosed.current = false;
    };

    websocket.onerror = (event) => {
      onSocketErrorProc(event);
      if (!isManuallyClosed.current) {
        startReconnect();
      }
    };

    websocket.onmessage = (event) => onMessageProc(event.data);

    websocket.onclose = () => {
      if (isConnected)
        toast.error('Socket closed!', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });

      onSocketCloseProc();
      setIsConnected(false);
      if (!isManuallyClosed.current) {
        startReconnect();
      }
    };
  };

  // reconnect
  const startReconnect = () => {
    setPlayerInfoBalance(null);
    setCurrentDialogPage(LAST_DIALOG_PAGE_IDX);
    setCurrentContact(undefined);
    setDialog([]);
    setDialogPage([]);
    if (reconnectInterval.current !== null) return;

    reconnectInterval.current = setInterval(() => {
      if (
        ws.current &&
        ws.current.readyState !== WebSocket.OPEN &&
        ws.current.readyState !== WebSocket.CONNECTING
      ) {
        console.log('Trying to reconnect...');
        try {
          connect();
        } catch (e) {
          console.error('Error reconnecting:', e);
        }
      }
    }, 3000);
  };

  useEffect(() => {
    console.log('ENV', process.env);
    if (cookies.hasOwnProperty('auth-token') && cookies['auth-token']?.token.length !== 0) {
      setAuthInfo(cookies['auth-token']);
    } else {
      toast.dismiss();
      navigate(PATH_HOME_LOGIN);
      removeCookie(AUTH_TAG);
    }
  }, []);

  useEffect(() => {
    console.log('------ isConnected ------', isConnected);
  }, [isConnected]);

  useEffect(() => {
    if (!authInfo) {
      return;
    }

    connect();

    return () => {
      isManuallyClosed.current = true;
      if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        console.log('----- C3 ------');
        ws.current.close(1000, 'Component unmounting');
      }
      if (pingInterval.current) {
        clearInterval(pingInterval.current);
        pingInterval.current = null;
      }
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current);
        reconnectInterval.current = null;
      }
    };
  }, [authInfo]);

  useEffect(() => {
    activeGroupRef.current = activeGroup;
    if (ws.current) {
      setLoadingContactLoading(true);
      setCurrentContact(undefined);
      setDialog([]);
      setDialogPage([]);
      if (activeGroup.value === 'support') {
        getContacts(ws.current);
      }
      if (activeGroup.value === 'operators') {
        getOperatorContacts(ws.current);
        // getGroups(wsClient);
      }
      if (activeGroup.value === 'invite') {
        getInvitedContacts(ws.current);
        // setContacts([]);
      }
    }
  }, [activeGroup]);

  useEffect(() => {}, [loadingContactLists]);

  useEffect(() => {
    setTimeout(() => setLoadingContactLoading(false), 250);
  }, [contacts]);

  useEffect(() => {
    switch (ws.current?.readyState) {
      case 0:
        console.log('-ws-state-CONNECTING');
        break;
      case 1:
        console.log('-ws-state-OPEN');
        break;
      case 2:
        console.log('-ws-state-CLOSING');
        break;
      case 3:
        console.log('-ws-state-CLOSED');
        break;
      default:
        // logoutIncorrectToken();
        console.log('-ws-state-' + ws.current?.readyState);
    }
  }, [ws.current?.readyState]);

  useEffect(() => {
    // if (chatlog)
    //   console.log('change current contact get messages ' + JSON.stringify(currentContact));
    setPlayerInfoBalance(null);
    setCurrentDialogPage(LAST_DIALOG_PAGE_IDX);
    setDialog([]);
    if (activeGroup.value === 'support') {
      getDialog(currentContact);
      //  CLOSE WEB SOCKET
      getPlayerInfo(currentContact);
    }
    if (activeGroup.value === 'operators') {
      getOperatorDialog(currentContact);
      getUserInfo(currentContact);
    }
    if (activeGroup.value === 'invite') {
      getInvitedDialog(currentContact);
      getInvitedPlayerInfo(currentContact);
    }
    fetchHallMessages();
  }, [currentContact]);

  useEffect(() => {
    if (chatlog) console.log('new dialog page has arrive contains  ' + dialogPage.length);
    const reverseNewMessage = dialogPage.reverse();
    let allDialog: IDialog[] = [...reverseNewMessage, ...dialog];
    setDialog(allDialog);
  }, [dialogPage]);

  useEffect(() => {
    if (chatlog) console.log('current dialog contains ' + dialog.length);
  }, [dialog]);

  useEffect(() => {
    if (imageLink.length > 0) {
      if (currentContact && authInfo && ws.current) {
        cmdSendImageMessage(
          authInfo,
          ws.current,
          currentContact,
          imageLink,
          imageMessage,
          clearAuthOnError,
          recoverySteps
        );
        setTimeout(() => {
          setImageLink('');
          setImageMessage('');
        }, 300);
      }
    }
  }, [imageLink]);

  useEffect(() => {
    if (message) {
      const typeMessage = message.hasOwnProperty('type')
        ? 'invite'
        : message.hasOwnProperty('telegram_id')
          ? 'support'
          : 'operators';

      console.log('----------------- TYPE MESSAGE -----------------', typeMessage);

      const peerMessage: PeerMessage = JSON.parse(JSON.stringify(message));
      const webMsg = isWebMessage(peerMessage);
      console.log('on chat msg-- ' + JSON.stringify(peerMessage) + '__' + JSON.stringify(contacts));
      let c: IContact[] = [];
      let recipients = null;
      console.log('---- TEST ----', peerMessage, message);
      if (activeGroup.value === 'support') {
        recipients = contacts.filter(
          (c) => c.telegram_id === message.telegram_id || c.telegram_id === peerMessage.telegramId
        );
      }
      if (activeGroup.value === 'operators') {
        recipients = contacts.filter((c) => {
          return (
            c.operator_id === message.to_user_id ||
            c.operator_id === message.from_user_id ||
            c.operator_id === peerMessage.to_user_id
          );
        });
      }
      if (activeGroup.value === 'invite') {
        recipients = contacts.filter(
          (c) =>
            c.telegram_id === message.telegram_id ||
            c.telegram_id === peerMessage.telegramId ||
            c.telegram_id === message.to_invited
        );
      }
      if (recipients && recipients.length > 0) {
        let recipient = recipients[0];
        const flag: keys = {
          support: currentContact?.pay_id === recipient.pay_id,
          operators: currentContact?.operator_id === recipient.operator_id,
          invite: currentContact?.telegram_id === recipient.telegram_id,
        };
        const flagTwo: keys = {
          support: !webMsg,
          operators: true,
          invite: !webMsg,
        };
        if (flag[activeGroup.value]) {
          const idHall = currentContact?.hall_id ?? 0;
          activeGroup.value === 'support' && updateStatusMessage(recipient.telegram_id, idHall);
          activeGroup.value === 'operators' &&
            updateOperatorStatusMessage(authInfo?.operator_id ?? 0, currentContact);
          // test(recipient.telegram_id);
          recipient.unreadMessagesNum = 0;
        } else if (flagTwo[activeGroup.value]) {
          recipient.unreadMessagesNum = incrementNum(recipient.unreadMessagesNum);
        }

        recipient.last_message = message.player_message ?? peerMessage.message;
        recipient.last_message_ts = message.timestamp; // No return time backend

        c = updateContactsWithNew(recipient, contacts);

        const flag2: keys = {
          support: currentContact && recipient.telegram_id === currentContact.telegram_id,
          operators: currentContact && recipient.operator_id === currentContact.operator_id,
          invite: currentContact && recipient.telegram_id === currentContact.telegram_id,
        };

        if (flag2[activeGroup.value]) updateDialog(peerMessage);
      } else {
        const recipient: IContact = {
          description: '',
          hall_id: message.hall_id,
          hall_name: message.hall_name,
          label: '',
          name: message.sender_name,
          pay_id: message.pay_id,
          player_id: '',
          id: message.telegram_id,
          telegram_id: message.telegram_id,
          week_limit: 0,
          unreadMessagesNum: 1,
          last_message: message.player_message ?? peerMessage.message, // update
          last_message_ts: message.timestamp,
        };
        c = updateContactsWithNew(recipient, contacts);
      }
      if (typeMessage === activeGroup.value) setContacts(c);
    }
  }, [message]);

  useEffect(() => {
    if (flag) {
      let newContacts: IContact[] = contacts.map((item) => {
        const ifs: keys = {
          support: item.telegram_id == flag,
          operators: item.operator_id == flag,
          invite: item.telegram_id == flag,
        };
        if (ifs[activeGroup.value]) {
          item.unreadMessagesNum = 0;
          return item;
        }
        return item;
      });
      setContacts(newContacts);
      setFlag(0);
    }
  }, [flag]);

  useEffect(() => {
    if (clearDialog && currentContact?.telegram_id === clearDialog) {
      setCurrentContact(undefined);
      setDialogPage([]);
    }
    setClearDialog(0);
  }, [clearDialog]);

  // if (authInfo === null && crm === ChatRecoveryMode.CRM_RELOGIN) startReLogin();

  return (
    <>
      <Contacts
        contacts={contacts}
        currentContact={currentContact}
        type={activeGroup.value}
        setCurrentContact={setCurrentContact}
        loading={loadingContactLists}
        setLoading={setLoadingContactLoading}
        inviteUser={inviteUser}
        halls={halls}
      />
      <Dialogs
        type={activeGroup.value}
        currentContact={currentContact}
        imagesFromGallery={imagesFromGallery}
        messagesFromGallery={messagesFromGallery}
        dialog={dialog}
        playerInfo={playerInfo}
        userInfo={userInfo}
        invitedPlayerInfo={invitedPlayerInfo}
        updatedBalance={playerInfoBalance}
        updatedBalanceMode={playerInfoBalanceMode}
        playerBalance={playerBalance}
        loginName={'' + authInfo?.operator_name}
        sendMessage={sendMessage}
        savePlayerInfo={savePlayerInfo}
        changeBalance={changeBalance}
        changeBalanceMode={changeBalanceMode}
        sendReset={sendReset}
        changeStatus={changeStatus}
        switchVip={switchVip}
        savePlayerId={savePlayerId}
        getHallImages={getHallImages}
        getHallMessages={getHallMessages}
        createMsgTemplate={createMsgTemplate}
        deleteMsgTemplate={deleteMsgTemplate}
        dlgCtrl={dlgCtrl}
        currentContactBackClear={currentContactBackClear}
      />
      <ToastContainer />
    </>
  );
};

export default Guruchat;
