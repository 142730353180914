import { DialogTypeEnum } from 'models/Dialog';

export interface IMessage<T = MessageStatusEnum> {
  id: string;
  type: MessageTypeEnum;
  status: T;
  date: Date;
  senderName: string;
  photo: string;
  text: string;
  textLines: string[];
  media?: any;
  getShortMessage(): string;
  getShortTime(): string;
  getShortDate(): string;
  getFullMonthDay(): string;
}

export interface IMessageDTO<T = MessageStatusEnum> {
  datetime: string;
  text: string;
  id: string;
  status: T;
  direction: DialogTypeEnum;
  senderName: string;
  media?: any;
}

export enum InMessageStatusEnum {
  new = 'NEW',
  old = 'OLD',
}

export enum OutMessageStatusEnum {
  new = 'NEW',
  moderated = 'MODERATED',
  queued = 'QUEUED',
  error = 'ERROR',
  send = 'Send',
  undelivered = 'UNDELIVERED',
  delivered = 'DELIVERED',
  unread = 'UNREAD',
  read = 'READ',
}

export type MessageStatusEnum = InMessageStatusEnum | OutMessageStatusEnum;

export enum MessageTypeEnum {
  text = 'TEXT',
  button = 'BUTTON',
  image = 'IMAGE',
}

// new -> moderated -> queued -> error
// new -> moderated -> queued -> sent -> (delivered -> unread -> read)
// new -> moderated -> queued -> sent -> (undelivered)
// new -> moderated -> queued -> sent -> (undelivered -> delivered -> unread -> read)

export interface INewMessageDTO {
  telegram_id: number;
  message: IMessageDTO;
}

export interface FetchContactMessages {
  _id: string;
  message: string;
  hallId: number;
  telegramId: number;
  payId: string;
  photo: string[];
  status: MessageStatusEnum;
  isPlayer: boolean;
  timestamp: number;
  senderName: string;
  updated_at: Date;
  created_at: Date;
  media?: any;
}

export function fetchPhoto(msg: FetchContactMessages): string {
  let result = '';
  if (msg && msg.photo && msg.photo.length > 0) result = msg.photo[0];
  return result;
}

export interface MultinamedSender {
  sender_name: string;
  senderName: string;
}

export interface TelegramBotMessage extends MultinamedSender {
  chat_message_id: string;
  hall_id: number;
  hall_name: string;
  label: string | null;
  message_status: string;
  notes: string | null;
  pay_id: string;
  player_id: number;
  player_message: string;
  telegram_id: number;
  week_limit: number | null;
  timestamp: number;
  to_user_id?: number;
  from_user_id?: number;
  to_invited?: number;
}

export interface PeerMessage extends MultinamedSender {
  chat_message_id: string;
  message: string;
  message_status: MessageStatusEnum;
  from_user_id: number;
  to_user_id: number;
  telegramId: number;
  photo: string[];
  player_message: string;
  to_invited?: number;
  media?: any;
}

export function getPeerMessage(pm: PeerMessage): string {
  return pm.message !== null && pm.message !== undefined && pm.message.length > 0
    ? pm.message
    : pm.player_message;
}

export function getPeerName(pm: PeerMessage): string {
  return pm.senderName !== null && pm.senderName !== undefined && pm.senderName.length > 0
    ? pm.senderName
    : pm.sender_name;
}

export function fetchPeerPhoto(msg: PeerMessage): string {
  let result = '';
  if (msg && msg.photo && msg.photo.length > 0) result = msg.photo[0];
  return result;
}

interface IAttachment {
  file: string;
  fileName: string;
  fileType: string;
}

export interface IOutgoing {
  message: string;
  imgSrc: string;
  imgObj: File;
  attachment?: IAttachment;
}

export interface ImageMessage {
  attachment: string | null;
  images: string[] | null;
  photo: string | null;
  operator_id: number;
  contact_id: number;
  hall_id: number;
  operator_name: string;
  payId: string;
  message: string;
  type: string;
}

export interface UpdatePlayerInfo {
  payId: string;
  type: string;
  weekLimit: number | null;
  description: string | null;
  creditLimit: number | null;
  payAmount: number | null;
  adsMessages: boolean;
  creditMessages: boolean;
}

export interface ImageMessage {
  attachment: string | null;
  images: string[] | null;
  photo: string | null;
  operator_id: number;
  contact_id: number;
  hall_id: number;
  operator_name: string;
  payId: string;
  message: string;
  type: string;
}

export interface ImagesMessage {
  attachment: string | null;
  image: string | null;
  images: string[] | null;
  operator_id: number;
  contact_id: number;
  hall_id: number;
  operator_name: string;
  payId: string;
  message: string;
  type: string;
  receiver_id?: number;
  to_operator?: boolean;
}

export interface GetContacts {
  operator_id: number;
  type: string;
}

export interface GetDialog {
  operator_id: number;
  contact_id: number;
  type: string;
}

export interface GetDialogPage {
  operator_id: number;
  contact_id: number;
  type: string;
  page: number;
}

export interface GetPlayerInfo {
  payId: string;
  type: string;
}

export interface GetGroupDialog {
  group_id: string;
  type: string;
}
export interface GetGroupDialogPage {
  group_id: string;
  type: string;
  page: number;
}
