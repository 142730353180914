import { IContact } from 'models/Contact';
import { ENV } from '../.env';
import { ChatRecoveryMode } from 'models/Balance';
import { IOutgoing } from 'models/Message/type';

const env = ENV || 'dev';
const config = require('../config/config.json')[env];

export const LAST_DIALOG_PAGE_IDX = 0;
export const crm: ChatRecoveryMode = ChatRecoveryMode.CRM_RECONNECT;

export enum Chat {
  GET_DIALOG = 'getDialog',
  GET_DIALOG_PAGE = 'getDialogPage',
  SEND_MESSAGE = 'sendMessage',
  GET_CONTACTS = 'getContacts',
  GET_UNREAD_DIALOG = 'getNewMessages',
  UPDATE_MESSAGES = 'updateMessagesStatus',
  UPDATE_MESSAGES_STATUS = 'updateStatusMessages',
  GET_PLAYER_INFO = 'getPlayerInfo',
  SET_PLAYER_INFO = 'updatePlayerInfo',
  SEND_COMMAND = 'sendCommand',
  GET_USER_INFO = 'getUserInfo',
}

export interface IChatSupport {
  currentContact?: IContact;
  sendMessage: (message: IOutgoing) => void;
}

export const LOGIN_API_HOST = config.API_HOST;
export const LOGIN_CS_URL = config.API_HOST + '/api/autologin';
export const LOGIN_URL = config.API_HOST + '/api/login';
export const IS_VALID_TOKEN_URL = config.API_HOST + '/api/isvalidtoken';
export const IMAGES_PATH = config.API_HOST + '/media/';

export const AUTH_TAG = 'auth-token';
export const PASSTHROUGH_AUTH_TAG = 'authid';
export const LOGOUT_TAG = 'logouted';

export const chatlog = true;
export const SAVE_CHAT_WORK = true;
export const CONTACT_NOTES_LIMIT = 500;

export function getHostPortWSS(host: string, port: number): string {
  if (port === 80 || port === undefined) return host;
  return host + ':' + port;
}

export function cookieSet(name: string, value: string) {
  const cookie1 = encodeURIComponent(name) + '=' + encodeURIComponent(value);
  document.cookie = cookie1 + '; path=/';
}

export function cookieGet(name: string) {
  const state = '' + document.cookie;
  const start = encodeURIComponent(name) + '=';
  const pos = state.indexOf(start);
  let result = '';
  if (pos > -1) {
    const inside = state.substring(pos + start.length);
    const ending = inside.indexOf(';');
    result = ending > -1 ? inside.substring(0, ending) : inside;
  }
  return decodeURIComponent(result);
}

export function cookieHas(name: string): boolean {
  const result = cookieGet(name);
  return result.length >= 1;
}

export function cookieClear(name: string) {
  cookieSet(name, '');
}

export function incrementNum(value: number): number {
  let result;
  if (value !== null && value !== undefined && value > 0) result = value + 1;
  else result = 1;
  return result;
}
