import { Message, IMessage, IMessageDTO, MessageTypeEnum, MessageStatusEnum } from 'models/Message';
import { DialogTypeEnum, IDialog } from './type';
import { getFullMonthDay, getShortDate, getShortTime } from 'types/Time';
import { getShortMessage } from 'types/Text';
import { CMD_WITHDRAW } from 'constants/commands';

export class Dialog implements IDialog {
  private _type: DialogTypeEnum;
  private _message: IMessage;

  constructor(type: DialogTypeEnum, message: IMessage) {
    this._type = type;
    this._message = message;
  }

  public static createDialog(
    type: DialogTypeEnum,
    id: string,
    msgType: MessageTypeEnum,
    status: MessageStatusEnum,
    date: Date,
    senderName: string,
    messagesPhoto: string,
    text: string,
    media: any
  ) {
    let msg: IMessage = {
      id: id,
      type: msgType,
      status: status,
      date: date,
      senderName: senderName,
      photo: messagesPhoto,
      text: text,
      textLines: [text],
      media: media,
      getShortMessage: () => {
        const result = getShortMessage(text);
        return result;
      },
      getShortTime: () => {
        const result = getShortTime(date);
        return result;
      },
      getShortDate: () => {
        const result = getShortDate(date);
        return result;
      },
      getFullMonthDay: () => {
        const result = getFullMonthDay(date);
        return result;
      },
    };
    const result = new Dialog(type, msg);
    return result;
  }

  public static createInstance(data: IMessageDTO) {
    const { direction } = data;

    return new Dialog(direction, Message.createInstance(data));
  }

  get type(): DialogTypeEnum {
    return this._type;
  }

  set type(value: DialogTypeEnum) {
    this._type = value;
  }

  get message(): IMessage {
    return this._message;
  }

  set message(value: IMessage) {
    this._message = value;
  }

  public isRenderDate(prevDialog?: IDialog): boolean {
    if (!prevDialog) {
      return true;
    }

    return this._message.getShortDate() !== prevDialog.message.getShortDate();
  }

  public isNextBlock(nextDialog?: IDialog): boolean {
    if (!nextDialog) {
      return false;
    }

    return this._type !== nextDialog.type;
  }

  public isRenderAvatar(prevDialog?: IDialog): boolean {
    if (!prevDialog) {
      return true;
    }

    return prevDialog.type !== this._type;
  }

  public isButtons(): boolean {
    const result =
      this.message &&
      this.message.text !== null &&
      this.message.text !== undefined &&
      ('' + this.message.text).startsWith(CMD_WITHDRAW);
    return result;
  }

  public isIncoming(currentContactName: string): boolean {
    return this._message.senderName === currentContactName;
  }

  public isOutgoing(): boolean {
    return this._type === DialogTypeEnum.out;
  }
}
export { DialogTypeEnum };
export type { IDialog };
