import React, { FC, memo, useState } from 'react';
import { IDialog } from 'models/Dialog';
import { OutMessageStatusEnum } from 'models/Message';
import getStatusIcon, { statuses } from './getStatusIcon';
import classes from '../message.module.scss';
import MultyMsg from 'components/Dialog/MultyMsg';
import { getShortTime } from 'types/Time';

interface IRightCommentRowProps {
  dialogItem: IDialog;
  prevDialogItem: IDialog;
  nextDialogItem: IDialog;
  keyNum: number;
  loginName: string;
}

const RightCommentRow: FC<IRightCommentRowProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const { dialogItem, nextDialogItem, keyNum, loginName } = props;
  const { message } = dialogItem;
  const { text, status } = message;
  const hasPhoto = message.hasOwnProperty('media');

  const getFileNameFromUrl = (url: string) => {
    if (url) {
      const match = url.match(/\/([^\/]+)$/);
      return match ? match[1] : null;
    }
    return '';
  };

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <li
      key={keyNum}
      className={classes.right}
      style={dialogItem.isNextBlock(nextDialogItem) ? { marginBottom: '8px' } : {}}
    >
      <div className={classes.conversationList}>
        <div className={classes.ctextWrapContent}>
          <p className={classes.chatParty}>
            <span>
              <b>
                <strong>{message.senderName}</strong>
              </b>
            </span>
          </p>
          {hasPhoto && message && message.media && (
            <>
              {(() => {
                if (message.media.type === 'image') {
                  return (
                    <div className={classes.imageAttached}>
                      {loading && <div className={classes.load}></div>}
                      <img
                        style={{ display: loading ? 'none' : 'block' }}
                        src={message.media.url}
                        alt="not present"
                        onLoad={handleLoad}
                      />
                    </div>
                  );
                } else if (message.media.type === 'video') {
                  return (
                    <>
                      {loading && <div className={classes.load}></div>}
                      <video
                        style={{ display: loading ? 'none' : 'block' }}
                        className={classes.video}
                        src={message.media.url}
                        controls
                        onLoadedData={handleLoad}
                      ></video>
                    </>
                  );
                } else {
                  return (
                    <div className={classes.boxFile}>
                      <a className={classes.linkFile} href={message.media.url} download>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                          />
                        </svg>
                      </a>
                      <label>{getFileNameFromUrl(message.media.url)}</label>
                    </div>
                  );
                }
              })()}
            </>
          )}
          <p>
            <MultyMsg msg={text} />
          </p>
          <p className={classes.chatTime}>
            <span>{getShortTime(message.date)}</span>
            {statuses && (
              <img src={getStatusIcon(status as OutMessageStatusEnum)} alt="not present" />
            )}
          </p>
        </div>
      </div>
    </li>
  );
};

export default memo(RightCommentRow);
