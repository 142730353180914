import { OutMessageStatusEnum } from 'models/Message';
import doneAll from 'assets/images/chats/done_all.svg';
import wait from 'assets/images/chats/wait.svg';
import error from 'assets/images/chats/error.svg';
// import { done } from 'assets/images/messages';
import done_ from 'assets/images/chats/done.svg';

const getStatusIcon = (status: OutMessageStatusEnum): any => {
  switch (status) {
    case OutMessageStatusEnum.new:
    case OutMessageStatusEnum.moderated:
    case OutMessageStatusEnum.queued:
      return wait;
    case OutMessageStatusEnum.send:
      return done_;
    case OutMessageStatusEnum.undelivered:
      return wait;
    case OutMessageStatusEnum.delivered:
    case OutMessageStatusEnum.unread:
      return done_;
    case OutMessageStatusEnum.read:
      return doneAll;
    case OutMessageStatusEnum.error:
    default:
      return error;
  }
};

export const statuses: boolean = false;

export default getStatusIcon;
