import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import createSessionSlice, { SessionState, SessionActions } from './session';
import createDialogsSlice, { DialogsState, DialogsActions } from './dialogs';
import createMessagesSlice, { MessagesState, MessagesActions } from './messages';
import createGroupsSlice, { GroupsState } from './groups';

type StoreState = SessionState & DialogsState & MessagesState & GroupsState;
type StoreActions = SessionActions & DialogsActions & MessagesActions;
type GroupsActions = GroupsState;
type Store = StoreState & StoreActions & GroupsActions;

export const useAppStore = create<Store>()(
  persist(
    (...a) => ({
      ...createSessionSlice(...a),
      ...createDialogsSlice(...a),
      ...createMessagesSlice(...a),
      ...createGroupsSlice(...a),
    }),
    {
      name: 'chat-app',
      partialize: (state) => ({
        activeGroup: state.activeGroup,
      }),
    }
  )
);

export default useAppStore;
