import { IContact } from 'models/Contact';
import { IDialog } from 'models/Dialog';
import { IGalleryImage } from './IHallImagesGet';
import { BalanceMode } from 'models/Balance';
import { ChatCommand } from './ChatCommand';
import { TelegramBotMessage } from 'models/Message/type';
import { LOGOUT_STATUS, SomewhereOnlineMessage } from './IAuthInfo';

export interface IChatResponceProc {
  onContactArrive: (contactsResult: IContact[]) => void;
  onDialogArrive: (contactMessages: IDialog[]) => void;
  onGetDialogPageArrive: (contactMessages: IDialog[]) => void;
  onPlayerInfoArrive: (result: any[]) => void;
  onPlayerInfoUpdated: (result: any) => void;
  onUserInfoArrive: (result: any[]) => void;
  onInvitedPlayerInfoArrive: (result: any[]) => void;
  onImagesHallComplete: (cmdResult: IGalleryImage[]) => void;
  onMessagesHallComplete: (cmdResult: any[]) => void;
  onChangeBalanceCommandComplete: (piBalance: number) => void;
  onChangeBalanceModeCommandComplete: (bMode: BalanceMode) => void;
  onSetWeeklyResetComplete: (cmdResult: any) => void;
  onStoreMessageCommandComplete: (cmdResult: any) => void;
  onDeleteMessageCommandComplete: (cmdResult: any) => void;
  onChangeStatusCommandComplete: (cmdResult: any) => void;
  onSwitchVipCommandComplete: (cmdResult: any) => void;
  onChangePlayerIdComplete: (cmdResult: any) => void;
  onImageLinkArrive: (data: any) => void;
  onChatMsg: (data: TelegramBotMessage) => void;
  onLoginSomewhereElse: (data: SomewhereOnlineMessage) => void;
  onUpdateUnreadMessagesNum: (telegramId: number) => void;
  onClearDialog: (data: any) => void;
  onChangeHall: (data: any) => void;
  onUnreadGroupArrive: (data: any) => void;
}

export class Dispatcher {
  private chatProc: IChatResponceProc;
  private operator_id: number | undefined;

  constructor(proc: IChatResponceProc, operator_id: number | undefined) {
    this.chatProc = proc;
    this.operator_id = operator_id;
  }

  public getOperatorId(): number | undefined {
    return this.operator_id;
  }

  public getProc(): IChatResponceProc {
    return this.chatProc;
  }

  public dispatch(data: any) {
    // if (data.id && data.status) {
    //   const logoutMsg: SomewhereOnlineMessage = JSON.parse(JSON.stringify(data));
    //   if ((this.operator_id !== undefined && logoutMsg.id === this.operator_id && logoutMsg.status === LOGOUT_STATUS) || this.operator_id === undefined)
    //     this.chatProc.onLoginSomewhereElse(data);
    // }

    if (data.response_getContacts) {
      console.log('WS: RESPONSE GETCONTACTS');
      console.log(data);
      this.chatProc.onContactArrive(data.response_getContacts.result);
    }

    if (data.response_getDialog) {
      this.chatProc.onDialogArrive(data.response_getDialog.result);
      // this.chatProc.onUpdateUnreadMessagesNum(data.telegramId);
    }

    if (data.response_getDialogPage) {
      this.chatProc.onGetDialogPageArrive(data.response_getDialogPage.result);
      // this.chatProc.onUpdateUnreadMessagesNum(data.telegramId);
    }

    // --------- NEW CHECK ---------
    if (data.response_getOperatorContacts) {
      console.log('WS: RESPONSE GETCONTACTS');
      console.log(data);
      this.chatProc.onContactArrive(data.response_getOperatorContacts.result);
    }

    if (data.response_getOperatorDialog) {
      this.chatProc.onDialogArrive(data.response_getOperatorDialog.result);
      // this.chatProc.onUpdateUnreadMessagesNum(data.telegramId);
    }

    if (data.response_getOperatorDialogPage) {
      this.chatProc.onGetDialogPageArrive(data.response_getOperatorDialogPage.result);
      // this.chatProc.onUpdateUnreadMessagesNum(data.telegramId);
    }
    // --------- END NEW CHECK ---------

    // --------- NEW GROUP ---------
    // --------- END GROUP ---------

    // --------- NEW INVITED ---------
    if (data.response_getInvitedContacts) {
      this.chatProc.onContactArrive(data.response_getInvitedContacts.result);
    }

    if (data.response_getInvitedDialog) {
      this.chatProc.onDialogArrive(data.response_getInvitedDialog.result);
      // this.chatProc.onUpdateUnreadMessagesNum(data.telegramId);
    }
    if (data.response_getInvitedDialogPage) {
      this.chatProc.onGetDialogPageArrive(data.response_getInvitedDialogPage.result);
    }
    if (data.response_getInvitedPlayerInfo) {
      this.chatProc.onInvitedPlayerInfoArrive(data.response_getInvitedPlayerInfo.result);
    }
    // --------- END INVITED ---------

    if (data.response_getPlayerInfo)
      this.chatProc.onPlayerInfoArrive(data.response_getPlayerInfo.result);

    if (data.response_updatePlayerInfo)
      this.chatProc.onPlayerInfoUpdated(data.response_updatePlayerInfo.result);

    if (data.response_getUserInfo) this.chatProc.onUserInfoArrive(data.response_getUserInfo.result);

    if (data.command === ChatCommand.GET_HALL_IMAGES && data.commandResult)
      this.chatProc.onImagesHallComplete(data.commandResult);

    if (data.command === ChatCommand.GET_HALL_MESSAGES && data.commandResult)
      this.chatProc.onMessagesHallComplete(data.commandResult);

    if (data.command === ChatCommand.CHANGE_BALANCE && data.commandResult)
      this.chatProc.onChangeBalanceCommandComplete(data.commandResult);

    if (data.command === ChatCommand.SET_BALANCE_MODE && data.commandResult)
      this.chatProc.onChangeBalanceModeCommandComplete(data.commandResult);

    if (data.command === ChatCommand.SET_WEEK_RESET && data.commandResult)
      this.chatProc.onSetWeeklyResetComplete(data.commandResult);

    if (data.command === ChatCommand.STORE_MESSAGE && data.commandResult)
      this.chatProc.onStoreMessageCommandComplete(data.commandResult);

    if (data.command === ChatCommand.DELETE_MESSAGE && data.commandResult)
      this.chatProc.onDeleteMessageCommandComplete(data.commandResult);

    if (data.command === ChatCommand.CHANGE_STATUS && data.commandResult)
      this.chatProc.onChangeStatusCommandComplete(data.commandResult);

    if (data.command === ChatCommand.SWITCH_VIP && data.commandResult)
      this.chatProc.onSwitchVipCommandComplete(data.commandResult);

    if (data.command === ChatCommand.CHANGE_PLAYER_ID && data.commandResult)
      this.chatProc.onChangePlayerIdComplete(data.commandResult);

    if (data.image_link) this.chatProc.onImageLinkArrive(data);

    if (data.chat_message_id) this.chatProc.onChatMsg(data);

    if (data.command === ChatCommand.MARK_AS_READ) {
      if (data.hasOwnProperty('telegramId'))
        this.chatProc.onUpdateUnreadMessagesNum(data.telegramId);
      else this.chatProc.onUpdateUnreadMessagesNum(data.operatorId);
    }

    if (data.close) {
      this.chatProc.onClearDialog(data);
    }

    if (data.response_getAllowedHalls) {
      this.chatProc.onChangeHall(data.response_getAllowedHalls);
    }

    if (data.response_unreadGroup) {
      this.chatProc.onUnreadGroupArrive(data.response_unreadGroup);
    }
  }
}
